import { FunctionComponent, useContext, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import AppLayout from "../../components/Layout/AppLayout"
import { ErrorMessage } from "../../components/State"
import { config } from "../../config/constants"
import { TokenContext } from "../../contexts/TokenContext"
import { formatError, sendGet } from "../../helpers/submit"
import SmartContractItem from "./SmartContractItem"

const OpenSmartContract: FunctionComponent = () => {
    const {token} = useContext(TokenContext)
    const params = useParams()
    const history = useHistory()
    const procedureId = (params as any).procedureId
    const [dataState, setData] = useState()
    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        const onSuccess = (message: string, payload: any, status: number) => {
            if (payload === null) {
                history.push("/")
            }
            if (dataState !== payload) {
                setData(payload)
            }
            setSuccess(true)
        }
        const onError = (message: string, reason: string, status: number) => {
            setError(formatError(message, reason, status))
        }

        const loadProcedure = async () => {
            await sendGet<any>(
                `${config.url.API_URL}/smart-contract/${procedureId}`,
                token,
                onSuccess,
                onError
            )
        }
        loadProcedure()
    }, [])

    return (
        <AppLayout title={`View Procedure`}>
            <>
                {(success || error) && (
                    <>
                        {error ? (
                            <ErrorMessage
                                errorMessage={error}
                            />
                        ) : (
                            success &&
                            dataState && (
                                <SmartContractItem
                                    dataState={dataState}
                                    token={token}
                                />
                            )
                        )}
                    </>
                )}
            </>
        </AppLayout>
    )
}

export default OpenSmartContract
