import React, { createContext, FunctionComponent } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

type UserContext = {
    loggedUser: any;
    setUser: (user: any) => void
};


export const UserContext = createContext<UserContext>({
    loggedUser: () => {},
    setUser: () => {}
});

const UserContextProvider: FunctionComponent = ({ children }) => {
    const [loggedUser,setUser] = useLocalStorage("user", {})
    return (
        <UserContext.Provider value={{ loggedUser, setUser }}>
          {children}
        </UserContext.Provider>
      );
}

export default UserContextProvider

