import classNames from "classnames"
import { FunctionComponent } from "react"
import { IconType } from "react-icons"
import { Link } from "react-router-dom"

const ContractCard: FunctionComponent<{
    icon: IconType
    to: any
    color: String
    title: String
    description: String
}> = ({ icon: Icon, to, color, title, description }) => {
    return (
        <Link to={to}>
            <div
                className={classNames(
                    "bg-opacity-80 m-8 hover:bg-opacity-100 hover:scale-110 transform transition ease-in-out duration-500 p-4 rounded-lg",
                    color
                )}
            >
                <Icon size="3rem" className="mx-auto text-white" />
                <h2 className="text-center font-bold text-white text-2xl">
                    {title}
                </h2>
                <p className="text-white text-center mt-4">{description}</p>
            </div>
        </Link>
    )
}

const ComingSoonCard: FunctionComponent<{
    icon: IconType
    title: String
    color: String
}> = ({ icon: Icon, title, color }) => {
    return (
        <div className="text-center">
            <div
                className={classNames(
                    "bg-opacity-80 m-8 hover:bg-opacity-100 transform transition ease-in-out duration-500 p-4 rounded-lg",
                    color
                )}
            >
                <Icon className="mx-auto text-white" size="3rem" />
                <h2 className="text-center font-bold text-white text-2xl">
                    {title}
                </h2>
                <p className="text-white mt-4">Coming soon...</p>
            </div>
        </div>
    )
}

export { ComingSoonCard }
export default ContractCard
