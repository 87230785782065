import classnames from "classnames"
import { Field } from "formik"
import { FunctionComponent } from "react"
import classes from "../../classes/classes"
import Tooltip from "../Tooltip"

const Toggle: FunctionComponent<{
    name: string
    toggleText: string
    className?: string
    tooltipText?: string
    label?:string
}> = ({ name, toggleText,label, tooltipText, className }) => {
    return (
        <div className="my-5 text-sm">
              <label htmlFor={name} className={classnames(classes.label)}>
                {label}
            </label>
            <div className="space-x-2 flex items-center">
                <Field
                    type="checkbox"
                    name={name}
                    className={classnames(
                        "form-checkbox text-blue-500 rounded h-4 w-4",
                        className
                    )}
                />
                <span className={classes.explanatoryText}>{toggleText}</span>
                {tooltipText && <Tooltip text={tooltipText} />}
            </div>
        </div>
    )
}
export const ToggleSmall: FunctionComponent<{
    name: string
    toggleText: string
    className?: string
    tooltipText?: string
    label?:string
}> = ({ name, toggleText,label, tooltipText, className }) => {
    return (
        <div className="mb-4 text-sm">
              <label htmlFor={name} className={classnames(classes.label)}>
                {label}
            </label>
            <div className="space-x-2 flex items-center">
                <Field
                    type="checkbox"
                    name={name}
                    className={classnames(
                        "form-checkbox text-blue-500 rounded h-4 w-4",
                        className
                    )}
                />
                <span className={classes.explanatoryText}>{toggleText}</span>
                {tooltipText && <Tooltip text={tooltipText} />}
            </div>
        </div>
    )
}

export default Toggle
