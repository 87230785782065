//borrowed and adapted from @dchest/tweetnacl-util-js

export const encodeBase64 = (arr: Uint8Array) => {
    var i,
        s = [],
        len = arr.length
    for (i = 0; i < len; i++) s.push(String.fromCharCode(arr[i]))
    return btoa(s.join(""))
}

export const decodeBase64 = (s: string) => {
    validateBase64(s)
    var i,
        d = atob(s),
        b = new Uint8Array(d.length)
    for (i = 0; i < d.length; i++) b[i] = d.charCodeAt(i)
    return b
}

export const validateBase64 = (s: string) => {
    if (
        !/^(?:[A-Za-z0-9+\/]{2}[A-Za-z0-9+\/]{2})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$/.test(
            s
        )
    ) {
        throw new TypeError("invalid encoding")
    }
}

export const decodeUTF8 = (s: string) => {
    if (typeof s !== "string") throw new TypeError("expected string")
    var i,
        d = unescape(encodeURIComponent(s)),
        b = new Uint8Array(d.length)
    for (i = 0; i < d.length; i++) b[i] = d.charCodeAt(i)
    return b
}

export const encodeUTF8 = (arr: Uint8Array) => {
    var i,
        s = []
    for (i = 0; i < arr.length; i++) s.push(String.fromCharCode(arr[i]))
    return decodeURIComponent(escape(s.join("")))
}
