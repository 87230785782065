import { useFormikContext } from "formik"
import React from "react"
import classes from "../../../classes/classes"
import Radio from "../../../components/fields/Radio"
import Toggle from "../../../components/fields/Toggle"

const LegalChoice = () => {
    const { values, submitForm } = useFormikContext()
    return (
        <div className="">
            <>
                {(values as any).legalChecked ? (
                    <>
                        <p className={classes.explanatoryText}>
                            Choose the legal arbitration(s) you wish to set up.
                            You can combine the expretise and classical
                            arbitration.
                        </p>
                        <Toggle
                            name="legalData.expertiseChecked"
                            toggleText="Expertise Arbitration"
                            tooltipText="Designate an Expert to settle a litigious facts about the case"
                        />
                        {(values as any).legalData.expertiseChecked && (
                            <>
                                <Radio
                                    name="legalData.expertiseData.expertiseOption"
                                    label="Choose Exertise arbitration"
                                    options={["0", "1", "2"]}
                                    optionsTexts={[
                                        "Direct expert arbitration by Arbitri",
                                        "Arbitration expertise by a third party",
                                        "Freely written",
                                    ]}
                                />
                            </>
                        )}
                        <Toggle
                            name="legalData.classicChecked"
                            toggleText="Classic Arbitration"
                            tooltipText="Designate Arbitrator(s) to settle the litigious facts and/or redact an award about the case"
                        />
                        {(values as any).legalData.classicChecked && (
                            <>
                                <Radio
                                    name="legalData.classicData.classicOption"
                                    label="Choose Classic arbitration"
                                    options={["0", "1", "2", "3", "4", "5"]}
                                    optionsTexts={[
                                        "CPC classic arbitration",
                                        "SCAI classic arbitration",
                                        "CCI classic arbitration",
                                        "AAA external classic arbitration",
                                        "WIPO external classic arbitration",
                                        "Freely written",
                                    ]}
                                />
                            </>
                        )}
                    </>
                ) : (
                    <p className={classes.explanatoryText}>
                        You didn't choose to set up any legal arbitration. If
                        you want, you can add by going back to the previous
                        step.
                    </p>
                )}
            </>
        </div>
    )
}

export default LegalChoice
