import classnames from "classnames"
import { useFormikContext } from "formik"
import { FunctionComponent } from "react"
import { useDropzone } from "react-dropzone"
import { AiOutlineUpload } from "react-icons/ai"
import { RiDeleteBin4Fill } from "react-icons/ri"
import classes from "../../classes/classes"
import Tooltip from "../Tooltip"

export type File = { name: string; type: string; size: string }

const UploadComponent: FunctionComponent<{
    name: string
    label: string
    tooltipText?: string,
    filetypeAccept?: string
}> = ({ name, label, tooltipText,filetypeAccept }) => {
    const { setFieldValue } = useFormikContext()
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: filetypeAccept ? filetypeAccept : "application/pdf",
        onDrop: (acceptedFiles) => {
            setFieldValue(name, acceptedFiles)
        },
    })
    return (
        <>
            <label htmlFor={name} className={classnames(classes.label)}>
                {label}
            </label>
            {tooltipText && <Tooltip text={tooltipText} />}
            <div
                {...getRootProps({ className: "dropzone" })}
                className="p-4 border-dashed border-2 border-light-blue-500 flex items-center space-x-2 font-bold text-gray-500"
            >
                <AiOutlineUpload size="1.5rem" />
                <div>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p>Drop the files here ...</p>
                    ) : (
                        <p>
                            Drag 'n' drop some files here, or click to select
                            files
                        </p>
                    )}
                </div>
            </div>
        </>
    )
}

export const ListFiles: FunctionComponent<{name:string}> = ({name}) => {
    const { values } = useFormikContext()
    return (
        <div className="space-y-1 mt-2">
            {(values as any)[name] &&
                (values as any)[name].map((file: File, i: number) => (
                    <div key={"file" + i} className="flex space-x-2">
                        <span>{`${file.name}`}</span>
                        {/* <button
                            className="block flex items-center cursor-pointer text-red-400"
                            onClick={(e) => {
                                e.preventDefault()
                                ;(values as any)[name].splice(i, 1)
                            }}
                        >
                            <RiDeleteBin4Fill />
                        </button> */}
                    </div>
                ))}
        </div>
    )
}

export default UploadComponent
