import { IoCloseCircle } from "react-icons/io5"
import classnames from "classnames"
import { FunctionComponent, useEffect } from "react"

const SuccessMessage: FunctionComponent<{
    message: string
    resetSuccessState?: any
    className?: string
    info?: boolean
}> = ({ message, resetSuccessState, className, info }) => {
    return info ? (
        <div className={classnames("error", className)}>
            <div className="bg-blue-50 text-blue-800 rounded-lg p-3">
                <div className="flex items-center">
                    <button onClick={resetSuccessState}>
                        <IoCloseCircle size={"1.25rem"} />
                    </button>
                    <span className="ml-2 font-bold">Info</span>
                </div>
                <div className="flex items-center">
                    <p className="text-sm text-blue-500 mt-1">{message}</p>
                </div>
            </div>
        </div>
    ) : (
        <div className={classnames("error", className)}>
            <div className="bg-green-50 text-green-800 rounded-lg p-3">
                <div className="flex items-center">
                    <button onClick={resetSuccessState}>
                        <IoCloseCircle size={"1.25rem"} />
                    </button>
                    <span className="ml-2 font-bold">Success !</span>
                </div>
                <div className="flex items-center">
                    <p className="text-sm text-green-500 mt-1">{message}</p>
                </div>
            </div>
        </div>
    )
}

export default SuccessMessage
