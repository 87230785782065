import { useWeb3React } from "@web3-react/core"
import axios from "axios"
import classnames from "classnames"
import React, { FunctionComponent, useEffect, useState } from "react"
import { FaFilePdf } from "react-icons/fa"
import { Link, useHistory } from "react-router-dom"
import { Signature } from "typescript"
import classes from "../../classes/classes"
import ArbitrationProcedure from "../../components/Arbitration"
import { Delete } from "../../components/DeleteDetails"
import { SignatureDetails } from "../../components/SignatureDetails"
import UploadedFiles from "../../components/UploadedFiles"
import { config } from "../../config/constants"
import { dateHelper } from "../../helpers/formatDate"
import { formatError, sendGet } from "../../helpers/submit"
import { useGetArbitragesProcedures } from "../../hooks/usGetProcedures"
import { Bindings } from "./BindingPreview"

const OpenedProcedure: FunctionComponent<{ dataState: any; token: any }> = ({
    dataState,
    token,
}) => {
    const history = useHistory()
    const [arbitragesState, setarbitragesState] = useState([])
    const { activate, active, account, library } = useWeb3React()
    const [errorMessage, setErrorMessage] = useState("")
    const generateProcedureData = (dataState: any) => {
        return (
            <div className="procedure-infos">
                <SimpleDetailElement name="Arbitration Clause details">
                    <>
                        <DisplayActive
                            active={dataState.procedureData.technicalChecked}
                            name="Technical Arbitrations"
                        />

                        <DisplayActive
                            active={dataState.procedureData.legalChecked}
                            name="Legal Arbitrations"
                        />
                        <div className="flex flex-col ml-4">
                            <DisplayActive
                                active={
                                    dataState.procedureData.legalChecked &&
                                    dataState.procedureData.legalData
                                        .classicChecked
                                }
                                name="Classic Arbitrations"
                            />
                            <DisplayActive
                                active={
                                    dataState.procedureData.legalChecked &&
                                    dataState.procedureData.legalData
                                        .expertiseChecked
                                }
                                name="Expertise Arbitrations"
                            />
                        </div>
                    </>
                </SimpleDetailElement>
            </div>
        )
    }

    useEffect(() => {
        axios
            .get(`${config.url.API_URL}/procedure/${dataState._id}/arbitrage`, {
                params: { token: token },
            })
            .then((res) => {
                const arbitrages = res.data.arbitrages
                if (arbitragesState !== arbitrages) {
                    setarbitragesState(arbitrages)
                }
            })
    }, [])

    const downloadPDF = () => {
        axios
            .get(`${config.url.API_URL}/procedure/${dataState._id}/download`, {
                params: { token: token },
                responseType: "blob",
            })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                )
                const link = document.createElement("a")
                link.href = url
                link.setAttribute(
                    "download",
                    `clause_${dataState._id}_validated.pdf`
                )
                document.body.appendChild(link)
                link.click()
            })
            .catch((err) => console.log(err.message))
    }

    const [signature, setSignature] = useState<Signature>()
    const [users, setUsers] = useState<any>()
    const [error, setError] = useState("")

    const onSuccessSignature = async (
        message: string,
        payload: any,
        status: number
    ) => {
        const { signature, users } = payload
        setSignature(signature)
        setUsers(users)
    }
    useEffect(() => {
        sendGet(
            `${config.url.API_URL}/signature/${dataState.signature}`,
            token,
            onSuccessSignature,
            onError
        )
    }, [])

    const onError = (message: string, reason: string, status: number) => {
        setError(formatError(message, reason, status))
    }

    return (
        <div className="py-8 px-8 h-full pb-20 overflow-x-scroll">
            <nav className="bg-grey-light rounded font-sans w-full">
                <ol className="list-reset flex text-grey-dark mb-4">
                    <li>
                        <Link to={`/procedures/`} className="font-bold">
                            Contract
                        </Link>
                    </li>
                    <li>
                        <span className="mx-2">/</span>
                    </li>
                    <li>{dataState._id}</li>
                </ol>
            </nav>
            <Delete uuid={dataState.uuid} />
            <ArbitrationProcedure dataState={dataState}/>
            <h2 className={classnames(classes.formH2, "my-6")}>
                Contract Information
            </h2>
            <div className="flex space-x-4">
                <div className="w-1/2 mx-2">
                    <SimpleDetailElement name="Name">
                        <span>{dataState.name}</span>
                    </SimpleDetailElement>
                    <SimpleDetailElement name="Date of creation">
                        <span>
                            {dateHelper(new Date(dataState.creationDate))}
                        </span>
                    </SimpleDetailElement>
                    <SimpleDetailElement name="Underlying contract uploaded">
                        <UploadedFiles
                            url={`${config.url.API_URL}/procedure/${dataState._id}/underlying-contracts`}
                        />
                    </SimpleDetailElement>
                    {generateProcedureData(dataState)}
                </div>
                <div className="w-1/2">
                    <SimpleDetailElement name="Arbitration Clause">
                        <button onClick={downloadPDF}>
                            <div className="flex items-end cursor-pointer">
                                <FaFilePdf
                                    className="text-red-500"
                                    size="1.25rem"
                                />
                                <span className="ml-1 inline-block text-gray-800 text-xs">
                                    {`clause_${dataState._id}_validated.pdf`}
                                </span>
                            </div>
                        </button>
                    </SimpleDetailElement>

                    {signature && users && (
                        <SimpleDetailElement name="">
                            <SignatureDetails
                                users={users}
                                signature={signature}
                            />
                        </SimpleDetailElement>
                    )}
                </div>
            </div>
            
            <Bindings dataState={dataState} />
        </div>
    )
}

export const SimpleDetailElement: FunctionComponent<{ name: string }> = ({
    children,
    name,
}) => {
    return (
        <div className="border-b pb-4">
            <span className={classnames(classes.labelNoInput, "my-3 block")}>
                {name}
            </span>
            {children}
        </div>
    )
}
export const DisplayActive: FunctionComponent<{
    name: string
    active: boolean
}> = ({ active, name }) => {
    return active ? (
        <div className="">{name}</div>
    ) : (
        <div className="text-gray-400">{name}</div>
    )
}

export default OpenedProcedure
