const prod = {
    url: {
        API_URL: `https://api.arbitri.app`,
    },
}

const dev = {
    url: {
        API_URL: `http://localhost:5300`,
    },
}
export const config = process.env.NODE_ENV === `development` ? dev : prod

//make sure these addresses are correct !
export const arbitriEncPubKey = "Zh03zgxPxwvgeempHwIjdX/v8uNVmH7DOXfXxP9DuRo="
export const notaryEncPubKey = "2dKNwWc5Qpxlem45lBZmZPMwH374V20+yarHQWuApH0="

export const VAULT_CONTRACT = {
    address: "0x472E35D0102f68b1610b3e1B73d5a6890E5138c0",
    abi: [
      "function newShare(address _shareAddress,string memory _sharesHash,bytes memory _signature) public",
    ],
    chainId: 137
  }; 

