import { FunctionComponent } from "react"
import { Link } from "react-router-dom"

const LoginLayout: FunctionComponent<{ title: any; children: any }> = ({
    title,
    children,
}) => {
    return (
        <div className="bg-buildings">
            <div className="bg-black bg-opacity-80 pt-10 min-h-screen">
                <div className="max-w-xl mx-auto">
                    <Link to="/">
                        <div className="w-44 mx-auto mb-10">
                            <img
                                className="w-44 self-center"
                                src="/arbitri_logo.png"
                            />
                        </div>
                    </Link>
                    <div className="bg-white w-full px-8 py-10 rounded-lg">
                        <h1 className="font-bold text-3xl">{title}</h1>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginLayout
