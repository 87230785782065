import axios from "axios"
import React, { FunctionComponent, useContext, useEffect, useState } from "react"
import { Redirect, useHistory, useParams } from "react-router-dom"
import AppLayout from "../../components/Layout/AppLayout"
import { config } from "../../config/constants"
import { AnyCnameRecord } from "dns"
import { formatError, sendGet } from "../../helpers/submit"
import { ErrorMessage } from "../../components/State"
import Spinner from "../../components/Spinner"
import { TokenContext } from "../../contexts/TokenContext"

const ContractItem: FunctionComponent = () => {
    const history = useHistory()
    const params = useParams()
    const uuid = (params as any).uuid
    const [dataState, setData] = useState()
    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)
    const {token} = useContext(TokenContext)

    useEffect(() => {
        const onSuccess = (message: string, payload: any, status: number) => {
            if (payload == null){
                history.push("/")
            }
            setData(payload)
            setSuccess(true)
        }
        const onError = (message: string, reason: string, status: number) => {
            setError(formatError(message, reason, status))
        }

        const loadProcedure = async () => {
            await sendGet<any>(
                `${config.url.API_URL}/contract/${uuid}`,
                token,
                onSuccess,
                onError
            )
        }

        loadProcedure()
    }, [])

    const RedirectCopmonent = () => {
        switch ((dataState as any).type) {
            case "ArbitrationClause":
                return (
                    <Redirect
                        to={`/procedures/${(dataState as any).contract._id}`}
                    />
                )
                break
            case "FreeContractClause":
                return (
                    <Redirect
                        to={`/custom-contract/${(dataState as any).contract._id}`}
                    />
                )
            case "SmartContract":
                return (
                    <Redirect
                        to={`/smart-contract/${(dataState as any).contract._id}`}
                    />
                )
            default:
                return <Redirect
                to={`/`}
            />
        
        }
    }

    return <>{dataState ? RedirectCopmonent() : <Spinner />}</>
}

export default ContractItem
