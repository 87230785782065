import { useWeb3React } from "@web3-react/core"
import classNames from "classnames"
import React, { FunctionComponent, useState } from "react"
import classes from "../../../classes/classes"

const ShowEncryptionKey: FunctionComponent = () => {
    const [encKey, setEncKey] = useState("")
    const { library, account } = useWeb3React()

    let reqEncKey = () =>
        library.provider
            .request({
                method: "eth_getEncryptionPublicKey",
                params: [account],
            })
            .then((res: string) => setEncKey(res))
            .catch((err: Error) => console.error(err))

    return (
        <div className="flex gap-x-1">  
            <button
                className={classNames(classes.mainButton)}
                onClick={reqEncKey}
            >
                Show my encryption key
            </button>

            <input
                className={classNames(classes.inlineInputs, "w-full")}
                type="text"
                value={encKey}
                readOnly
            />
        </div>
    )
}

export default ShowEncryptionKey
