import axios from "axios"
import classnames from "classnames"
import { Form, Formik } from "formik"
import { FunctionComponent, useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import classes from "../../classes/classes"
import SimpleInput from "../../components/fields/SimpleInput"
import TextArea from "../../components/fields/TextArea"
import AppLayout from "../../components/Layout/AppLayout"
import { ErrorMessage, SuccessMessage } from "../../components/State"
import { config } from "../../config/constants"
import ReCAPTCHA from "react-google-recaptcha"
import * as Yup from "yup"
import "yup-phone";
import InlineError from "../../components/fields/InlineError"
import FormError from "../../components/fields/InlineError"
import { TokenContext } from "../../contexts/TokenContext"

const Questions: FunctionComponent = () => {
    const {token} = useContext(TokenContext)
    const [submited, setSubmited] = useState(false)
    const [captchaValue, setCaptchaValue] = useState("")
    const history = useHistory()
    const [error, setError] = useState("")
    const questionSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        phone: Yup.string().phone('CH',false,"Hmm this does not look like a phone. Please make sure to enter the indicative.").required("Required"),
        subject: Yup.string().required("Required"),
        details: Yup.string().required("Required"),
    })
    const initialValues = {
        name: "",
        email: "",
        phone: "",
        subject: "",
        details: "",
    }
    const submit = (values: any) => {
        const dataform = new URLSearchParams()
        dataform.append("question", JSON.stringify(values))
        axios
            .post(`${config.url.API_URL}/question`, dataform, {
                params: { token: token },
                headers: {
                    "content-type":
                        "application/x-www-form-urlencoded;charset=utf-8",
                },
            })
            .then((res) => {
                setSubmited(true)
                setTimeout(() => {
                    history.push("/")
                }, 3000)
            })
            .catch((err) => {
                setError(
                    "There was an error while forwarding the question to the server"
                )
            })
    }

    function onChange(value: any) {
        setCaptchaValue(value)
    }

    return (
        <AppLayout title="Legal questions">
            <div className="py-8 md:px-20">
                <h1 className={classes.pageSubtitle}>
                    {" "}
                    Do you need some advices ?
                </h1>
                <div className={classnames("w-1/2 py-2")}>
                    {submited ? (
                        <SuccessMessage message="Your message was sent!" />
                    ) : (
                        <Formik
                            initialValues={initialValues}
                            onSubmit={submit}
                            validationSchema={questionSchema}
                        >
                            {({ errors, touched, isValid }) => (
                                <Form>
                                    <FormError
                                        errors={errors}
                                        touched={touched}
                                        name="name"
                                    />
                                    <SimpleInput
                                        name="name"
                                        type="text"
                                        placeholder="Full name"
                                        label="Name"
                                    />
                                    <FormError
                                        errors={errors}
                                        touched={touched}
                                        name="email"
                                    />
                                    <SimpleInput
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                        label="E-mail"
                                    />
                                    <FormError
                                        errors={errors}
                                        touched={touched}
                                        name="phone"
                                    />
                                    <SimpleInput
                                        name="phone"
                                        type="text"
                                        placeholder="Phone number"
                                        label="Phone"
                                    />
                                    <FormError
                                        errors={errors}
                                        touched={touched}
                                        name="subject"
                                    />
                                    <SimpleInput
                                        name="subject"
                                        type="text"
                                        placeholder="Subject of your demand"
                                        label="Subject"
                                    />
                                    <FormError
                                        errors={errors}
                                        touched={touched}
                                        name="details"
                                    />
                                    <TextArea
                                        rows={15}
                                        name="details"
                                        placeholder="Detail your situation"
                                        label="Explain us what you need"
                                    />
                                    <ReCAPTCHA
                                        sitekey="6Ld_kO8cAAAAAFV8XfXZ9thyoZFunVQ0GfOQq-5-"
                                        onChange={onChange}
                                    />
                                    <button
                                        disabled={!captchaValue}
                                        className={classnames(
                                            classes.flatButton,
                                            "bg-gradient-to-r from-blue-400 to-blue-700 my-4",
                                            (!captchaValue || !isValid) &&
                                                "opacity-40"
                                        )}
                                    >
                                        Send
                                    </button>
                                    <ErrorMessage
                                        errorMessage={error}
                                    />
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
            </div>
        </AppLayout>
    )
}

export default Questions
