import { useWeb3React } from "@web3-react/core"

export const useFormattedAccount = () => {
    const { account } = useWeb3React()
    const formattedAccount = formatHash(account || "")
    return formattedAccount
}

export const formatHash = (hash) =>
    `${hash.substr(0, 4 + 2)}...${hash.substr(-4)}`
