export type Signature = {
    signers: [
        {
            userEmail: string
            signed: boolean
            signature?: string
            signatureTimestamp?: Date
        }
    ]
    toSign: string
    validated: boolean
    description?: String
    creation: Date
    inAppURL?: string
}

export const signMessage = async (library: any, account: any, toSign:string) => {
    return await library
        .getSigner(account)
        .signMessage(toSign)
        
}

export const didUserSigned = (email:string, signature:Signature) => {
    const signer = signature.signers.find((user: any) => user.userEmail === email)
    return signer ? signer!.signed  : false
}
