import { FC, useContext } from "react"
import { Redirect, Route, RouteProps } from "react-router"
import { TokenContext } from "../contexts/TokenContext"

export type ProtectedRouteProps = {
    authenticationPath?: string
} & RouteProps

const defaultProtectedRouteProps: ProtectedRouteProps = {
    authenticationPath: "/login"
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
    authenticationPath,
    ...routeProps
}: ProtectedRouteProps) => {
    const { token } = useContext(TokenContext)

    if (token) {
        return <Route {...routeProps} />
    } else {
        return <Redirect to={{ pathname: authenticationPath }} />
    }
}

ProtectedRoute.defaultProps = defaultProtectedRouteProps

export default ProtectedRoute
