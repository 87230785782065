import axios from "axios"
import { Form, Formik } from "formik"
import { useContext } from "react"
import { FunctionComponent, useState } from "react"
import { Link, Redirect } from "react-router-dom"
import classes from "../../classes/classes"
import ErrorComponent from "../../components/Error"
import Error from "../../components/Error"
import SimpleInput from "../../components/fields/SimpleInput"
import LoginLayout from "../../components/Layout/LoginLayout"
import { config } from "../../config/constants"
import { TokenContext } from "../../contexts/TokenContext"
import { UserContext } from "../../contexts/UserContext"

const Login: FunctionComponent = () => {
    const {loggedUser, setUser} = useContext(UserContext)
    const {token, setToken} = useContext(TokenContext)
    const resetErrorState = () => {
        setErrorMessage("")
    }
    const [errorMessage, setErrorMessage] = useState("")
    const login = (values: any) => {
        const loginData = new URLSearchParams()
        loginData.append("email", values.email)
        loginData.append("password", values.password)
        axios
            .post(`${config.url.API_URL}/login`, loginData, {
                headers: {
                    "content-type":
                        "application/x-www-form-urlencoded;charset=utf-8",
                },
            })
            .then((res) => {
                setToken(res.data.token)
                setUser(res.data.user)
                
            })
            .catch((err) => {
                console.log(err)
                setErrorMessage(err.response.data.error)
            })
    }
    return (
        <LoginLayout title="login">
            {(token && loggedUser) && (<Redirect push to="/"/>)}
            <Formik
                initialValues={{ email: "", password: "" }}
                onSubmit={login}
            >
                <Form>
                    <SimpleInput
                        name="email"
                        type="email"
                        placeholder="Email"
                        label="Email"
                    />
                    <SimpleInput
                        name="password"
                        type="password"
                        placeholder="Password"
                        label="Password"
                    />
                    {/* TODO add route + email template */}
                    <Link to="/reset">
                        <div className="flex justify-end mt-2 text-xs text-gray-600">
                            Forget Password?
                        </div>
                    </Link>
                    <button type="submit" className={classes.neutralButton}>
                        Login
                    </button>
                    <div className="mt-3">
                        <Link
                            to="/register"
                            className="text-gray-400 text-sm mt-3"
                        >
                            Don’t have an account?{" "}
                            <span className="font-bold text-black">
                                Sign Up
                            </span>
                        </Link>
                    </div>
                </Form>
            </Formik>
            {errorMessage && (
                <ErrorComponent
                    message={errorMessage}
                    resetErrorState={resetErrorState}
                    className="mt-4"
                />
            )}
        </LoginLayout>
    )
}

export default Login
