import { FunctionComponent } from "react"
import { useGetArbitragesProcedures } from "../hooks/usGetProcedures"
import { DetailsElement } from "./DetailsElement"

const ArbitrationProcedure: FunctionComponent<{ dataState: any }> = ({
    dataState,
}) => {
    const arb = useGetArbitragesProcedures(dataState._id)
    return arb !== undefined && arb !== null ? (
        <div className="mt-5 rounded bg-red-400 bg-opacity-30 p-3">
            <span className="text-red-500 font-bold">
                An Arbitration is in progress
            </span>
        </div>
    ) : (
        <></>
    )
}

export default ArbitrationProcedure