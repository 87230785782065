import classNames from "classnames"
import { FunctionComponent } from "react"
import classes from "../classes/classes"
import { PageWithTitle } from "../components/Layout/AppLayout"
import { useHistory } from "react-router-dom"

const ProcedureConnect: FunctionComponent = () => {
    const history = useHistory()
    return (
        <PageWithTitle
            title="Connect to a Smart Contract"
            description={
                "If you wish, you can connect your newly created procedure to a smart contract"
            }
        >
            <div className="flex items-center justify-center p-8 rounded bg-gray-400 bg-opacity-10 mt-20">
                <div>
                <div className={classNames(classes.pageSubtitle)}> Do you wish to connect a Smart Contract to this Contract ?</div>
                <div className="flex space-x-1 mt-4 justify-center items-center">
                    <button onClick={(e) => {
                        e.preventDefault()
                        history.push("/smart-contract-generator")
                    }}>
                        <span className={classNames(classes.button, "bg-blue-400 hover:bg-blue-500 text-white inline-block")}>Yes, proceed</span>
                    </button>
                    <button onClick={(e) => {
                        e.preventDefault()
                        history.push("/procedures")
                    }}>
                        <span className={classNames(classes.button, "bg-red-400 hover:bg-red-500 text-white inline-block")}>No, return Home</span>
                    </button>
                </div>
                </div>
            </div>
        </PageWithTitle>
    )
}

export default ProcedureConnect
