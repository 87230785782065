import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { config } from "../config/constants"
import { TokenContext } from "../contexts/TokenContext"
import { formatError, sendGet } from "../helpers/submit"

const useGetProcedures = () => {
    const [dataState, setData] = useState([])
    const { token } = useContext(TokenContext)
    useEffect(() => {
        axios
            .get(`${config.url.API_URL}/procedure`, {
                params: { token: token },
            })
            .then((res) => {
                const procedures = res.data.procedures
                if (dataState !== procedures) {
                    setData(procedures)
                }
            })
    }, [])

    return dataState
}
export const useGetFreeContracts = () => {
    const [dataState, setData] = useState([])
    const { token } = useContext(TokenContext)
    useEffect(() => {
        axios
            .get(`${config.url.API_URL}/custom-contract`, {
                params: { token: token },
            })
            .then((res) => {
                const procedures = res.data.procedures
                if (dataState !== procedures) {
                    setData(procedures)
                }
            })
    }, [])

    return dataState
}
export const useGetSmartContracts = () => {
    const [dataState, setData] = useState([])
    const { token } = useContext(TokenContext)
    useEffect(() => {
        axios
            .get(`${config.url.API_URL}/smart-contract`, {
                params: { token: token },
            })
            .then((res) => {
                const procedures = res.data.data
                if (dataState !== procedures) {
                    setData(procedures)
                }
            })
    }, [])

    return dataState
}

export const useGetArbitragesProcedures = (clauseId: string) => {
    const [dataState, setData] = useState()
    const { token } = useContext(TokenContext)

    const onSuccess = async (message: string, payload: any, status: number) => {
        setData(payload)
    }

    const onError = (message: string, reason: string, status: number) => {
        return setData(undefined)
    }

    useEffect(() => {
        sendGet(
            `${config.url.API_URL}/procedure/${clauseId}/arbitrage`,
            token,
            onSuccess,
            onError
        )
    }, [])

    return dataState
}
export const useGetDetailsForAllProcedures = () => {
    const [dataState, setData] = useState()
    const { token } = useContext(TokenContext)

    const onSuccess = async (message: string, payload: any, status: number) => {
        setData(payload)
    }

    const onError = (message: string, reason: string, status: number) => {
        return setData(undefined)
    }

    useEffect(() => {
        sendGet(
            `${config.url.API_URL}/procedure/details`,
            token,
            onSuccess,
            onError
        )
    }, [])

    return dataState
}

export default useGetProcedures

function setError(arg0: any) {
    throw new Error("Function not implemented.")
}
