import AppLayout from "../../components/Layout/AppLayout"
import Error from "../../components/Error"
import useSignUpForm from "../../hooks/loginform"
import { Link } from "react-router-dom"
import axios from "axios"
import React, { useState, useEffect, FunctionComponent, useContext } from "react"
import { useHistory } from "react-router-dom"
import classnames from "classnames"
import classes from "../../classes/classes"
import { BsInfoSquareFill, BsFillPlusCircleFill } from "react-icons/bs"
import { useParams } from "react-router-dom"
import { config } from "../../config/constants"
import { Form, Formik } from "formik"
import Radio from "../../components/fields/Radio"
import Toggle, { ToggleSmall } from "../../components/fields/Toggle"
import SimpleInput from "../../components/fields/SimpleInput"
import TextArea from "../../components/fields/TextArea"
import UploadedFiles from "../../components/UploadedFiles"
import UploadFiles, { ListFiles } from "../../components/fields/UploadFiles"
import Spinner from "../../components/Spinner"
import { TokenContext } from "../../contexts/TokenContext"

const NewArbitrage: FunctionComponent = () => {
    const history = useHistory()
    const params = useParams()
    const [dataState, setData] = useState()
    const [errorMessage, setErrorMessage] = useState("")
    const {token} = useContext(TokenContext)
    const procedureId = (params as any).procedureId

    useEffect(() => {
        const getProcedure = async () => {
            axios
                .get(`${config.url.API_URL}/procedure/${procedureId}`, {
                    params: { token: token },
                })
                .then((res) => {
                    const procedure = res.data.data.procedures
                    if (dataState !== procedure) {
                        setData(procedure)
                    }
                })
                .catch((err) => {})
        }
        getProcedure()
    }, [])

    const submit = (values: any) => {
        if (values.notice) {
            const valuesCopy = values
            const contracts = valuesCopy.uploadFiles
            delete valuesCopy.uploadFiles
            delete valuesCopy.notice
            const dataform = new FormData()
            dataform.append("arbitrageData", JSON.stringify(valuesCopy))
            if (contracts !== null && contracts !== undefined) {
                for (const contract of contracts as any) {
                    dataform.append("files", contract)
                }
            }
            axios
                .post(
                    `${config.url.API_URL}/procedure/${procedureId}/arbitrage/new`,
                    dataform,
                    {
                        params: { token: token },
                        headers: {
                            "content-type":
                                "application/x-www-form-urlencoded;charset=utf-8",
                        },
                    }
                )
                .then((res) => {
                    history.push(`/procedures/${procedureId}`)
                })
                .catch((err) => {
                    console.log(err.response)
                    setErrorMessage(err.response.data.error)
                })
        }
    }
    const initialValues = {
        evidence: "",
        stake: "",
        expert: false,
        technical: false,
        classic: false,
        urgent: false,
        situation: "",
        notice: false,
    }
    return (
        <AppLayout title="New Arbitrage">
            {dataState !== undefined ? (
                <div className="flex h-full">
                    <div className="p-8 w-full">
                        <nav className="bg-grey-light rounded font-sans w-full">
                            <ol className="list-reset flex text-grey-dark mb-4">
                                <li>
                                    <Link
                                        to={`/procedures/`}
                                        className="font-bold"
                                    >
                                        Contract
                                    </Link>
                                </li>
                                <li>
                                    <span className="mx-2">/</span>
                                </li>
                                <li>
                                    <Link
                                        to={`/procedures/${
                                            (dataState as any)._id
                                        }`}
                                        className="font-bold"
                                    >
                                        {`${(dataState as any)._id}`}
                                    </Link>
                                </li>
                                <li>
                                    <span className="mx-2">/</span>
                                </li>
                                <li>new</li>
                            </ol>
                        </nav>
                        <div className="flex space-x-4"></div>
                        <div className="lg:w-3/4 mx-auto shadow-xl p-8 bg-white">
                            <Formik
                                initialValues={initialValues}
                                enableReinitialize={true}
                                onSubmit={(values) => submit(values)}
                            >
                                {({ values, setFieldValue }) => {
                                    return (
                                        <Form>
                                            <h2
                                                className={classnames(
                                                    classes.formH2,
                                                    "my-2"
                                                )}
                                            >
                                                Arbitrage requested
                                            </h2>
                                            <Toggle
                                                name={"urgent"}
                                                label={
                                                    "Is your request urgent?"
                                                }
                                                toggleText={
                                                    "My request is urgent"
                                                }
                                            />
                                            <SimpleInput
                                                name={"stake"}
                                                type={"text"}
                                                placeholder={"Amount"}
                                                label={
                                                    "What is the total value at stake in this dispute in CHF at the moment of the request?"
                                                }
                                                tooltipText="What is the total amount in CHF that you are asking (damages, interests...)"
                                            />
                                            <label
                                                className={classnames(
                                                    classes.label
                                                )}
                                            >
                                                What are the Arbitrations
                                                requested?
                                            </label>
                                            {(dataState as any).procedureData
                                                .technicalChecked && (
                                                <ToggleSmall
                                                    name={"technical"}
                                                    toggleText={
                                                        "Technical Arbitration"
                                                    }
                                                />
                                            )}
                                            {(dataState as any).procedureData
                                                .legalChecked &&
                                                (dataState as any).procedureData
                                                    .legalData
                                                    .expertiseChecked && (
                                                    <ToggleSmall
                                                        name={"expert"}
                                                        toggleText={
                                                            "Expert Arbitration"
                                                        }
                                                    />
                                                )}
                                            {(dataState as any).procedureData
                                                .legalChecked &&
                                                (dataState as any).procedureData
                                                    .legalData
                                                    .expertiseChecked && (
                                                    <ToggleSmall
                                                        name={"expert"}
                                                        toggleText={
                                                            "Classic Arbitration"
                                                        }
                                                    />
                                                )}
                                            <TextArea
                                                name={"situation"}
                                                placeholder={
                                                    "Explain the situation"
                                                }
                                                label={
                                                    "Explain the factual situation"
                                                }
                                            />
                                            <TextArea
                                                name={"evidence"}
                                                placeholder={"Evidences.."}
                                                label={"Provide evidence"}
                                            />
                                            <UploadFiles
                                                name={"uploadFiles"}
                                                label={"Upload files"}
                                            />
                                            <ListFiles name={"uploadFiles"}/>
                                            <Toggle
                                                name={"notice"}
                                                toggleText={
                                                    "Notice: please note that the present request does not necessarily create a valid request for the triggering of the procedure. This is especially always the case in the eventuality of an external, expert or classical arbitration. Further, the request does not triggers any effect on the blockchain. Please refer to your arbitration and to your relevant Counsel"
                                                }
                                            />
                                            <button
                                                type="submit"
                                                className={classnames(
                                                    classes.stepButton,
                                                    "mt-2"
                                                )}
                                            >
                                                Create
                                            </button>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            ) : (
                <Spinner />
            )}
        </AppLayout>
    )
}

export default NewArbitrage
