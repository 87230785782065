import axios from "axios"
import React, { FunctionComponent, useContext, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import AppLayout from "../../components/Layout/AppLayout"
import ResumeProcedure from "./Resume"
import { config } from "../../config/constants"
import { AnyCnameRecord } from "dns"
import { formatError, sendGet } from "../../helpers/submit"
import { ErrorMessage } from "../../components/State"
import OpenedCustomContract from "./Details"
import { TokenContext } from "../../contexts/TokenContext"

const CustomContractItem: FunctionComponent = () => {
    const params = useParams()
    const history = useHistory()
    const procedureId = (params as any).procedureId
    const [dataState, setData] = useState()
    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)
    const {token} = useContext(TokenContext)

    useEffect(() => {
        const onSuccess = (message: string, payload: any, status: number) => {
            if (payload === null) {
                history.push("/")
            } 
            if (dataState !== payload) {
                setData(payload)
            }
            setSuccess(true)
        }
        const onError = (message: string, reason: string, status: number) => {
            setError(formatError(message, reason, status))
        }

        const loadProcedure = async () => {
            await sendGet<any>(
                `${config.url.API_URL}/custom-contract/${procedureId}`,
                token,
                onSuccess,
                onError
            )
        }
        loadProcedure()
    }, [])

    return (
        <AppLayout title={`View Procedure`}>
            <>
                {(success || error) && (
                    <>
                        {error ? (
                            <ErrorMessage
                                errorMessage={error}
                            />
                        ) : success && (dataState as any).validated ? (
                            <OpenedCustomContract
                                dataState={dataState}
                                token={token}
                            />
                        ) : (
                            <ResumeProcedure
                                token={token}
                            />
                        )}
                    </>
                )}
            </>
        </AppLayout>
    )
}

export default CustomContractItem
