import { useWeb3React } from "@web3-react/core"
import axios from "axios"
import { default as classnames, default as classNames } from "classnames"
import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from "react"
import {
    IoIosCheckmarkCircle,
    IoMdArrowDropdown,
    IoMdArrowDropleft,
} from "react-icons/io"
import { IoCloseCircle } from "react-icons/io5"
import { useHistory } from "react-router-dom"
import classes from "../../classes/classes"
import { DetailsElement } from "../../components/DetailsElement"
import { SignatureDetails } from "../../components/SignatureDetails"
import { config } from "../../config/constants"
import { TokenContext } from "../../contexts/TokenContext"
import { UserContext } from "../../contexts/UserContext"
import { dateHelper } from "../../helpers/formatDate"
import { didUserSigned, Signature, signMessage } from "../../helpers/signatures"
import { formatError, sendGet, sendPost } from "../../helpers/submit"
import { injected } from "../../web3/connectors"

export const Bindings: FunctionComponent<{ dataState: any }> = ({
    dataState,
}) => {
    const history = useHistory()
    const [errorBindings, setErrorBindings] = useState("")
    const [bindings, setBindings] = useState([])
    const [unBindings, setUnBindings] = useState([])
    const { token } = useContext(TokenContext)
    const onSuccess = async (message: string, payload: any, status: number) => {
        setBindings(payload.bindings)
        setUnBindings(payload.unbindings)
    }

    const onError = (message: string, reason: string, status: number) => {
        setErrorBindings(formatError(message, reason, status))
    }

    useEffect(() => {
        sendGet(
            `${config.url.API_URL}/binding/get/${dataState.uuid}`,
            token,
            onSuccess,
            onError
        )
    }, [])

    return (
        <>
            <DetailsElement label="Bindings" />

            <button
                onClick={(e) => {
                    e.preventDefault()
                    history.push(`/binding/new/${dataState.uuid}`)
                }}
            >
                <span
                    className={classNames(
                        classes.button,
                        "bg-blue-400 hover:bg-blue-500 text-white inline-block w-full"
                    )}
                >
                    Bind to contract
                </span>
            </button>
            {bindings.map((binding, i) => {
                return <Binding binding={binding} key={`binding-${i}`} />
            })}
        </>
    )
}

export const Binding: FunctionComponent<{ binding: any }> = ({ binding }) => {
    const [error, setError] = useState("")
    const [contracts, setContracts] = useState<any>()
    const [signature, setSignature] = useState<Signature>()
    const [users, setUsers] = useState<any>()
    const { token } = useContext(TokenContext)

    const onSuccessSignature = async (
        message: string,
        payload: any,
        status: number
    ) => {
        const { signature, users } = payload
        setSignature(signature)
        setUsers(users)
    }
    const onSuccessDetails = async (
        message: string,
        payload: any,
        status: number
    ) => {
        setContracts(payload)
    }

    const onError = (message: string, reason: string, status: number) => {
        setError(formatError(message, reason, status))
    }

    useEffect(() => {
        sendGet(
            `${config.url.API_URL}/signature/${binding.signature}`,
            token,
            onSuccessSignature,
            onError
        )
    }, [])

    useEffect(() => {
        sendGet(
            `${config.url.API_URL}/binding/${binding._id}`,
            token,
            onSuccessDetails,
            onError
        )
    }, [])

    const signers = signature
        ? signature.signers.map((signer) => signer.userEmail)
        : null

    return (
        <>
            {signature && users && contracts && (
                <div className="mt-5 rounded bg-blue-400 bg-opacity-10 p-3">
                    <DeleteBinding uuid={binding._id} binding={binding} />
                    <DetailsElement
                        label="Creation date"
                        value={signature.creation.toString()}
                    />
                    <DetailsElement
                        label="Contract 1"
                        value={`${contracts[0].contract.name} (${contracts[0].contract._id})`}
                        href={`/contract/${contracts[0].contract.uuid}`}
                    />
                    <DetailsElement
                        label="Contract 2"
                        value={`${contracts[1].contract.name} (${contracts[1].contract._id})`}
                        href={`/contract/${contracts[1].contract.uuid}`}
                    />
                    <SignatureDetails users={users} signature={signature} />
                </div>
            )}
        </>
    )
}

export const CreateBindingDeletion: FunctionComponent<{ uuid: string }> = ({
    uuid,
}) => {
    const [deletion, setDeletion] = useState({})
    const [error, setError] = useState("")
    const { token } = useContext(TokenContext)

    const onSuccess = async (message: string, payload: any, status: number) => {
        window.location.reload()
    }

    const onError = (message: string, reason: string, status: number) => {
        setError(formatError(message, reason, status))
    }

    return (
        <button
            onClick={(e) => {
                e.preventDefault()
                sendPost(
                    {},
                    `${config.url.API_URL}/binding/${uuid}/delete`,
                    token,
                    onSuccess,
                    onError
                )
            }}
        >
            <span
                className={classNames(
                    classes.button,
                    "bg-red-400 hover:bg-red-500 text-white inline-block"
                )}
            >
                Delete binding
            </span>
        </button>
    )
}

export const BindingDeletionPreview: FunctionComponent<{
    deleteDetails: any
}> = ({ deleteDetails }) => {
    const [error, setError] = useState("")
    const [signature, setSignature] = useState<Signature>()
    const [users, setUsers] = useState<any>()
    const { token } = useContext(TokenContext)

    const onSuccessSignature = async (
        message: string,
        payload: any,
        status: number
    ) => {
        const { signature, users } = payload
        setSignature(signature)
        setUsers(users)
    }

    const onError = (message: string, reason: string, status: number) => {
        setError(formatError(message, reason, status))
    }

    useEffect(() => {
        sendGet(
            `${config.url.API_URL}/signature/${deleteDetails.signature}`,
            token,
            onSuccessSignature,
            onError
        )
    }, [])

    return (
        <>
            {signature && users && deleteDetails && (
                <>
                    <div className="mt-5 rounded bg-red-400 bg-opacity-30 p-3">
                        <span className="text-red-500 font-bold">
                            Unbinding in progress
                        </span>
                        <button
                            className="my-4 block"
                            onClick={(e) =>
                                axios
                                    .post(
                                        `${config.url.API_URL}/binding/unbinding/${deleteDetails._id}/cancel`,
                                        {},
                                        {
                                            params: { token: token },
                                        }
                                    )
                                    .then((res) => {
                                        window.location.reload()
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                            }
                        >
                            {" "}
                            <span
                                className={classNames(
                                    classes.button,
                                    "bg-red-400 hover:bg-red-500 text-white inline-block"
                                )}
                            >
                                Cancel Unbinding
                            </span>
                        </button>
                        <DetailsElement
                            label="Creation date"
                            value={signature.creation.toString()}
                        />

                        <SignatureDetails users={users} signature={signature} />
                    </div>
                </>
            )}
        </>
    )
}

export const DeleteBinding: FunctionComponent<{
    uuid: string
    binding: any
}> = ({ uuid, binding }) => {
    const [deletion, setDeletion] = useState()
    const [signature, setSignature] = useState()
    const [error, setError] = useState("")
    const { token } = useContext(TokenContext)

    const onSuccess = async (message: string, payload: any, status: number) => {
        setDeletion(payload)
    }
    const onSuccessSignature = async (
        message: string,
        payload: any,
        status: number
    ) => {
        setSignature(payload.signature)
    }

    const onError = (message: string, reason: string, status: number) => {
        setError(formatError(message, reason, status))
    }

    useEffect(() => {
        sendGet(
            `${config.url.API_URL}/binding/unbinding/${uuid}`,
            token,
            onSuccess,
            onError
        )
    }, [])

    useEffect(() => {
        sendGet(
            `${config.url.API_URL}/signature/${binding.signature}`,
            token,
            onSuccessSignature,
            onError
        )
    }, [binding])

    return (
        deletion !== undefined &&
        signature !== undefined ? (
            <>
                {deletion !== null && signature !== null ? (
                    <BindingDeletionPreview deleteDetails={deletion} />
                ) : (signature as any)!.validated ? (
                    <CreateBindingDeletion uuid={uuid} />
                ) : (
                    <button
                        className="my-4 block"
                        onClick={(e) =>
                            axios
                                .post(
                                    `${config.url.API_URL}/binding/${binding._id}/cancel`,
                                    {},
                                    {
                                        params: { token: token },
                                    }
                                )
                                .then((res) => {
                                    window.location.reload()
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                        }
                    >
                        {" "}
                        <span
                            className={classNames(
                                classes.button,
                                "bg-red-400 hover:bg-red-500 text-white inline-block"
                            )}
                        >
                            Cancel binding
                        </span>
                    </button>
                )}
            </>
        ):<></>
    )
}
