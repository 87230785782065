import { BIPShamir } from "@vquelque/shamir_bip39"
import classnames from "classnames"
import { utils } from "ethers"
import { FieldArray, Form, Formik, FormikHelpers } from "formik"
import React, { FunctionComponent } from "react"
import classes from "../../../classes/classes"
import SimpleInput from "../../../components/fields/SimpleInput"
import Spinner from "../../../components/Spinner"
import { arbitriEncPubKey, notaryEncPubKey } from "../../../config/constants"

interface Values {
    shares: string[]
}

const initialValues: Values = {
    shares: Array(1).fill(""),
}

const recoverShares = async (
    values: Values,
    { setSubmitting }: FormikHelpers<Values>
) => {
    try {
        let shares = values.shares.filter((s) => s !== "")
        let recovered = BIPShamir.recoverSecret(shares)
        //get signed from database
        const signed = ""
        if (signed) {
            //compute hash of shares
            const hShare0 = utils.keccak256(Buffer.from(shares[0]))
            const hShare1 = utils.keccak256(Buffer.from(shares[1]))
            const confirmMsg = utils.keccak256(
                Buffer.from(
                    arbitriEncPubKey + hShare0 + notaryEncPubKey + hShare1
                )
            )
            const ver = await utils.verifyMessage(confirmMsg, signed)
            console.log(ver)
        }
        alert(recovered)
    } catch (error) {
        alert(error)
    }
}

const RecoverSharesForm: FunctionComponent = () => (
    <Formik initialValues={initialValues} onSubmit={recoverShares}>
        {({ values, isSubmitting }) => (
            <Form>
                <FieldArray name="shares">
                    {({ insert, remove, push }) => (
                        <div>
                            {values.shares.length > 0 &&
                                values.shares.map((share, index) => (
                                    <div className="flex gap-x-1 items-center">
                                        <div className="w-full">
                                            <SimpleInput
                                                name={`shares.${index}`}
                                                type="text"
                                                placeholder="Enter your mnemonic"
                                                label="Your BIP-39 mnemonic"
                                                tooltipText="Enter your BIP-39 mnemonic here"
                                            />
                                        </div>
                                        <button
                                            className={classnames(
                                                classes.stepButton,
                                                "disabled:opacity-50"
                                            )}
                                            onClick={(e) => {
                                                remove(index)
                                                return e.preventDefault()
                                            }}
                                            disabled={index < 1}
                                        >
                                            Remove share
                                        </button>
                                    </div>
                                ))}
                            <button
                                type="button"
                                className={classnames(
                                    classes.stepButton,
                                    "mb-7"
                                )}
                                onClick={(e) => {
                                    push("")
                                    return e.preventDefault()
                                }}
                            >
                                Add Share
                            </button>
                        </div>
                    )}
                </FieldArray>
                <div className="flex items-center gap-x-2">
                    <button
                        className={classnames(classes.mainButton, "w-44")}
                        disabled={isSubmitting}
                        type="submit"
                    >
                        Recover mnenmonic
                    </button>
                    {isSubmitting ? <Spinner /> : ""}
                </div>
            </Form>
        )}
    </Formik>
)

export default RecoverSharesForm
