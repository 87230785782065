import classnames from "classnames"
import { Field, useFormikContext } from "formik"
import React, { FunctionComponent, useEffect } from "react"
import { BsInfoSquareFill } from "react-icons/bs"
import classes from "../../../classes/classes"
import Radio from "../../../components/fields/Radio"
import Select from "../../../components/fields/Select"
import SimpleInput from "../../../components/fields/SimpleInput"
import TextArea from "../../../components/fields/TextArea"
import Toggle from "../../../components/fields/Toggle"
import useFormNoSubmit from "../../../hooks/useForm"

const ClassicLDIP: FunctionComponent = () => {
    const { values, submitForm } = useFormikContext()
    const descriptions = [
        'They shall be freely appointed in accordance with the following procedure ("other services" section) by the Nomination and Proposals Committee within 14 working days upon the express request of the Parties. The Arbitrators shall be appointed freely. This appointment is binding between the Parties. The Arbitrators must in particular have the following qualities, a quality which Arbitri is entirely free to judge.',
        'They shall be freely proposed according to the procedure below ("other services" section) by the Nomination and Proposals Committee of Arbitration within 14 working days from the express request of the Parties. The Arbitrators shall be appointed freely. They must in particular have the following qualities, a quality which Arbitri is entirely free to judge.',
        "Appointing the Arbitri Arbitrator(s) from the drop-down list",
    ]
    return (
        <>
            <Toggle
                name="classLDIPData.placeOfJurisdiction"
                toggleText="In the event that the Arbitrators are unable to determine the place of jurisdiction or the law applicable to the basic underlying relationship, the place of jurisdiction shall be deemed to be Geneva and Swiss law shall be deemed to apply.
In particular, securities for which it is not possible to determine the law or jurisdiction are deemed to be issued in Switzerland and subject to Swiss law
"
                label="Procedural seat of the court by default"
            />
            <SimpleInput
                name="classLDIPData.seat"
                label="The seat of the arbitration shall be"
                placeholder="name of a city in Switzerland"
                type="text"
            />
            <Select
                name="legalData.classicData.classicLDIPData.language"
                options={["English", "French", "German", "Other"]}
                label="Language"
            />
            {(values as any).legalData.classicData.classicLDIPData.language ==
                "Other" && (
                <SimpleInput
                    name="legalData.classicData.classicLDIPData.otherLanguage"
                    type="text"
                    placeholder="Language"
                    label="Other language"
                />
            )}
            <div className="space-y-2 mb-5">
                <label
                    htmlFor={"legalData.classicData.classicLDIPData.procedure"}
                    className={classnames(classes.label)}
                >
                    {"Arbitration Procedural rules"}
                </label>
                <label className="block flex items-center">
                    <Field
                        type="radio"
                        name="legalData.classicData.classicLDIPData.procedure"
                        value="0"
                    />
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-sm ml-4"
                        )}
                    >
                        {" "}
                        The procedural rules of Swiss law on international
                        arbitration (art. 176 PILA), excluding the rules of
                        domestic arbitration (art. 353 CPC).
                    </p>
                </label>
                <label className="block flex items-center">
                    <Field
                        type="radio"
                        name="legalData.classicData.classicLDIPData.procedure"
                        value="1"
                    />
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-sm ml-4"
                        )}
                    >
                        {" "}
                        Domestic arbitration rules (art. 353 CPC) excluding art.
                        176 PILA.
                    </p>
                </label>
                <label className="block flex items-center">
                    <Field
                        type="radio"
                        name="legalData.classicData.classicLDIPData.procedure"
                        value="2"
                    />
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-sm ml-4"
                        )}
                    >
                        {" "}
                        The procedural rules of Swiss law on international
                        arbitration (art. 176 PILA) excluding the rules of
                        domestic arbitration (art. 353 CPC). However, in case of
                        domestic arbitration, the domestic arbitration rules
                        (art. 353 CPC) apply exclusively.
                    </p>
                </label>
                <label className="block flex items-center">
                    <Field
                        type="radio"
                        name="legalData.classicData.classicLDIPData.procedure"
                        value="3"
                    />
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-sm ml-4"
                        )}
                    >
                        {" "}
                        The procedural rules of Swiss law on international
                        arbitration (Art. 176 PILA), including the rules of
                        domestic arbitration (Art. 353 CPC) for the procedure.
                        In the case of domestic arbitration, the domestic
                        arbitration rules (Art. 353 CPC) shall apply
                        exclusively.
                    </p>
                </label>
            </div>
            <div className="space-y-2 mb-5">
                <label
                    htmlFor={
                        "legalData.classicData.classicLDIPData.designation"
                    }
                    className={classnames(classes.label)}
                >
                    {"Arbitrator(s) Designation"}
                </label>
                <label className="block flex items-center">
                    <Field
                        type="radio"
                        name="legalData.classicData.classicLDIPData.designation"
                        value="freelyAppointed"
                    />
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-sm ml-4"
                        )}
                    >
                        {" "}
                        They shall be freely appointed in accordance with the
                        following procedure ("other services" section) by the
                        Nomination and Proposals Committee within 14 working
                        days upon the express request of the Parties. The
                        Arbitrators shall be appointed freely. This appointment
                        is binding between the Parties. The Arbitrators must in
                        particular have the following qualities, a quality which
                        Arbitri is entirely free to judge.
                    </p>
                </label>
                {(values as any).legalData.classicData.classicLDIPData
                    .designation == "freelyAppointed" && (
                    <div className="ml-8">
                        <SimpleInput
                            name="legalData.classicData.classicLDIPData.expertQualification"
                            type="text"
                            placeholder="Qualifications"
                            label="Arbitrator(s) qualifications (technical knowledge, etc)"
                        />
                    </div>
                )}
                <label className="block flex items-center">
                    <Field
                        type="radio"
                        name="legalData.classicData.classicLDIPData.designation"
                        value="freelyProposed"
                    />
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-sm ml-4"
                        )}
                    >
                        {" "}
                        They shall be freely proposed according to the procedure
                        below ("other services" section) by the Nomination and
                        Proposals Committee of Arbitration within 14 working
                        days from the express request of the Parties. The
                        Arbitrators shall be appointed freely. They must in
                        particular have the following qualities, a quality which
                        Arbitri is entirely free to judge.
                    </p>
                </label>
                {(values as any).legalData.classicData.classicLDIPData
                    .designation == "freelyProposed" && (
                    <div className="ml-8">
                        <SimpleInput
                            name="legalData.classicData.classicLDIPData.expertQualification"
                            type="text"
                            placeholder="Qualifications"
                            label="Arbitrator(s) qualifications (technical knowledge, etc)"
                        />
                    </div>
                )}
                <label className="block flex items-center">
                    <Field
                        type="radio"
                        name="legalData.classicData.classicLDIPData.designation"
                        value="reffered"
                    />
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-sm ml-4"
                        )}
                    >
                        {" "}
                        They are referred to {" "}
                        <Field
                            className={classnames(classes.inlineInputs)}
                            placeholder="designation method"
                            type="text"
                            name="legalData.classicData.classicLDIPData.refferedData.method"
                        />{" "}
                        by{" "}
                        <Field
                            className={classnames(classes.inlineInputs)}
                            placeholder="insert name"
                            type="text"
                            name="legalData.classicData.classicLDIPData.refferedData.referrer"
                        />
                        .
                    </p>
                </label>
                {(values as any).legalData.classicData.classicLDIPData
                    .designation == "reffered" && (
                    <div className="ml-8">
                        <Toggle
                            className="text-xs h-3 w-3"
                            name="legalData.classicData.classicLDIPData.refferedData.designationDelay"
                            toggleText="They shall be designated within 14 working days upon the express request of the Parties."
                        />
                        <Toggle
                            className="text-xs h-3 w-3"
                            name="legalData.classicData.classicLDIPData.refferedData.freely"
                            toggleText="The Arbitrators shall be appointed freely. Such appointment shall be binding on the Parties. The arbitrators shall, in particular, have the following qualities, which the person appointed shall be entirely free to judge."
                        />
                        <SimpleInput
                            name="legalData.classicData.classicLDIPData.expertQualification"
                            type="text"
                            placeholder="Qualifications"
                            label="Arbitrator(s) qualifications (technical knowledge, etc)"
                        />
                    </div>
                )}
                <label className="block flex items-center">
                    <Field
                        type="radio"
                        name="legalData.classicData.classicLDIPData.designation"
                        value="offered"
                    />
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-sm ml-4"
                        )}
                    >
                        {" "}
                        They are referred{" "}
                        <Field
                            className={classnames(classes.inlineInputs)}
                            placeholder="designation method"
                            type="text"
                            name="legalData.classicData.classicLDIPData.offeredData.method"
                        />{" "}
                        by{" "}
                        <Field
                            className={classnames(classes.inlineInputs)}
                            placeholder="insert name"
                            type="text"
                            name="legalData.classicData.classicLDIPData.offeredData.referrer"
                        />
                    </p>
                </label>
                {(values as any).legalData.classicData.classicLDIPData
                    .designation == "offered" && (
                    <div className="ml-8">
                        <Toggle
                            className="text-xs h-3 w-3"
                            name="legalData.classicData.classicLDIPData.offeredData.designationDelay"
                            toggleText="They shall be designated within 14 working days upon the express request of the Parties."
                        />
                        <Toggle
                            className="text-xs h-3 w-3"
                            name="legalData.classicData.classicLDIPData.offeredData.freely"
                            toggleText="The Arbitrators shall be appointed freely. Such appointment shall be binding on the Parties. The Arbitrators shall, in particular, have the following qualities, which the person appointed shall be entirely free to judge."
                        />
                        <SimpleInput
                            name="legalData.classicData.classicLDIPData.expertQualification"
                            type="text"
                            placeholder="Qualifications"
                            label="Arbitrator(s) qualifications (technical knowledge, etc)"
                        />
                    </div>
                )}
                <label className="block flex items-center">
                    <Field
                        type="radio"
                        name="legalData.classicData.classicLDIPData.designation"
                        value="arbitri"
                    />
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-sm ml-4"
                        )}
                    >
                        {" "}
                        Appointing the Arbitri Arbitrator(s) from the
                        drop-down list
                    </p>
                </label>
                {(values as any).legalData.classicData.classicLDIPData
                    .designation == "arbitri" && (
                    <div className="ml-8">
                        <Select
                            name="legalData.classicData.classicLDIPData.arbitriData.arbitrator"
                            options={["Gabriel Jaccard", "Gaspard Peduzzi"]}
                            label="Select Arbitrator"
                        />
                        <SimpleInput
                            name="legalData.classicData.classicLDIPData.expertQualification"
                            type="text"
                            placeholder="Qualifications"
                            label="Arbitrator(s) qualifications (technical knowledge, etc)"
                        />
                    </div>
                )}
            </div>
            <Toggle
                name="legalData.classicData.classicLDIPData.priorConciliation"
                label="Conciliation"
                toggleText="The parties wish the Arbitrator, before deciding the case, to make every effort to achieve conciliation between them. If necessary, and if necessary, he shall record the result of this conciliation. Only in the absence of a successful conciliation shall the Arbitrator decide the dispute"
            />
            <Toggle
                name="legalData.classicData.classicLDIPData.mediation"
                label="Mediation"
                toggleText="The parties may also decide at any time to submit their dispute to mediation in accordance with the Swiss Rules of Commercial Mediation of the Swiss Chambers' Arbitration Institution."
            />
            <SimpleInput
                name="legalData.classicData.classicLDIPData.resolutionTime"
                type="text"
                placeholder="Time"
                label="Resolution time limit"
            />
            <Radio
                label="Law applicable to the Award"
                name="legalData.classicData.classicLDIPData.law"
                options={["0", "1"]}
                optionsTexts={[
                    "The Arbitral Tribunal shall decide in accordance with the rules of law agreed upon by the parties. Thereafter, it shall apply the default rules provided for in this Chapter if they are chosen. Finally, it may, in the absence of a choice by the Parties and in the absence of a choice of law, apply the rules of law with which the dispute is most closely connected. In addition, the Tribunal has the possibility to decide ex æquo et bono.",
                    "The Tribunal shall decide ex æquo et bono",
                ]}
            />
            <Radio
                label="Motivation of the Award"
                name="legalData.classicData.classicLDIPData.reasons"
                options={["0", "1", "2"]}
                optionsTexts={[
                    "must be motivated, or at least briefly motivated",
                    "does not need to be motivated",
                    "Freely written",
                ]}
            />
            {(values as any).legalData.classicData.classicLDIPData.reasons
                     == "2" && (
                    <div className="ml-8">
                        <SimpleInput
                            name="legalData.classicData.classicLDIPData.freeReason"
                            type="text"
                            placeholder="Motivations of the award"
                            label="Freely written motivations of the award"
                        />
                    </div>
                )}
            <Radio
                label="Waiver of rescourse"
                name="legalData.classicData.classicLDIPData.waiver"
                options={["0", "1"]}
                optionsTexts={[
                    "Parties to waive recourse on incidental decisions",
                    `The Parties waive recourse to appeal against incidental decisions except : 
a) where the sole arbitrator has been irregularly appointed or the arbitral tribunal irregularly composed;
b) where the arbitral tribunal has wrongly declared itself competent or incompetent
`,
                ]}
            />
            <Radio
                label="In the event that both parties have no domicile, habitual residence or establishment in Switzerland"
                name="legalData.classicData.classicLDIPData.noResidence"
                options={["0", "1"]}
                optionsTexts={[
                    "They declare that they exclude any recourse against the final award of the arbitral tribunal",
                    `They exclude appeal except on the following grounds (art. 190, para. 2 PILA) :
 Either: a. where the sole arbitrator has been improperly appointed or the arbitral tribunal has been improperly composed ;
 At the arbitral tribunal's discretion: b. where the arbitral tribunal has wrongly declared itself competent or incompetent;
 Either: c. where the arbitral tribunal has ruled beyond the claims before it or where it has failed to rule on one of the grounds of the claim;
 Either: d. when the equality of the parties or their right to be heard in the adversarial procedure has not been respected;
 Either: e. where the award is incompatible with public policy

`,
                ]}
            />
        </>
    )
}

export default ClassicLDIP
