import { BsFillPlusCircleFill } from "react-icons/bs"
import { FaFileContract } from "react-icons/fa"
import { FiLogOut } from "react-icons/fi"
import { NavLink } from "react-router-dom"

function BottomNavigation() {

    const clearToken = () => {
        window.localStorage.clear()
        window.location.reload()
    }
    return (
        <div className="bg-black fixed bottom-0 w-screen h-14 box-border fixed shadow-lg flex flex-col justify-center md:hidden">
            <div className="flex items-center h-14 justify-around text-white text-lg font-bold">
                <NavLink
                    exact
                    to="/procedures"
                    activeClassName="bg-white text-black"
                    className="block w-14 h-14 flex flex-col justify-center items-center px-3 py-3"
                >
                    <FaFileContract />
                </NavLink>
                <NavLink
                    exact
                    to="/procedures/new"
                    activeClassName="bg-white text-black"
                    className="block w-14 h-14 flex flex-col justify-center items-center px-3 py-3 "
                >
                    <BsFillPlusCircleFill />{" "}
                </NavLink>
                <button
                    className="text-white w-11 text-black rounded-full uppercase px-4 text-sm tracking-widest font-bold py-1"
                    onClick={clearToken}
                >
                    <FiLogOut />
                </button>
            </div>
        </div>
    )
}

export default BottomNavigation
