import { Link, NavLink } from "react-router-dom"
import AppLayout from "../components/Layout/AppLayout"

const NotFound = () => {
    return (
        <AppLayout>
            <div className="h-full w-full flex flex-col items-center justify-center">
                <img
                    className="w-20 self-center"
                    src="/arbitri_logo_dark.png"
                    alt="Arbitri logo"
                />
                <span className="text-3xl font-bold mt-8">oops... 404 Not found</span>
                <Link
                    to="/procedures"
                    className="text-blue-500 font-bold mt-8"
                >
                    <span>Return to Home</span>
                </Link>
            </div>
        </AppLayout>
    )
}

export default NotFound
