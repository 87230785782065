import React, { createContext } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

type TokenContext = {
  token: any;
  setToken: (token: any) => void;
};


export const TokenContext = createContext<TokenContext>({
    token: "",
    setToken: () => ""
});

const TokenContextProvider: React.FC<{children:React.ReactNode}> = ({ children }) => {
    const [token, setToken] = useLocalStorage("token", "")
    return (
        <TokenContext.Provider value={{ token, setToken }}>
          {children}
        </TokenContext.Provider>
      );
}

export default TokenContextProvider

