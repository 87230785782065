import classnames from "classnames"
import React from "react"
import { FunctionComponent, useState } from "react"
import classes from "../classes/classes"
import AppLayout from "./Layout/AppLayout"

const Selector: FunctionComponent<{ SelectorButton?: FunctionComponent, title: any }> = ({
    SelectorButton,
    children,
    title,
}) => {
    const childrenArray = React.Children.toArray(children).sort(
        (c: any) => c.props.admin
    ) as React.ReactElement<any>[]
    const [step, setStep] = useState(0)
    const currentChild = childrenArray[step]

    const userComponents = childrenArray
        .filter((c) => !c.props.admin)
        .map((child: any, index: number) => {
            return (
                <div
                    onClick={(e) => setStep(index)}
                    className={classnames(
                        "font-bold px-3 py-1 border-b border-gray-200 cursor-pointer",
                        index === step
                            ? "bg-blue-500 text-white"
                            : "text-gray-700",
                        index ==
                            childrenArray.filter((c) => !c.props.admin).length -
                                1 && "rounded-b-lg",
                        index == 0 && "rounded-t-lg"
                    )}
                >
                    {child.props.label}
                </div>
            )
        })

    let adminComps = childrenArray
        .filter((c) => c.props.admin)
        .map((child: any, index: number) => {
            const nStep = index + userComponents.length
            return (
                <div
                    onClick={(e) => setStep(nStep)}
                    className={classnames(
                        "font-bold px-3 py-1 border-b border-gray-200 cursor-pointer",
                        nStep === step
                            ? "bg-blue-500 text-white"
                            : "text-gray-700",
                        index ==
                            childrenArray.filter((c) => c.props.admin).length -
                                1 && "rounded-b-lg",
                        index == 0 && "rounded-t-lg"
                    )}
                >
                    {child.props.label}
                </div>
            )
        })

        const sidebar = (
            <div className="flex flex-col h-full w-full justify-between py-5">
            <div>
                <div className="bg-white shadow rounded-lg">
                    {userComponents}
                </div>
                {SelectorButton ? <SelectorButton></SelectorButton> : ""}
            </div>

            <div className="bg-white shadow rounded-lg">{adminComps}</div>
            </div>
        )

    return (
        <AppLayout title={title} sidebar2={sidebar}>
            <div className="p-5">{currentChild}</div>
        </AppLayout>
    )
}
export const Subpage: FunctionComponent<{
    children: any
    label: string
    admin?: any
}> = ({ children, label, admin }) => {
    const childrenArray = React.Children.toArray(
        children
    ) as React.ReactElement<any>[]
    return (
        <div className="">
            <div className="text-gray-700">
                <h2 className={classnames(classes.pageSubtitle)}>{label}</h2>
            </div>
            {childrenArray.map((child) => {
                return child
            })}
        </div>
    )
}

export default Selector
