import { FunctionComponent } from "react"
import { BsInfoSquareFill } from "react-icons/bs"

const Tooltip: FunctionComponent<{
    text: string
}> = ({ text }) => {
    return (
        <div className="group cursor-pointer relative inline-block w-28 text-center">
            <BsInfoSquareFill />
            <div className="opacity-0 w-40 bg-black text-white mb-1 text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -left-1/2 ml-14 px-3 pointer-events-none">
                {text}
            </div>
        </div>
    )
}

export default Tooltip
