import { useWeb3React } from "@web3-react/core"
import classNames from "classnames"
import classnames from "classnames"
import { useContext, useState } from "react"
import { FunctionComponent } from "react"
import classes from "../../classes/classes"
import ErrorComponent from "../../components/Error"
import AppLayout from "../../components/Layout/AppLayout"
import Selector, { Subpage } from "../../components/Selector"
import { TokenContext } from "../../contexts/TokenContext"
import { UserContext } from "../../contexts/UserContext"
import CreateSharesForm from "./components/createShares"
import DecryptEncryptedMessage from "./components/decryptEncryptedMessage"
import KYCHandler from "./components/kycHandler"
import MyKeys from "./components/myKeys"
import RecoverPrivateKeyForm from "./components/RecoverPrivateKeyForm"
import RecoverSharesForm from "./components/recoverShares"
import ShowEncryptionKey from "./components/showEncryptionKey"
import Payment from "./components/payment"

const Vault: FunctionComponent = () => {
    const { active, library } = useWeb3React()
    const { loggedUser } = useContext(UserContext)
    const { token } = useContext(TokenContext)
    const [myKeys, setMyKeys] = useState([""])
    const [paid, setPaid] = useState(false)

    return !loggedUser?.kyc ? (
        <AppLayout title="Decentralized Vault">
            {" "}
            <KYCHandler />
        </AppLayout>
    ) : (
        <>
            <Selector title="Decentralized Vault">
                <Subpage label="Key submission">
                    <p className={classnames(classes.explanatoryText, "mt-2")}>
                        <strong className="text-red-500 font-extrabold">
                            USE AT YOUR OWN RISK.{" "}
                        </strong>
                    </p>
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-justify"
                        )}
                    >
                        By using this service, you accept to share parts of your
                        private key with{" "}
                        <span className="text-gray-800">Arbitri SARL</span> and{" "}
                        <span className="text-gray-800">
                            Fontanet Schöni Notaires
                        </span>
                        . Arbitri cannot recover your private key by itself. The
                        cooperation of Fontanet Schöni Notaires is necessary.
                        Additionally, if you choose to add a third party,
                        cooperation with this third party is also needed.
                        <br />
                        We use complex cryptographic operations to secure your
                        key and make sure it can only be decrypted under the
                        strict conditions mentioned above. To ensure maximum
                        safety, every cryptographic operations is done on your
                        local machine. No part of the key leaves your computer
                        unencrypted.
                    </p>

                    {(!active ||
                        !library ||
                        library.connection.url !== "metamask") && (
                        <ErrorComponent
                            message="Please connect to metamask on Polygon to use this service"
                            className="mt-5"
                        />
                    )}

                    {active && (
                        <>
                            {!paid && (
                                <Payment
                                    paid={paid}
                                    setPaid={setPaid}
                                ></Payment>
                            )}

                            {paid && (
                                <>
                                    <CreateSharesForm
                                        token={token}
                                        setMyKeys={setMyKeys}
                                    />
                                </>
                            )}
                        </>
                    )}
                </Subpage>

                <Subpage label="Keys submitted">
                    <p className={classnames(classes.explanatoryText, "mt-2")}>
                        Below you can find the addresses of the private keys you
                        submitted to Arbitri.
                    </p>
                    <h2 className={classNames(classes.formH2, "mb-2 mt-4")}>
                        Address
                    </h2>
                    <MyKeys
                        token={token}
                        myKeys={myKeys}
                        setMyKeys={setMyKeys}
                    ></MyKeys>
                </Subpage>

                <Subpage label="Key recovery">
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "mt-2 mb-4"
                        )}
                    >
                        Please fill in this form to ask for a key recovery.
                        Arbitri will contact you and start a key recovery
                        procedure.
                    </p>
                    <RecoverPrivateKeyForm
                        token={token}
                    ></RecoverPrivateKeyForm>
                </Subpage>

                <Subpage label="My encryption key">
                    {!active ||
                    !library ||
                    library.connection.url !== "metamask" ? (
                        <ErrorComponent
                            message="Please connect to metamask on Polygon to use this service"
                            className="mt-5"
                        />
                    ) : (
                        <>
                            <h2
                                className={classNames(
                                    classes.formH2,
                                    "mt-2 mb-2"
                                )}
                            >
                                {" "}
                                My public encryption key
                            </h2>
                            <p
                                className={classnames(
                                    classes.explanatoryText,
                                    "my-2"
                                )}
                            >
                                Below you can find the public encryption key
                                that corresponds to your wallet. Data encrypted
                                using this key can only be decrypted with the
                                private key of the corresponding wallet. Please
                                provide this key in case someone requests you to
                                be a "third party guard" for a private key.
                            </p>

                            <ShowEncryptionKey></ShowEncryptionKey>
                        </>
                    )}
                </Subpage>

                <Subpage label="Key decryption" admin>
                    {!active ||
                    !library ||
                    library.connection.url !== "metamask" ? (
                        <ErrorComponent
                            message="Please connect to metamask on Polygon to use this service"
                            className="mt-5"
                        />
                    ) : (
                        <>
                            <p
                                className={classnames(
                                    classes.explanatoryText,
                                    "mt-2"
                                )}
                            >
                                This section is only relevant for Abitri. It
                                provides Arbitri the tools to recover a client's
                                key. <br></br>It can decrypt an encrypted share
                                provided you have access to the right wallet. It
                                can also recover a key from several shares.
                            </p>

                            <h2
                                className={classnames(
                                    classes.formH2,
                                    "mt-8 mb-2"
                                )}
                            >
                                Recover client's mnemonic from shares
                            </h2>

                            <RecoverSharesForm />

                            <h2
                                className={classNames(
                                    classes.formH2,
                                    "mt-8 mb-2"
                                )}
                            >
                                {" "}
                                Decrypt client's share{" "}
                            </h2>

                            <DecryptEncryptedMessage></DecryptEncryptedMessage>
                        </>
                    )}
                </Subpage>
            </Selector>
        </>
    )
}

export default Vault
