import axios from "axios"
import classnames from "classnames"
import { Form, Formik, FormikConfig, FormikValues } from "formik"
import { default as React, FunctionComponent, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import classes from "../../classes/classes"
import UnderlyingContract from "../../components/Contracts/UnderlyingContract"
import SimpleInput from "../../components/fields/SimpleInput"
import Toggle from "../../components/fields/Toggle"
import UploadComponent, { ListFiles } from "../../components/fields/UploadFiles"
import { FormikStep, FormikStepper } from "../../components/FormikStepper"
import Spinner from "../../components/Spinner"
import UploadedFiles from "../../components/UploadedFiles"
import { config } from "../../config/constants"
import { formatError, sendGet, sendPost } from "../../helpers/submit"
import { CustomContract } from "./CustomContract"

const CustomContractResume: FunctionComponent<{ token: any }> = ({ token }) => {
    const [errorMessage, setErrorMessage] = useState("")
    const [initialized, setInitialized] = useState(false)
    const [clauseData, setClauseData] = useState<CustomContract>() //TODO add the right type
    const [values, setValues] = useState<CustomContract>()
    const [error, setError] = useState("")
    const params = useParams()
    const procedureId = (params as any).procedureId
    const resetErrorState = () => {
        setErrorMessage("")
    }

    const previewPDF = () => {
        // axios
        //     .get(`${config.url.API_URL}/procedure/${procedureId}/preview`, {
        //         params: { token: token },
        //         responseType: "blob",
        //     })
        //     .then((response) => {
        //         //Create a Blob from the PDF Stream
        //         const file = new Blob([response.data], {
        //             type: "application/pdf",
        //         })
        //         //Build a URL from the file
        //         const fileURL = URL.createObjectURL(file)
        //         //Open the URL on new Window
        //         window.open(fileURL)
        //     })
        //     .catch((err) => {
        //         setErrorMessage(err.response.data.error)
        //     })
    }

    const onSuccess = async (message: string, payload: any, status: number) => {
        setClauseData(payload.contractData)
        setValues(payload.contractData)
        setInitialized(true)
    }
    const onError = (message: string, reason: string, status: number) => {
        setError(formatError(message, reason, status))
    }
    const getStored = () => {
        sendGet(
            `${config.url.API_URL}/custom-contract/${procedureId}/resume`,
            token,
            onSuccess,
            onError
        )
    }

    const updateData = async (values: CustomContract) => {
        const valuesCopy = values
        const underlying = valuesCopy.uploadFiles
        const contracts = valuesCopy.uploadFilesContract
        delete valuesCopy.uploadFiles
        delete valuesCopy.uploadFilesContract
        const dataform = new FormData()

        if (underlying !== null && underlying !== undefined) {
            dataform.append("updateType", "underlying")
            for (const contract of underlying as any) {
                dataform.append("files", contract)
            }
        }

        if (contracts !== null && contracts !== undefined) {
            dataform.append("updateType", "contracts")
            for (const contract of contracts as any) {
                dataform.append("files", contract)
            }
        }

        dataform.append("procedureUpdateData", JSON.stringify(valuesCopy))
        return axios
            .put(
                `${config.url.API_URL}/custom-contract/${procedureId}`,
                dataform,
                {
                    params: { token: token },
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then((res) => {})
            .catch((err) => {
                setErrorMessage(err)
            })
    }

    const onSubmit = (values: any) => {
        return
    }

    const validate = () => {
        axios
            .post(
                `${config.url.API_URL}/custom-contract/${procedureId}/validate`,
                {},
                {
                    params: { token: token },
                }
            )
            .then((res) => {
                history.push(`/procedures/${procedureId}/connect`)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        getStored()
    }, [])
    const history = useHistory()
    return (
        <div className="pt-10 md:px-20 w-full">
            <h1 className={classes.pageSubtitle}>Clause builder</h1>
            {initialized ? (
                <>
                    <p className={classnames(classes.explanatoryText, "mb-4")}>
                        You are currently building your clause{" "}
                        <span className="font-bold">
                            {(clauseData as any).name}
                        </span>{" "}
                    </p>
                    <FormikStepper
                        initialValues={clauseData as any}
                        onSubmit={onSubmit}
                        saveState={setValues}
                        serverUpdate={updateData}
                        serverValidate={validate}
                        serverPreview={previewPDF}
                    >
                        <FormikStep label="Relationship subject to Arbitration">
                            <UnderlyingContract
                                url={`${config.url.API_URL}/custom-contract/${procedureId}/underlying-contracts`}
                            />
                        </FormikStep>
                        <FormikStep label="Upload legal Contracts">
                            <SimpleInput
                                name="name"
                                type="text"
                                placeholder="E.g. Sale contract 16.10. with Lucas"
                                label="Name you contract"
                            />
                            <UploadComponent
                                label="Upload your files"
                                name="uploadFilesContract"
                            />
                            <ListFiles name={"uploadFilesContract"} />
                            <UploadedFiles
                                url={`${config.url.API_URL}/custom-contract/${procedureId}/contracts`}
                                title="Uploaded Files"
                            />
                        </FormikStep>
                        <FormikStep label="Review & Confirm">
                            <p className={classes.explanatoryText}>
                                You are about to finalize you clause. After this
                                step you won't be able to modify it. People will
                                immediately be invited to sign the clause.
                            </p>
                            <Toggle
                                name="checkedEverythingCorrect"
                                toggleText="I assert that everything is correct and I want to pursue with my clause creation"
                            />
                        </FormikStep>
                    </FormikStepper>{" "}
                </>
            ) : (
                <Spinner />
            )}
        </div>
    )
}

export default CustomContractResume
