import classNames from "classnames"
import React, { FunctionComponent } from "react"
import { AiFillIdcard } from "react-icons/ai"
import { BsFillPeopleFill } from "react-icons/bs"
import {
    FaBuilding,
    FaDonate,
    FaFileContract,
    FaHandshake,
} from "react-icons/fa"
import { GiMoneyStack } from "react-icons/gi"
import { GoLaw } from "react-icons/go"
import { HiShoppingCart } from "react-icons/hi"
import { RiFileUserFill } from "react-icons/ri"
import classes from "../../classes/classes"
import AppLayout, { PageWithTitle } from "../../components/Layout/AppLayout"
import ContractCard, { ComingSoonCard } from "./components/ContractCard"

const ContractGenerator: FunctionComponent = () => {
    return (
        <PageWithTitle
            title="Contract Generator"
            description={"Select the kind of contract you wish to create"}
        >
            <div className="grid grid-cols-3 gap-4">
                <ContractCard
                    to="/procedures/new"
                    icon={GoLaw}
                    title="Arbitration Clause"
                    description="Set up an arbitration clause"
                    color="bg-red-500"
                />
                <ContractCard
                    to="/custom-contract/new"
                    icon={FaFileContract}
                    title="Upload Contract"
                    description="Upload directly your contract"
                    color="bg-green-500"
                />
                <ComingSoonCard
                    title="Sale Contract"
                    color="bg-blue-300"
                    icon={HiShoppingCart}
                />
                <ComingSoonCard
                    title="License Contract"
                    color="bg-blue-300"
                    icon={AiFillIdcard}
                />
                <ComingSoonCard
                    title="Donation Contract"
                    color="bg-blue-300"
                    icon={FaDonate}
                />
                <ComingSoonCard
                    title="Freelance/Mandate Contract"
                    color="bg-blue-300"
                    icon={RiFileUserFill}
                />
                <ComingSoonCard
                    title="Loan Contract"
                    color="bg-blue-300"
                    icon={GiMoneyStack}
                />
                <ComingSoonCard
                    title="Association"
                    color="bg-gray-300"
                    icon={BsFillPeopleFill}
                />
                <ComingSoonCard
                    title="Partnership"
                    color="bg-gray-300"
                    icon={FaHandshake}
                />
                <ComingSoonCard
                    title="Limited liability company"
                    color="bg-gray-300"
                    icon={FaBuilding}
                />
            </div>
        </PageWithTitle>
    )
}

export default ContractGenerator
