import { useWeb3React } from "@web3-react/core"
import classNames from "classnames"
import React, { FunctionComponent, useState } from "react"
import classes from "../../../classes/classes"

const DecryptEncryptedMessage: FunctionComponent = () => {
    const [encMessage, setEncMessage] = useState("")
    const [decMessage, setDecMessage] = useState("")
    const { library, account } = useWeb3React()

    let decodeEncodedMessage = () => {
        setDecMessage("")
        if (!encMessage) {
            alert("Encoded message is empty ! Please provide encoded message.")
            return
        }
        library.provider
            .request({
                method: "eth_decrypt",
                params: [encMessage,account],
            })
            .then((res: string) => setDecMessage(res))
            .catch((err: Error) => {alert(`Error ! \n ${err.message}`); console.error(err)})
        }
    return (
        <>
        <div className="flex gap-x-1">
            <textarea
                className={classNames(classes.inlineInputs, "w-full h-15")}
                value={encMessage}
                onChange={e => setEncMessage(e.target.value)}
            />
            <button
                className={classNames(classes.mainButton)}
                onClick={decodeEncodedMessage}
            >
                Decrypt
            </button>
        </div>
        <p>{decMessage}</p>
        </>
    )
}

export default DecryptEncryptedMessage
