import { useWeb3React } from "@web3-react/core"
import classNames from "classnames"
import { NavLink } from "react-router-dom"
import classes from "../classes/classes"
import { useFormattedAccount } from "../hooks/useFormattedAccount"
import { injected } from "../web3/connectors"
import Ping from "./Ping"

function Sidebar() {
    const { activate, active  } = useWeb3React()
    const formatAccount = useFormattedAccount()

    const clearToken = () => {
        window.localStorage.clear()
        window.location.reload()
    }

    return (
        <div className="bg-gray-800 w-60 h-screen shadow-lg md:block hidden lg:visible">
            <div className="flex flex-col h-full justify-between">
                <div>
                <div className="pt-6">
                    <div className="w-20 mx-auto mb-10">
                        <a
                            href="
          https://arbitri.ch"
                            target="_blank"
                        >
                            <img
                                className="w-20 self-center"
                                src="/arbitri_logo.png"
                                alt="Arbitri logo"
                            />
                        </a>
                    </div>
                </div>
                <div className="mt-5 text-white text font-bold">
                    <NavLink
                        exact
                        to="/procedures"
                        activeClassName={classNames(classes.sidebar.activeLink)}
                        className={classNames(classes.sidebar.link)}
                    >
                        <span>Dashboard</span>
                    </NavLink>
                    <NavLink
                        exact
                        to="/contacts"
                        activeClassName={classNames(classes.sidebar.activeLink)}
                        className={classNames(classes.sidebar.link)}
                    >
                        <span>Contacts</span>
                    </NavLink>
                    <NavLink
                        exact
                        to="/contract-generator/new"
                        activeClassName={classNames(classes.sidebar.activeLink)}
                        className={classNames(classes.sidebar.link)}
                    >
                        <span>Contracts generator</span>
                    </NavLink>
                    <NavLink
                        exact
                        to="/smart-contract-generator"
                        activeClassName={classNames(classes.sidebar.activeLink)}
                        className={classNames(classes.sidebar.link)}
                    >
                        <span>Smart contract generator</span>
                    </NavLink>
                    <NavLink
                        exact
                        to="/vault"
                        activeClassName={classNames(classes.sidebar.activeLink)}
                        className={classNames(classes.sidebar.link)}
                    >
                        <span>Decentralized Vault</span>
                    </NavLink>

                    <NavLink
                        exact
                        to="/arbitration/select"
                        activeClassName={classNames(classes.sidebar.activeLink)}
                        className={classNames(classes.sidebar.link)}
                    >
                        <span>Request Arbitration</span>
                    </NavLink>
                    <span className={classes.sidebar.disabledLink}>
                        Hash a document
                    </span>
                </div>
                </div>
                <div className="bottom-0 pb-10 text-white px-3 space-y-4 w-60">
                    <div className="space-y-2">
                        <div className="flex items-center">
                            <div className="flex flex-col">
                                {!active ? (
                                    <button
                                        className="text-xs font-bold text-blue-400"
                                        onClick={() => activate(injected)}
                                    >
                                        Connect with Metamask
                                    </button>
                                ) : (
                                    <div className="flex items-center">
                                        <p className="text-xs font-bold">
                                            ETH: {formatAccount}
                                        </p>
                                        <Ping />
                                    </div>
                                )}
                            </div>
                        </div>
                        <button
                            className={classNames(
                                classes.sidebar.button,
                                "bg-white text-black "
                            )}
                            onClick={clearToken}
                        >
                            Log out
                        </button>
                        <NavLink exact to="/questions" className="block">
                            <span
                                className={classNames(
                                    classes.sidebar.button,
                                    "bg-gradient-to-r from-blue-500 to-blue-700"
                                )}
                            >
                                Request Counsels
                            </span>
                        </NavLink>
                    </div>
                    <div className="space-y-1">
                        <a
                            className="text-xs block capitalize"
                            rel="noreferrer"
                            href="https://arbitri.ch/privacy-policy/"
                            target="_blank"
                        >
                            Privacy Policy
                        </a>
                        <a
                            className="text-xs block capitalize"
                            rel="noreferrer"
                            href="https://arbitri.ch/29360-2/"
                            target="_blank"
                        >
                            Terms of service
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
