import classNames from "classnames"
import React, { FunctionComponent } from "react"
import classes from "../../classes/classes"
import BottomNavigation from "../BottomNavigation"
import Sidebar from "../Sidebar"

const AppLayout: FunctionComponent<{
    children: any
    title?: any
    sidebar2?: any
}> = ({ children, title, sidebar2 }) => {
    return (
        <div className="m-0 box-border h-screen w-screen p-0">
            <div className="sticky flex h-full space-x-4">
                <div className="h-screen top-0">
                    <Sidebar />
                    <BottomNavigation />
                </div>
                {sidebar2 && <div className="my-8 w-1/4">{sidebar2}</div>}

            <div className="my-4 w-full overflow-y-scroll pr-10">
                {children}
            </div>
            </div>
        </div>
    )
}
export const PageWithTitle: FunctionComponent<{
    children: React.ReactNode
    title: string
    description?: String
    disabled?: boolean
    disabledText?: string
}> = ({ children, description, title, disabled, disabledText }) => {
    return (
        <AppLayout title="Contract Generator">
            <div className="relative">
                {disabled && (
                    <div className="absolute top-0 left-0 h-full w-full backdrop-blur-xl bg-white bg-opacity-70 flex items-center justify-center">
                        <div className="flex flex-col justify-center items-center space-y-2">
                            <span className="font-bold">{disabledText}</span>
                        </div>
                    </div>
                )}
                <div className="pt-8 md:px-20 w-full min-h-screen mb-5">
                    <h2 className={classes.pageSubtitle}>{title}</h2>
                    <p className={classNames(classes.explanatoryText, "mb-4")}>
                        {description}
                    </p>
                    {children}
                </div>
            </div>
        </AppLayout>
    )
}

export default AppLayout
