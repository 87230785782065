import { Link, Redirect } from "react-router-dom"
import LoginLayout from "../../components/Layout/LoginLayout"
import useSignUpForm from "../../hooks/loginform"
import { config } from "../../config/constants"
import axios from "axios"
import { useContext, useState } from "react"
import classes from "../../classes/classes"
import classnames from "classnames"
import ErrorComponent from "../../components/Error"
import { UserContext } from "../../contexts/UserContext"
import { TokenContext } from "../../contexts/TokenContext"

function Login() {
    const resetErrorState = () => {
        setErrorMessage("")
    }

    const register = () => {
        const registerData = new URLSearchParams()
        registerData.append("email", (inputs as any).email)
        registerData.append("eth", (inputs as any).eth)
        registerData.append("password", (inputs as any).password)
        registerData.append("password2", (inputs as any).password2)
        registerData.append("firstname", (inputs as any).firstname)
        registerData.append("lastname", (inputs as any).lastname)
        registerData.append("ethAddress", (inputs as any).ethAddress)
        axios
            .post(`${config.url.API_URL}/signup`, registerData, {
                headers: {
                    "content-type":
                        "application/x-www-form-urlencoded;charset=utf-8",
                },
            })
            .then((res) => {
                window.location.replace("/")
            })
            .catch((err) => {
                setErrorMessage(err.response.data.error)
            })
    }

    const { inputs, handleInputChange, handleSubmit } = useSignUpForm(register)
    const [errorMessage, setErrorMessage] = useState("")
    return (
        <LoginLayout title="Register">
            <form onSubmit={handleSubmit} className="">
                <div className="my-5 text-sm">
                    <label htmlFor="username" className="block text-black">
                        First Name
                    </label>
                    <input
                        type="text"
                        autoFocus
                        onChange={handleInputChange}
                        id="firstname"
                        name="firstname"
                        className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full"
                        placeholder="First Name"
                    />
                </div>
                <div className="my-5 text-sm">
                    <label htmlFor="username" className="block text-black">
                        Last Name
                    </label>
                    <input
                        type="text"
                        autoFocus
                        onChange={handleInputChange}
                        id="lastname"
                        name="lastname"
                        className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full"
                        placeholder="Last Name"
                    />
                </div>
                <div className="my-5 text-sm">
                    <label htmlFor="username" className="block text-black">
                        Email
                    </label>
                    <input
                        type="email"
                        autoFocus
                        onChange={handleInputChange}
                        id="email"
                        name="email"
                        className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full"
                        placeholder="Email"
                    />
                </div>
                <div className="my-5 text-sm">
                    <label htmlFor="password" className="block text-black">
                        Password
                    </label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        onChange={handleInputChange}
                        className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full"
                        placeholder="Password"
                    />
                </div>
                <div className="my-5 text-sm">
                    <label htmlFor="password" className="block text-black">
                        Confirm Password
                    </label>
                    <input
                        type="password"
                        id="password2"
                        name="password2"
                        onChange={handleInputChange}
                        className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full"
                        placeholder="Confirm Password"
                    />
                </div>
                <div className="my-5 text-sm">
                    <label htmlFor="password" className="block text-black">
                        Ethereum address
                    </label>
                    <input
                        type="string"
                        id="ethAddress"
                        name="ethAddress"
                        onChange={handleInputChange}
                        className="rounded-sm px-4 py-3 mt-3 focus:outline-none bg-gray-100 w-full"
                        placeholder="0x..."
                    />
                </div>
                <p className="text-gray-400 text-sm">
                    By proceeding, I agree to{" "}
                    <a href="" className="font-bold text-black">
                        Arbitri’s Terms of Use
                    </a>{" "}
                    and acknowledge that I have read the{" "}
                    <a href="" className="font-bold text-black">
                        Privacy Policy
                    </a>
                    .
                </p>
                <button
                    type="submit"
                    className={classnames(classes.neutralButton, "mt-4")}
                >
                    Register
                </button>
                <div className="mt-3">
                    <Link to="/" className="text-gray-400 text-sm">
                        Already have an account?{" "}
                        <span className="font-bold text-black">Sign In</span>
                    </Link>
                </div>
            </form>
            {errorMessage &&
            <ErrorComponent
                message={errorMessage}
                resetErrorState={resetErrorState}
                className="mt-4"
            />
        }
        </LoginLayout>
    )
}

export default Login
