import axios from "axios"
import classNames from "classnames"
import { FunctionComponent, useContext, useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import classes from "../../classes/classes"
import AppLayout from "../../components/Layout/AppLayout"
import { config } from "../../config/constants"
import { TokenContext } from "../../contexts/TokenContext"
import { dateHelper } from "../../helpers/formatDate"
import { useGetDetailsForAllProcedures } from "../../hooks/usGetProcedures"

const SelectClause: FunctionComponent = () => {
    const [dataState, setData] = useState([])
    const details = useGetDetailsForAllProcedures()
    const history = useHistory()
    const {token} = useContext(TokenContext)

    const onRowClickHandler = (e: any) => {
        const id = e.currentTarget.getAttribute("data-item")
        history.push(`/procedures/${id}/arbitrages/new`)
    }
    const renderTable = (data: any, details: any) => {
        return (
            <>
                {data.length !== 0 ? (
                    <table
                        id="Procedures"
                        className="rounded-t-lg w-full bg-gray-700 text-gray-200"
                    >
                        <tr className="text-left">
                            <th className="px-4 py-3">Name</th>
                            <th className="px-4 py-3">Creation Date</th>
                            <th className="px-4 py-3">Status</th>
                            <th className="px-4 py-3">Arbitration Status</th>
                            <th className="px-4 py-3">Binding contract(s)</th>
                        </tr>

                        <tbody className="text-gray-600 text-sm border rounded divide-y divide-gray-300">
                            {(data as any).map(
                                (procedure: any, idx: number) => {
                                    const {
                                        name,
                                        lastModified,
                                        creationDate,
                                        _id,
                                        validated = false,
                                    } = procedure
                                    return (
                                        <tr
                                            data-item={_id}
                                            className="bg-white border-b divide-gray-300 hover:bg-gray-100 cursor-pointer"
                                            onClick={onRowClickHandler}
                                            key={`tr-${idx}`}
                                        >
                                            <td className="p-4 whitespace-nowrap font-bold">
                                                {name}
                                            </td>
                                            <td className="p-4 whitespace-nowrap">
                                                {dateHelper(
                                                    new Date(creationDate)
                                                )}
                                            </td>
                                            <td className="p-4 ">
                                                {validated ? (
                                                    <span className="relative text-xs inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                                        <span className="absolute inset-0 bg-green-200 opacity-50 rounded-full"></span>
                                                        <span className="relative">
                                                            Validated
                                                        </span>
                                                    </span>
                                                ) : (
                                                    <span className="relative inline-block  text-xs px-3 py-1 font-semibold text-yellow-900 leading-tight">
                                                        <span className="absolute inset-0 bg-yellow-200 opacity-50 rounded-full"></span>
                                                        <span className="relative">
                                                            Building
                                                        </span>
                                                    </span>
                                                )}
                                            </td>
                                            <td className="p-4 whitespace-nowrap">
                                                {details[idx].arbitrage !==
                                                null ? (
                                                    <span className="relative text-xs inline-block px-3 py-1 font-semibold text-yellow-900 leading-tight">
                                                        <span className="absolute inset-0 bg-yellow-200 opacity-50 rounded-full"></span>
                                                        <span className="relative">
                                                            Pending
                                                        </span>
                                                    </span>
                                                ) : (
                                                    <span className="relative text-xs inline-block px-3 py-1 font-semibold text-gray-900 leading-tight">
                                                        <span className="absolute inset-0 bg-gray-200 opacity-50 rounded-full"></span>
                                                        <span className="relative">
                                                            No Arbitrage
                                                        </span>
                                                    </span>
                                                )}
                                            </td>
                                            <Link
                                                to={`/contract/${details[idx].clause}`}
                                                className="text-blue-500 underline"
                                            >
                                                <td className="p-4 whitespace-nowrap z-40">
                                                    {`${
                                                        details[idx].bindings
                                                            .length
                                                    } contract${
                                                        details[idx].bindings
                                                            .length > 1
                                                            ? "s"
                                                            : ""
                                                    } bind`}
                                                </td>
                                            </Link>
                                        </tr>
                                    )
                                }
                            )}
                        </tbody>
                    </table>
                ) : (
                    <p>No Procedures</p>
                )}
            </>
        )
    }

    const renderValidatedTable = (data: any[], details: any) => {
        return renderTable(
            data.filter(
                (procedure: any) =>
                    procedure.validated && procedure.procedureData.signed
            ),
            details.filter((details: any) =>
                data
                    .filter(
                        (procedure: any) =>
                            procedure.validated &&
                            procedure.procedureData.signed
                    )
                    .map((clause) => clause._id)
                    .includes(details.clause)
            )
        )
    }

    useEffect(() => {
        axios
            .get(`${config.url.API_URL}/procedure`, {
                params: { token: token },
            })
            .then((res) => {
                const procedures = res.data.procedures
                if (dataState !== procedures) {
                    setData(procedures)
                }
            })
    }, [])

    return (
        <AppLayout title="Select the concerned clause">
            <div className="mx-auto py-8 md:px-20 flex-grow h-full">
                <h2 className={classes.pageSubtitle}>
                    {" "}
                    Request an arbitration
                </h2>
                <p className={classNames(classes.explanatoryText, "mb-4")}>
                    Please select the arbitration clause you wish to trigger
                </p>
                {details && dataState && (
                    <div className="w-2/3">
                        {renderValidatedTable(dataState, details)}
                    </div>
                )}
            </div>
        </AppLayout>
    )
}

export default SelectClause
