import classnames from "classnames"
import { FunctionComponent } from "react"
import { Link } from "react-router-dom"
import classes from "../classes/classes"

export const DetailsElement: FunctionComponent<{label:string, value?:string, href?:string, className?:string}> = ({label, value, href, className}) => {
    return (
        <>
            <span className={classnames(classes.labelNoInput, "my-1 block")}>
                {label}
            </span>
            {value&& href ? <Link to={href}><span className={classnames("text-blue-500 underline", className? className: "")}>{value}</span></Link>:<span className={classnames("font-bold", className? className: "")}>{value}</span>}
        </>
    )
}
