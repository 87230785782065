import React, { FunctionComponent, useContext, useEffect } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import ProtectedRoute from "../components/ProtectedRoute"
import TokenContextProvider from "../contexts/TokenContext"
import UserContextProvider from "../contexts/UserContext"
import "../styles/App.css"
import ArbitrageItem from "./Arbitration"
import NewArbitrage from "./Arbitration/New"
import SelectClause from "./Arbitration/SelectClause"
import ProcedureItem from "./ArbitrationClause"
import NewProcedure from "./ArbitrationClause/New"
import NewBinding from "./Binding"
import Procedures from "./Clauses"
import Contacts from "./Contacts"
import ContractGenerator from "./ContractGenerator"
import ContractItem from "./Contracts"
import CustomContractItem from "./CustomContract"
import OpenedCustomContract from "./CustomContract/Details"
import NewCustomContract from "./CustomContract/New"
import CustomContractResume from "./CustomContract/Resume"
import Login from "./Login"
import NotFound from "./NotFound"
import ProcedureConnect from "./ProcedureConnect"
import Questions from "./Questions"
import Register from "./Register"
import Reset from "./Reset"
import SmartContractGenerator from "./SmartContractGenerator"
import OpenSmartContract from "./SmartContractGenerator/OpenSmartContract"
import SmartContractItem from "./SmartContractGenerator/SmartContractItem"
import Vault from "./Vault"
import { useWeb3React } from "@web3-react/core"
import { injected } from "../web3/connectors"

function App() {
    const { activate, active } = useWeb3React()

    useEffect(() => {
        activate(injected)
    },[])
    return (
        <TokenContextProvider>
            <UserContextProvider>
                <Router>
                    <Switch>
                        {/* PROTECTED ROUTES */}
                        <ProtectedRoute
                            exact
                            path="/"
                            component={() => <Procedures />}
                        />
                        <ProtectedRoute
                            exact
                            path="/procedures"
                            component={() => <Procedures />}
                        />
                        <ProtectedRoute
                            exact
                            path="/procedures/new"
                            component={() => <NewProcedure />}
                        />
                        <ProtectedRoute
                            exact
                            path="/binding/new/:contract0Id?"
                            component={() => <NewBinding />}
                        />
                        <ProtectedRoute
                            exact
                            path="/custom-contract/new"
                            component={() => <NewCustomContract />}
                        />
                        <ProtectedRoute
                            exact
                            path="/custom-contract/:procedureId"
                            component={() => <CustomContractItem />}
                        />
                        <ProtectedRoute
                            exact
                            path="/smart-contract/:procedureId"
                            component={() => <OpenSmartContract />}
                        />
                        <ProtectedRoute
                            exact
                            path="/contract/:uuid"
                            component={() => <ContractItem />}
                        />
                        <ProtectedRoute
                            exact
                            path="/smart-contract-generator"
                            component={() => <SmartContractGenerator />}
                        />
                        <ProtectedRoute
                            exact
                            path="/questions"
                            component={() => <Questions />}
                        />
                        <ProtectedRoute
                            exact
                            path="/contract-generator/new"
                            component={() => <ContractGenerator />}
                        />
                        <ProtectedRoute
                            exact
                            path="/contacts"
                            component={() => <Contacts />}
                        />

                        <ProtectedRoute
                            exact
                            path="/procedures/:procedureId/connect"
                            component={() => <ProcedureConnect />}
                        />

                        <ProtectedRoute
                            exact
                            path="/arbitration/select"
                            component={() => <SelectClause />}
                        />

                        <ProtectedRoute
                            exact
                            path="/procedures/:procedureId/arbitrages/new"
                            component={() => <NewArbitrage />}
                        />

                        <ProtectedRoute
                            exact
                            path="/procedures/:procedureId/arbitrages/:arbitrageId"
                            component={() => <ArbitrageItem />}
                        />

                        <ProtectedRoute
                            exact
                            path="/procedures/:procedureId"
                            component={() => <ProcedureItem />}
                        />

                        <ProtectedRoute
                            exact
                            path="/vault"
                            component={() => <Vault />}
                            />

                      {/* PUBLIC ROUTES */}
                        <Route
                            path="/login"
                            component={Login}
                        />
                        <Route
                            path="/register"
                            component={Register}
                        />
                        <Route 
                            path="/reset"
                            component={Reset}
                        />
                        <Route component={() => <NotFound />} /> 
                    </Switch>
                </Router>
            </UserContextProvider>
        </TokenContextProvider>
    )
}

export default App
