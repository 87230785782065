import { IoCloseCircle } from "react-icons/io5"
import classnames from "classnames"
import { FunctionComponent } from "react"

const ErrorComponent: FunctionComponent<{
    message: string
    resetErrorState?: any
    className?: string
}> = ({ message, resetErrorState, className }) => {
    return (
        <div className={classnames("error", className)}>
            <div className="bg-red-50 text-red-800 rounded-lg p-3">
                <div className="flex items-center">
                    <button onClick={resetErrorState}>
                        <IoCloseCircle size={"1.25rem"} />
                    </button>
                    <span className="ml-2 font-bold">An error has occured</span>
                </div>
                <div className="flex items-center">
                    <div className="text-sm text-red-500 mt-1">{message}</div>
                </div>
            </div>
        </div>
    )
}

export default ErrorComponent
