import classnames from "classnames"
import { Field, useFormikContext } from "formik"
import React, { FunctionComponent, useEffect } from "react"
import { BsInfoSquareFill } from "react-icons/bs"
import classes from "../../../classes/classes"
import Radio from "../../../components/fields/Radio"
import Select from "../../../components/fields/Select"
import SimpleInput from "../../../components/fields/SimpleInput"
import TextArea from "../../../components/fields/TextArea"
import Toggle from "../../../components/fields/Toggle"
import useFormNoSubmit from "../../../hooks/useForm"

const FreeClassic: FunctionComponent = () => {
    const { values, submitForm } = useFormikContext()

    return (
        <>
            <TextArea
                name="legalData.classicData.freeClassicData.clause"
                placeholder="Your clause"
                label="Write your clause"
            />
            <h3 className={classnames(classes.label, "block mb-2")}>
                Forum & law applicable by default
            </h3>
            <Toggle
                name="legalData.classicData.freeClassicData.jurisdiction"
                toggleText={`The place of jurisdiction and applicable law of the underlying relationship is usually determined by the underlying agreement between the Parties.
In the absence of a choice of law, or in the event that the Arbitrators are unable to determine the forum or the law applicable to the underlying relationship, the forum shall be deemed to be in Geneva and Swiss law shall be deemed applicable.
In particular, in the absence of a choice of law, securities for which it is not possible to determine the law or the forum are deemed to be issued in Switzerland and subject to Swiss law.
`}
            />
            <h3 className={classnames(classes.label, "block mb-2")}>
                Form of future modifications to the clause
            </h3>
            <Toggle
                name="legalData.classicData.freeClassicData.changes"
                toggleText={`The parties agree that any subsequent changes to the content of the arbitration agreement (amendment or revocation) shall be subject to a binding contractual form (Art. 16 OR by analogy). This reserved conventional form does not affect the underlying relationship between the parties. 
This provision does not contravene the fact that legal relations are freely transferable between the Parties and a Third Party. 
Changes (content, parts, etc.) must be made and announced through the Arbitri dashboard.
`}
            />
        </>
    )
}

export default FreeClassic
