import classnames from "classnames"
import { FunctionComponent, useContext, useEffect, useState } from "react"
import { FaFilePdf } from "react-icons/fa"
import classes from "../classes/classes"
import { TokenContext } from "../contexts/TokenContext"
import { formatError, handleDownload, sendGet } from "../helpers/submit"

const UploadedFiles: FunctionComponent<{ url: string, title?:string }> = ({ url, title }) => {
    const [error, setError] = useState("")
    const [files, setFiles] = useState([])
    const { token } = useContext(TokenContext)

    const onSuccess = async (message: string, payload: any, status: number) => {
        setFiles(payload)
    }

    const onError = (message: string, reason: string, status: number) => {
        setError(formatError(message, reason, status))
    }
    useEffect(() => {
        sendGet(url, token, onSuccess, onError)
    }, [])

    return files ? (
        <div className="flex flex-col space-y-1">
            <label className={classnames(classes.label)}>
                {title}
            </label>
            
            {files.length > 0 ? files.map((file) => (
                <DownloadableFile url={`${url}/download`} fileName={file} key={`file-${file}`}/>
            )): <span>No contract uploaded </span> }
        </div>
    ) : <></>
}

const DownloadableFile: FunctionComponent<{ url: string; fileName: string }> =
    ({ url, fileName }) => {
        const { token } = useContext(TokenContext)
        const downloadPDF = (e:any) => {
            e.preventDefault()
            handleDownload(url, token, fileName)
        }

        return (
            <button onClick={e => downloadPDF(e)}>
                <div className="flex items-end cursor-pointer">
                    <FaFilePdf className="text-red-500" size="1.25rem" />
                    <span className="ml-1 inline-block text-gray-800 text-xs">
                        {fileName}
                    </span>
                </div>
            </button>
        )
    }
export default UploadedFiles
