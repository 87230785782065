import classnames from "classnames"
import { Field, useFormikContext } from "formik"
import React, { FunctionComponent, useEffect } from "react"
import { BsInfoSquareFill } from "react-icons/bs"
import classes from "../../../classes/classes"
import Radio from "../../../components/fields/Radio"
import Select from "../../../components/fields/Select"
import SimpleInput from "../../../components/fields/SimpleInput"
import TextArea from "../../../components/fields/TextArea"
import Toggle from "../../../components/fields/Toggle"
import useFormNoSubmit from "../../../hooks/useForm"

const ClassicSCAI: FunctionComponent = () => {
    const { values, submitForm } = useFormikContext()
    const descriptions = [
        'They shall be freely appointed in accordance with the following procedure ("other services" section) by the Nomination and Proposals Committee within 14 working days upon the express request of the Parties. The Arbitrators shall be appointed freely. This appointment is binding between the Parties. The Arbitrators must in particular have the following qualities, a quality which Arbitri is entirely free to judge.',
        'They shall be freely proposed according to the procedure below ("other services" section) by the Nomination and Proposals Committee of Arbitration within 14 working days from the express request of the Parties. The Arbitrators shall be appointed freely. They must in particular have the following qualities, a quality which Arbitri is entirely free to judge.',
        "Appointing the Arbitri expert arbitrators from the drop-down list",
    ]
    return (
        <>
            <Toggle
                name="legalData.classicData.classicSCAIData.placeOfJurisdiction"
                toggleText="In the event that the Arbitrators are unable to determine the place of jurisdiction or the law applicable to the basic underlying relationship, the place of jurisdiction shall be deemed to be Geneva and Swiss law shall be deemed to apply.
In particular, securities for which it is not possible to determine the law or jurisdiction are deemed to be issued in Switzerland and subject to Swiss law
"
                label="Procedural seat of the Court by default"
            />
            <SimpleInput
                name="legalData.classicData.classicSCAIData.seat"
                label="The seat of the arbitration shall be"
                placeholder="name of a city in Switzerland"
                type="text"
            />
            <Select
                name="legalData.classicData.classicSCAIData.language"
                options={["English", "French", "German", "Other"]}
                label="Language"
            />
            {(values as any).legalData.classicData.classicSCAIData.language ==
                "Other" && (
                <SimpleInput
                    name="legalData.classicData.classicSCAIData.otherLanguage"
                    type="text"
                    placeholder="Language"
                    label="Other language"
                />
            )}
            <div className="space-y-2 mb-5">
                <label
                    htmlFor={"legalData.classicData.classicSCAIData.procedure"}
                    className={classnames(classes.label)}
                >
                    {"Arbitration Procedural rules"}
                </label>
                <label className="block flex items-center">
                    <Field
                        type="radio"
                        name="legalData.classicData.classicSCAIData.procedure"
                        value="0"
                    />
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-sm ml-4"
                        )}
                    >
                        {" "}
                        The procedural rules of Swiss law on international
                        arbitration (art. 176 LDIP), excluding the rules of
                        domestic arbitration (art. 353 CPC).
                    </p>
                </label>
                <label className="block flex items-center">
                    <Field
                        type="radio"
                        name="legalData.classicData.classicSCAIData.procedure"
                        value="1"
                    />
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-sm ml-4"
                        )}
                    >
                        {" "}
                        Domestic arbitration rules (art. 353 CPC) excluding art.
                        176 LDIP
                    </p>
                </label>
                <label className="block flex items-center">
                    <Field
                        type="radio"
                        name="legalData.classicData.classicSCAIData.procedure"
                        value="2"
                    />
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-sm ml-4"
                        )}
                    >
                        {" "}
                        The procedural rules of Swiss law on international
                        arbitration (art. 176 LDIP) excluding the rules of
                        domestic arbitration (art. 353 CPC). However, in case of
                        domestic arbitration, the domestic arbitration rules
                        (art. 353 CPC) apply exclusively.
                    </p>
                </label>
                <label className="block flex items-center">
                    <Field
                        type="radio"
                        name="legalData.classicData.classicSCAIData.procedure"
                        value="3"
                    />
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-sm ml-4"
                        )}
                    >
                        {" "}
                        The procedural rules of Swiss law on international
                        arbitration (Art. 176 LDIP), including the rules of
                        domestic arbitration (Art. 353 CPC) for the procedure.
                        In the case of domestic arbitration, the domestic
                        arbitration rules (Art. 353 CPC) shall apply
                        exclusively.
                    </p>
                </label>
            </div>
            {
                <Radio
                    label="Rule for appointment"
                    name="legalData.classicData.classicSCAIData.appointmentMethod"
                    options={["0", "1"]}
                    optionsTexts={[
                        "SCAI appointment",
                        "Designation of SCAI Arbitrator(s)",
                    ]}
                />
            }
            {(values as any).legalData.classicData.classicSCAIData
                .appointmentMethod === "0" ? (
                <>
                    <div className="space-y-2 mb-5">
                        <p className={classes.explanatoryText}>
                            The appointements rules of the SCAI apply by default
                        </p>
                        <Toggle
                            name="legalData.classicData.classicSCAIData.appointmentRule"
                            toggleText="The rule for appointment in multi-party proceedings is as follows: "
                        />
                        {(values as any).legalData.classicData.classicSCAIData
                            .appointmentRule && (
                            <div className="ml-8">
                                <Select
                                    label="Rule for appointment"
                                    name="legalData.classicData.classicSCAIData.appointmentData.rule"
                                    options={["0", "1"]}
                                    optionsTexts={[
                                        "Each party shall appoint one arbitrator",
                                        "Else:",
                                    ]}
                                />
                                {(values as any).legalData.classicData
                                    .classicSCAIData.appointmentData?.rule ===
                                    "1" && (
                                    <SimpleInput
                                        name="legalData.classicData.classicSCAIData.appointmentData.writtenRule"
                                        type="text"
                                        label="Appointment rule"
                                        placeholder="rule"
                                    />
                                )}
                            </div>
                        )}
                        <Toggle
                            name="legalData.classicData.classicSCAIData.appointmentTimeLimit"
                            toggleText="The deadline for the appointment of an arbitrator will be 15 days. If circumstances warrant, the Court may modify these time limits"
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="space-y-2 mb-5">
                        <label
                            htmlFor={
                                "legalData.classicData.classicSCAIData.designation"
                            }
                            className={classnames(classes.label)}
                        >
                            {"Arbitrator(s) Designation"}
                        </label>
                        <label className="block flex items-center">
                            <Field
                                type="radio"
                                name="legalData.classicData.classicSCAIData.designation"
                                value="SCAI"
                            />
                            <p
                                className={classnames(
                                    classes.explanatoryText,
                                    "text-sm ml-4"
                                )}
                            >
                                {" "}
                                The following SCAI arbitrators
                            </p>
                        </label>
                        {(values as any).legalData.classicData.classicSCAIData
                            .designation == "SCAI" && (
                            <div className="ml-8">
                                <span className={classnames("block my-1 mb-4")}>
                                    Visit{" "}
                                    <a
                                        href="https://www.swissarbitration.org/Arbitration/Find-Arbitrator-Counsel"
                                        className={classnames(
                                            "font-bold text-blue-500 underline"
                                        )}
                                    >
                                        www.swissarbitration.org
                                    </a>{" "}
                                    to find arbitrators.
                                </span>
                                <SimpleInput
                                    name="legalData.classicData.classicSCAIData.SCAIData.SCAIArbitrators"
                                    label="SCAI Arbitrators"
                                    placeholder="SCAI Arbitrators"
                                    type="text"
                                />
                            </div>
                        )}
                        <label className="block flex items-center">
                            <Field
                                type="radio"
                                name="legalData.classicData.classicSCAIData.designation"
                                value="freelyAppointed"
                            />
                            <p
                                className={classnames(
                                    classes.explanatoryText,
                                    "text-sm ml-4"
                                )}
                            >
                                They shall be freely appointed in accordance
                                with the following procedure ("other services"
                                section) by the Nominating and Proposing
                                Committee of Arbitri within 14 working days upon
                                the express request of the Parties. The
                                Arbitrators shall be appointed freely. Such
                                appointment shall be binding between the
                                Parties. The Arbitrators shall in particular
                                have the following qualities, a quality of which
                                Arbitri is entirely free to judge.
                            </p>
                        </label>
                        <label className="block flex items-center">
                            <Field
                                type="radio"
                                name="legalData.classicData.classicSCAIData.designation"
                                value="freelyProposed"
                            />
                            <p
                                className={classnames(
                                    classes.explanatoryText,
                                    "text-sm ml-4"
                                )}
                            >
                                They shall be freely proposed in accordance with
                                the following procedure ("other services"
                                section) by the Nominating and Proposing
                                Committee of Arbitri within 14 working days upon
                                the express request of the Parties. The
                                Arbitrators shall be appointed freely. In
                                particular, they shall have the following
                                qualities, which Arbitri shall be entirely free
                                to judge.
                            </p>
                        </label>
                        {(values as any).legalData.classicData.classicSCAIData
                            .designation == "freelyProposed" && (
                            <div className="ml-8">
                                <SimpleInput
                                    name="legalData.classicData.classicSCAIData.expertQualification"
                                    type="text"
                                    placeholder="Qualifications"
                                    label="Arbitrator(s) qualifications (technical knowledge, etc)"
                                />
                            </div>
                        )}
                        <label className="block flex items-center">
                            <Field
                                type="radio"
                                name="legalData.classicData.classicSCAIData.designation"
                                value="appointed"
                            />
                            <p
                                className={classnames(
                                    classes.explanatoryText,
                                    "text-sm ml-4"
                                )}
                            >
                                {" "}
                                They are appointed{" "}
                                <Field
                                    className={classnames(classes.inlineInputs)}
                                    placeholder="designation method"
                                    type="text"
                                    name="legalData.classicData.classicSCAIData.appointedData.method"
                                />{" "}
                                by{" "}
                                <Field
                                    className={classnames(classes.inlineInputs)}
                                    placeholder="insert name"
                                    type="text"
                                    name="legalData.classicData.classicSCAIData.appointedData.referrer"
                                />
                                .
                            </p>
                        </label>
                        {(values as any).legalData.classicData.classicSCAIData
                            .designation == "appointed" && (
                            <div className="ml-8">
                                <Toggle
                                    className="text-xs h-3 w-3"
                                    name="legalData.classicData.classicSCAIData.appointedData.designationDelay"
                                    toggleText="They shall be designated within 14 working days upon the express request of the Parties."
                                />
                                <Toggle
                                    className="text-xs h-3 w-3"
                                    name="legalData.classicData.classicSCAIData.appointedData.freely"
                                    toggleText="The Arbitrators shall be appointed freely. Such appointment shall be binding on the Parties. The Arbitrators shall, in particular, have the following qualities, which the person appointed shall be entirely free to judge."
                                />
                                <SimpleInput
                                    name="legalData.classicData.classicSCAIData.expertQualification"
                                    type="text"
                                    placeholder="Qualifications"
                                    label="Arbitrator(s) qualifications (technical knowledge, etc)"
                                />
                            </div>
                        )}
                        <label className="block flex items-center">
                            <Field
                                type="radio"
                                name="legalData.classicData.classicSCAIData.designation"
                                value="proposed"
                            />
                            <p
                                className={classnames(
                                    classes.explanatoryText,
                                    "text-sm ml-4"
                                )}
                            >
                                {" "}
                                They are proposed{" "}
                                <Field
                                    className={classnames(classes.inlineInputs)}
                                    placeholder="designation method"
                                    type="text"
                                    name="legalData.classicData.classicSCAIData.proposedData.method"
                                />{" "}
                                by{" "}
                                <Field
                                    className={classnames(classes.inlineInputs)}
                                    placeholder="insert name"
                                    type="text"
                                    name="legalData.classicData.classicSCAIData.proposedData.referrer"
                                />{" "}
                                They shall be proposed within 14 working days
                                upon the express request of the Parties.
                            </p>
                        </label>
                        {(values as any).legalData.classicData.classicSCAIData
                            .designation == "proposed" && (
                            <div className="ml-8">
                                <SimpleInput
                                    name="legalData.classicData.classicSCAIData.expertQualification"
                                    type="text"
                                    placeholder="Qualifications"
                                    label="Arbitrator(s) qualifications (technical knowledge, etc)"
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
            <Toggle
                name="legalData.classicData.classicSCAIData.replacement"
                label="Removal & Replacement of Arbitrator(s)"
                toggleText="By agreement between the Parties, they may decide on the removal or replacement of an Arbitrator"
            />
            <Toggle
                name="legalData.classicData.classicSCAIData.priorConciliation"
                label="Conciliation"
                toggleText="The parties wish the Arbitrator, before deciding the case, to make every effort to achieve conciliation between them. If necessary, and if necessary, he shall record the result of this conciliation. Only in the absence of a successful conciliation shall the Arbitrator decide the dispute"
            />
            <Toggle
                name="legalData.classicData.classicSCAIData.mediation"
                label="Mediation"
                toggleText="The parties may also decide at any time to submit their dispute to mediation in accordance with the Swiss Rules of Commercial Mediation of the Swiss Chambers' Arbitration Institution."
            />
            <Radio
                label="Time limit for resolution"
                name="legalData.classicData.classicSCAIData.resolutionTime"
                options={["0", "1"]}
                optionsTexts={[
                    "The accelerated procedure applies. The time limit for resolution shall be a maximum of 6 months from the transmission of the file to the arbitral tribunal",
                    "The accelerated procedure does not apply unless the conditions of Art. 42 para.2 SCAI are met (Claims < 1 million / sole arbitrator)",
                ]}
            />
            <Radio
                label="In the case of an accelerated procedure, the dispute shall be decided"
                name="legalData.classicData.classicSCAIData.evidences"
                options={["0", "1"]}
                optionsTexts={[
                    "In a single hearing",
                    "On the basis of documents only",
                ]}
            />

            <Radio
                label="Law applicable to the Award"
                name="legalData.classicData.classicSCAIData.law"
                options={["0", "1"]}
                optionsTexts={[
                    "The Arbitral Tribunal shall decide in accordance with the rules of law agreed upon by the parties. Thereafter, it shall apply the default rules provided for in this Chapter if they are chosen. Finally, it may, in the absence of a choice by the Parties and in the absence of a choice of law, apply the rules of law with which the dispute is most closely connected. In addition, the Tribunal has the possibility to decide ex æquo et bono.",
                    "The Tribunal shall decide ex æquo et bono",
                ]}
            />
            <Radio
                label="Motivation of the Award"
                name="legalData.classicData.classicSCAIData.reasons"
                options={["0", "1"]}
                optionsTexts={[
                    "must be motivated, or at least briefly motivated",
                    "does not need to be motivated",
                ]}
            />
            <Radio
                label="Waiver of rescourse"
                name="legalData.classicData.classicSCAIData.waiver"
                options={["0", "1", "2"]}
                optionsTexts={[
                    "Parties to waive recourse on incidental decisions",
                    `The Parties waive recourse to appeal against incidental decisions except : 
a) where the sole arbitrator has been irregularly appointed or the arbitral tribunal irregularly composed;
b) where the arbitral tribunal has wrongly declared itself competent or incompetent
`,
                    "Freely written",
                ]}
            />
            {(values as any).legalData.classicData.classicSCAIData.waiver ==
                "2" && (
                <div className="ml-8">
                    <SimpleInput
                        name="legalData.classicData.classicSCAIData.freeWaiver"
                        type="text"
                        placeholder="Waiver of rescourse"
                        label="Freely written waiver of rescourse"
                    />
                </div>
            )}
            <Radio
                label="In the event that both parties have no domicile, habitual residence or establishment in Switzerland"
                name="legalData.classicData.classicSCAIData.noResidence"
                options={["0", "1"]}
                optionsTexts={[
                    "They declare that they exclude any recourse against the final award of the arbitral tribunal",
                    `They exclude appeal except on the following grounds (art. 190, para. 2 LDIP) :
 Either: a. where the sole arbitrator has been improperly appointed or the arbitral tribunal has been improperly composed ;
 At the arbitral tribunal's discretion: b. where the arbitral tribunal has wrongly declared itself competent or incompetent;
 Either: c. where the arbitral tribunal has ruled beyond the claims before it or where it has failed to rule on one of the grounds of the claim;
 Either: d. when the equality of the parties or their right to be heard in the adversarial procedure has not been respected;
 Either: e. where the award is incompatible with public policy]
`,
                ]}
            />
        </>
    )
}

export default ClassicSCAI
