import axios, { AxiosResponse } from "axios"

type ErrorCallback = (message: string, reason: string, status: number) => void
type SuccessCallback<T> = (message: string, payload: T, status: number) => void


//Export an axios instance with Auth Token
export const APIClient = (token: string) => {
    // axios instance for making requests
    axios.defaults.params = {};
    const axiosInstance = axios.create();
    // request interceptor for adding token
    axiosInstance.interceptors.request.use((config) => {
      // add token to request header
      config.params['token'] = token;
      return config;
    });
  
    return axiosInstance;
  };


export async function sendPost<T>(
    body: any,
    url: string,
    token: any,
    successCallback: SuccessCallback<T>,
    errorCallback: ErrorCallback
) {
    const dataform = new URLSearchParams()
    for (const e in body) {
        dataform.append(e, JSON.stringify(body[e]))
    }
    await axios
        .post(url, dataform, {
            params: { token: token },
            headers: {
                "content-type":
                    "application/x-www-form-urlencoded;charset=utf-8",
            },
        })
        .then((res) => {
            const { message, payload, status } = handleResponse(res)
            successCallback(message, payload, status)
        })
        .catch((err) => {
            const { data, status } = err.response
            const { message, reason } = data || {}
            errorCallback(message, reason, status)
        })
}

export async function sendGet<T>(
    url: string,
    token: any,
    successCallback: SuccessCallback<T>,
    errorCallback: ErrorCallback
) {
    axios
        .get(url, {
            params: { token: token },
        })
        .then((res) => {
            const { message, payload, status } = handleResponse(res)
            successCallback(message, payload, status)
        })
        .catch((err) => {
            const { data, status } = err.response || {}
            const { message, reason } = data || {}
            errorCallback(message, reason, status)
        })
}
/**
 *
 * @param url
 * @param token
 * @param fileName
 */
export const handleDownload = (url: string, token: any, fileName: string) => {
    const dataform = new URLSearchParams()
    dataform.append("fileName", fileName)
    axios
        .post(url, dataform, {
            params: { token: token },
            responseType: "blob",
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", fileName)
            document.body.appendChild(link)
            link.click()
        })
        .catch((err) => console.log(err.message))
}
export const handleResponse = (res: AxiosResponse<any>) => {
    const { data, status } = res
    const payload = data.data
    const message = data.message

    return {
        message: message,
        payload: payload,
        status: status,
    }
}

export const formatError = (
    message: string,
    reason: string,
    status: number
) => {
    return `Error ${status}: ${message}; reasonn:${reason}`
}
