import classnames from "classnames"
import { useContext } from "react"
import { BsHeartFill } from "react-icons/bs"
import { useHistory } from "react-router-dom"
import classes from "../../classes/classes"
import { TokenContext } from "../../contexts/TokenContext"
import { UserContext } from "../../contexts/UserContext"
import { dateHelper } from "../../helpers/formatDate"
import {
    archiveProcedure,
    getArchived,
    getBuild,
    getFavorites,
    getValidated,
    likeProcedure,
    unArchiveProcedure,
    unLikeProcedure,
} from "./proceduresHelpers"

export const RenderFavTable = (
    data: any[],
    user: any,
    onRowClickHandler: any, name:string, className?:string
) => {
    return RenderTable(getFavorites(data, user), onRowClickHandler, name, className)
}

export const RenderArchivedTable = (
    data: any[],
    user: any,
    onRowClickHandler: any, name:string, className?:string
) => {
    return RenderTable(getArchived(data, user), onRowClickHandler, name, className)
}

export const RenderBuildTable = (
    data: any[],
    user: any,
    onRowClickHandler: any, name:string, className?:string
) => {
    return RenderTable(getBuild(data), onRowClickHandler, name, className)
}

export const RenderValidatedTable = (
    data: any[],
    user: any,
    onRowClickHandler: any, name:string, className?:string
) => {
    return RenderTable(getValidated(data, user), onRowClickHandler, name, className)
}

export const RenderTable = (data: any, onRowClickHandler: any, name:string, className?:string ) => {
    const history = useHistory()
    const { loggedUser } = useContext(UserContext)
    const { token } = useContext(TokenContext)
    return (
        <>
        <div className="mt-10">
            <h3 className={classnames(classes.pageSubtitle)}>{name}</h3>
        </div>
            {data.length !== 0 ? (
                <table
                    id="Procedures"
                    className={classnames("w-full text-gray-200 rounded-t-xl bg-opacity-70", className)}
                >
                    <tr className="text-left">
                        <th className="px-4 py-3">Name</th>
                        <th className="px-4 py-3">Creation Date</th>
                        <th className="px-4 py-3">Status</th>
                    </tr>

                    <tbody className="text-gray-600 text-sm border rounded divide-y divide-gray-300">
                        {(data as any).map((procedure: any, idx: number) => {
                            const {
                                name,
                                creationDate,
                                _id,
                                uuid,
                                validated = false,
                            } = procedure
                            return (
                                <tr
                                    className="bg-white border-b divide-gray-300"
                                    key={`tr-${idx}`}
                                >
                                    <td
                                        className="p-4 whitespace-nowrap font-bold cursor-pointer"
                                        data-item={uuid}
                                        onClick={onRowClickHandler}
                                    >
                                        {name}
                                    </td>
                                    <td className="p-4 whitespace-nowrap">
                                        {dateHelper(new Date(creationDate))}
                                    </td>
                                    <td className="p-4 ">
                                        {validated ? (
                                            <span className="relative text-xs inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                                <span className="absolute inset-0 bg-green-200 opacity-50 rounded-full"></span>
                                                <span className="relative">
                                                    Validated
                                                </span>
                                            </span>
                                        ) : (
                                            <span className="relative inline-block  text-xs px-3 py-1 font-semibold text-yellow-900 leading-tight">
                                                <span className="absolute inset-0 bg-yellow-200 opacity-50 rounded-full"></span>
                                                <span className="relative">
                                                    Building
                                                </span>
                                            </span>
                                        )}
                                    </td>
                                    <td className={"text-blue-500 underline"}>
                                        {" "}
                                        <button
                                            onClick={(e) => {
                                                if (
                                                    procedure.archived.includes(
                                                        (loggedUser as any)._id
                                                    )
                                                ) {
                                                    unArchiveProcedure(
                                                        procedure.uuid,
                                                        token
                                                    )
                                                } else
                                                    archiveProcedure(
                                                        procedure.uuid,
                                                        token
                                                    )
                                            }}
                                        >
                                            {procedure.archived.includes(
                                                (loggedUser as any)._id
                                            )
                                                ? "unarchive"
                                                : "archive"}
                                        </button>
                                    </td>
                                    <td className={"text-blue-500 underline"}>
                                        {" "}
                                        <button
                                            onClick={(e) => {
                                                history.push(
                                                    `/binding/new/${procedure.uuid}`
                                                )
                                            }}
                                        >
                                            bind
                                        </button>
                                    </td>
                                    <td
                                        className={classnames(
                                            procedure.favorites.includes(
                                                (loggedUser as any)._id
                                            )
                                                ? "text-red-500"
                                                : "text-gray-400",
                                            "underline"
                                        )}
                                    >
                                        {" "}
                                        <button
                                            onClick={(e) => {
                                                if (
                                                    procedure.favorites.includes(
                                                        (loggedUser as any)._id
                                                    )
                                                ) {
                                                    unLikeProcedure(
                                                        procedure.uuid,
                                                        token
                                                    )
                                                } else
                                                    likeProcedure(
                                                        procedure.uuid,
                                                        token
                                                    )
                                            }}
                                        >
                                            <BsHeartFill />
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            ) : (
                <p>No Procedures</p>
            )}
        </>
    )
}
