import classNames from "classnames"
import { FunctionComponent, useEffect, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { FaEthereum } from "react-icons/fa"
import { FiChevronDown } from "react-icons/fi"
import { HiClipboard } from "react-icons/hi"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism"
import classes from "../../classes/classes"
import useResetState from "../../hooks/useResetState"

const CodeSnippet: FunctionComponent<{ code: string }> = ({ code }) => {
    const [copied, setCopied] = useResetState(false, 1000)
    return (
        <>
            <div className="relative">
                <div
                    className={classNames(
                        "absolute right-0 p-2 text-gray-500 hover:text-gray-200 cursor-pointer"
                    )}
                >
                    <CopyToClipboard text={code} onCopy={() => setCopied(true)}>
                        <HiClipboard size="1.5rem" />
                    </CopyToClipboard>
                </div>
                {copied ? (
                    <div className="absolute bottom-0 right-0 px-2 py-1 m-4 bg-gray-500 rounded text-sm">
                        Copied!
                    </div>
                ) : null}
                <SyntaxHighlighter language="solidity" style={okaidia}>
                    {code}
                </SyntaxHighlighter>
            </div>
        </>
    )
}

export const SnippetsLib: FunctionComponent<{}> = ({}) => {
    const FILES = [
        {
            fileName: "example.sol",
            description: "This contract instantiate a simple ERC721 contract",
        },
        {
            fileName: "box.sol",
            description: "This contract is a simple data store",
        },
    ]

    const [code, setCode] = useState("")
    const [description, setDescription] = useState(FILES[0].description)
    const [selectedSnippet, setSelectedSnippet] = useState(FILES[0].fileName)

    async function importFile(fileName: string) {
        fetch(`./CodeFiles/${fileName}`)
            .then((r) => r.text())
            .then((text) => {
                setCode(text)
            })
    }

    useEffect(() => {
        importFile(selectedSnippet)
    }, [selectedSnippet])

    return (
        <>
            <div className="flex space-x-1 items-center font-bold mb-1">
                <FiChevronDown />
                <span>Contract Snippets</span>
            </div>
            {FILES.map(({ fileName, description }) => {
                return (
                    <>
                        <div className="ml-4">
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedSnippet(fileName)
                                    setDescription(description)
                                }}
                            >
                               {SolidityFile(fileName, fileName == selectedSnippet)}
                            </button>
                        </div>
                    </>
                )
            })}
            <div className="space-y-4 mt-4">
                <p className={classes.explanatoryText}>{description}</p>
                <CodeSnippet code={code} />
            </div>
        </>
    )
}

const SolidityFile = (fileName: string, selected?:boolean) => {
    return (
        <div className={classNames("flex items-center capitalize", selected && "font-bold")}>
            <FaEthereum /> <span className="">{fileName}</span>
        </div>
    )
}

export default SnippetsLib
