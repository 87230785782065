import classnames from "classnames"
import { Form, Formik, FormikHelpers } from "formik"
import React, { Dispatch, FC, SetStateAction } from "react"
import * as Yup from "yup"
import classes from "../../../classes/classes"
import FormError from "../../../components/fields/InlineError"
import Radio from "../../../components/fields/Radio"
import Toggle from "../../../components/fields/Toggle"
import Spinner from "../../../components/Spinner"

const Payment: FC<{
    paid: boolean
    setPaid: Dispatch<SetStateAction<boolean>>
}> = ({ paid, setPaid }) => {
    const initialValues = {
        duration: "",
        conditionsAccepted: "",
    }
    const sendPayment = async (
        values: any,
        { setSubmitting }: FormikHelpers<any>
    ) => {
        console.log(values)
        setPaid(true)
    }
    const validationSchema = Yup.object({
        duration: Yup.string().required("Please select a duration"),
        conditionsAccepted: Yup.boolean().required(
            "You have to accept the conditions before moving forward"
        ),
    })

    const computePrice = (plan: string) => {
        return plan ? `${parseInt(plan) * 10} CHF` : "Please select a plan !"
    }

    return (
        <>
            <div>
                <p className="mt-2">
                    Before submitting a new key, you have to accept the service
                    contract and pay for the duration you choose. We can only
                    guarantee that we will be able to recover your key for the
                    duration you paid. After, we will not be able to start any
                    recovery procedure.
                </p>
                <Formik
                    initialValues={initialValues}
                    onSubmit={sendPayment}
                    validationSchema={validationSchema}
                >
                    {({ isSubmitting, values, errors, touched }) => (
                        <Form>
                            <Radio
                                name="duration"
                                label="Choose service duration"
                                options={["1", "5", "10", "99"]}
                                optionsTexts={[
                                    "1 Year",
                                    "5 Years",
                                    "10 Years",
                                    "For life (99 Years)",
                                ]}
                            />
                            <FormError
                                errors={errors}
                                touched={touched}
                                name="duration"
                            ></FormError>

                            <span className="text-blue-600 font-bold">
                                Price: {computePrice(values.duration)}
                            </span>

                            <a
                                className={classnames(
                                    classes.neutralButton,
                                    "mt-4"
                                )}
                                href="https://arbitri-service-contracts.s3.eu-west-3.amazonaws.com/Conditions+ge%CC%81ne%CC%81rales+du+service+d%E2%80%99Arbitri+(de%CC%81po%CC%82t+virtuel+de+secret+retail)+.pdf"
                            >
                                Download the service contract
                            </a>
                            <Toggle
                                name="conditionsAccepted"
                                toggleText="I accept the conditions and sign the service contract."
                            />

                            <FormError
                                errors={errors}
                                touched={touched}
                                name="conditionsAccepted"
                            ></FormError>

                            <button
                                className={classnames(
                                    classes.mainButton,
                                    "w-44"
                                )}
                                disabled={isSubmitting}
                                type="submit"
                            >
                                "Pay !"
                            </button>
                            {isSubmitting ? <Spinner /> : ""}
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default Payment
