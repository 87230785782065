import axios from "axios"
import React, {
    Dispatch,
    FunctionComponent,
    SetStateAction,
    useEffect,
    useState,
} from "react"
import Spinner from "../../../components/Spinner"
import { config } from "../../../config/constants"

const MyKeys: FunctionComponent<{
    token: any
    myKeys: Array<string>
    setMyKeys: Dispatch<SetStateAction<Array<string>>>
}> = ({ token, myKeys, setMyKeys }) => {
    const [loaded, setLoaded] = useState(false)

    const reqEncKey = () => {
        axios
            .get(`${config.url.API_URL}/vault/keys`, {
                params: { token: token },
            })
            .then((res: any) => {
                let newKeys = [...res.data.data];
                setMyKeys(newKeys)
                setLoaded(true)
                console.log(newKeys)
            })
            .catch((err: any) => {
                console.log(err)
                setLoaded(true)
            })
    }

    useEffect(() => {
        reqEncKey()
    }, [])

    return (
        <>
            {!loaded && <Spinner></Spinner>}
            <div className="flex gap-x-1">
                <ol>
                    {myKeys?.map((k, idx) => (
                        <li key={idx} className={(k[2] && "text-green-500")}>{k[0]} {k[1] && (<span>({k[1]})</span>)} </li>
                    ))}
                </ol>
            </div>
        </>
    )
}

export default MyKeys
