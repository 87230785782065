import { BIPShamir } from "@vquelque/shamir_bip39"
import { useWeb3React } from "@web3-react/core"
import classnames from "classnames"
import { Form, Formik, FormikHelpers } from "formik"
import React, {
    Dispatch,
    FunctionComponent,
    SetStateAction,
    useEffect,
    useState,
} from "react"
import classes from "../../../classes/classes"
import SimpleInput from "../../../components/fields/SimpleInput"
import Spinner from "../../../components/Spinner"
import { encrypt } from "../../../web3/crypto"
import SuccessMessage from "../../../components/SuccessMessage"
import { Wallet, utils, Contract } from "ethers"
import * as Yup from "yup"
import {
    arbitriEncPubKey,
    config,
    notaryEncPubKey,
    VAULT_CONTRACT,
} from "../../../config/constants"
import { APIClient } from "../../../helpers/submit"
import ErrorComponent from "../../../components/Error"
import { Logger } from "ethers/lib/utils"
import Toggle from "../../../components/fields/Toggle"
import FormError from "../../../components/fields/InlineError"
import Select from "../../../components/fields/Select"
import { generateMnemonic } from "bip39"

interface Values {
    deposit: string
    email: string
    vault_name: string
    mnemonic: string
    thirdshare: boolean
    thirdshare_firstname: string
    thirdshare_lastname: string
    thirdshare_wallet_address: string
    thirdshare_pub_enc_key: string
}

const initialValues: Values = {
    deposit: "virtual",
    email: "",
    vault_name: "",
    mnemonic: "",
    thirdshare: false,
    thirdshare_firstname: "",
    thirdshare_lastname: "",
    thirdshare_wallet_address: "",
    thirdshare_pub_enc_key: "",
}

const formSchema: Yup.SchemaOf<any> = Yup.object().shape({
    deposit: Yup.string().oneOf(["virtual", "physical"]).required(),
    email: Yup.string()
        .email()
        .when(["deposit"], {
            is: (deposit: string) => {
                return deposit === "physical"
            },
            then: Yup.string().required("You must enter email address"),
        }),
    vault_name: Yup.string().notRequired(),
    mnemonic: Yup.string().when(["deposit"], {
        is: (deposit: string) => {
            return deposit === "virtual"
        },
        then: Yup.string().required("Please enter a mnemonic !"),
    }),
    thirdshare: Yup.boolean(),
    thirdshare_firstname: Yup.string().when("thirdshare", {
        is: true,
        then: Yup.string().required(
            "You must enter third share owner firstname"
        ),
    }),
    thirdshare_lastname: Yup.string().when("thirdshare", {
        is: true,
        then: Yup.string().required(
            "You must enter third share owner lastname"
        ),
    }),
    thirdshare_wallet_address: Yup.string().when("thirdshare", {
        is: true,
        then: Yup.string()
            .test(
                "validate-eth-address",
                "This is not a valid Ethereum address",
                (value, _) => (value ? utils.isAddress(value) : false)
            )
            .required("You must enter third share owner ethereum address"),
    }),
    thirdshare_pub_enc_key: Yup.string().when("thirdshare", {
        is: true,
        then: Yup.string()
            .matches(
                /^(?:[A-Za-z\d+/]{4})*(?:[A-Za-z\d+/]{3}=|[A-Za-z\d+/]{2}==)?$/,
                "This is a wrong encryption key"
            )
            .length(44, "This is a wrong encryption key")
            .required("You must enter third share owner public encryption key"),
    }),
})

const CreateSharesForm: FunctionComponent<{
    token: any
    setMyKeys: Dispatch<SetStateAction<string[]>>
}> = ({ token, setMyKeys }) => {
    const { account, active, library, chainId } = useWeb3React()

    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [info, setInfo] = useState("")
    const [randWallet, setRandWallet] = useState({
        mnemonic: "",
        address: "",
    })

    useEffect(() => {
        const [mnemonic, address] = generateRandomWallet()
        setRandWallet({ mnemonic: mnemonic, address: address })
    }, [])

    const generateRandomWallet = () => {
        const mnemonic = generateMnemonic()
        try {
            const wallet = Wallet.fromMnemonic(mnemonic)
            return [mnemonic, wallet.address]
        } catch (error: any) {
            setError(error.message)
            return []
        }
    }

    const assertUserBalance = async () => {
        //assert user has enough matic to pay for tx fees
        const provider = library?.provider
        if (!provider) {
            return false
        }
        const userFunds = await provider.request({
            method: "eth_getBalance",
            params: [account],
        })
        return userFunds > 1
    }

    const generateShares = async (
        values: any,
        { setSubmitting }: FormikHelpers<Values>
    ) => {
        setError("")
        setSuccess("")
        setInfo("")
        setSubmitting(true)
        const enoughUserFunds = await assertUserBalance()
        if (!enoughUserFunds) {
            setError(
                "Please fund your account with at least 0.1 MATIC to pay for transaction fees"
            )
            return
        }
        // take either real mnemonic or generated mnemonic
        const mnemonic =
            values.deposit === "virtual" ? values.mnemonic : randWallet.mnemonic
        let wallet
        try {
            wallet = Wallet.fromMnemonic(mnemonic)
            if (
                values.deposit === "physical" &&
                wallet.address !== randWallet.address
            ) {
                //error
                throw new Error(
                    "Generated wallet mnemonic and address don't match. Please try refreshing the page."
                )
            }
        } catch (error: any) {
            setError(error.message)
            return
        }
        if (
            !window.confirm(
                `Do you really want to send the private key for address ${wallet.address} ?`
            )
        ) {
            setError("Aborted.")
            return
        }
        const threshold = values.thirdshare ? 3 : 2
        const thirdshare_pub_key = values.thirdshare
            ? values.thirdshare_pub_enc_key
            : ""
        // N,M = 2 or 3
        let sss = new BIPShamir(threshold, threshold)
        try {
            let shares = sss.createShares(mnemonic)
            if (
                window.confirm(
                    `Do you confirm you want to encrypt an send the ${threshold} shares to Arbitri ?`
                )
            ) {
                await encryptSharesAndSend(
                    values.deposit,
                    values.email,
                    shares,
                    wallet.address,
                    values.vault_name,
                    values.thirdshare_firstname,
                    values.thirdshare_lastname,
                    values.thirdshare_wallet_address,
                    thirdshare_pub_key
                )
            } else {
                setError("Aborted")
            }
        } catch (error: any) {
            setError(error.message)
        }
    }

    const encryptSharesAndSend = async (
        deposit: string,
        email: string,
        sharesArr: string[],
        addr: string,
        vaultName: string,
        thirdshare_firstname: string,
        thirdshare_lastname: string,
        thirdshare_wallet_address: string,
        thirdshare_pub_enc_key: string
    ) => {
        if (!active || !account) {
            alert(
                "Please connect to your wallet using Metamask to use this service. All wallet might not support this operation."
            )
            return
        }

        if (chainId !== VAULT_CONTRACT.chainId) {
            setError("Please connect to Polygon network to use this service !")
            return
        }

        //compute hash of shares
        const hShares = sharesArr.map((share, _) =>
            utils.keccak256(Buffer.from(share))
        )

        //encrypt shares
        const encryptionKeys = [arbitriEncPubKey, notaryEncPubKey].concat(
            thirdshare_pub_enc_key.length > 0 ? [thirdshare_pub_enc_key] : []
        )
        if (encryptionKeys.length !== sharesArr.length) {
            throw new Error(
                "Fatal error: mismatch between encryption keys and shares generated"
            )
        }
        const encPayloads = sharesArr.map((share, idx) =>
            encryptSalsaAndHexify(share, encryptionKeys[idx])
        )

        //sign confirmation message: encKey1 | share1 | encKey2 | share 2 | (encKey3 | share3)
        const confirmMsg = utils.keccak256(
            utils.toUtf8Bytes(
                encPayloads.reduce(
                    (prev, curr, idx) => prev + encryptionKeys[idx] + curr
                )
            )
        )
        const signature = await library.getSigner().signMessage(confirmMsg)
        //store shares in backend
        try {
            const payloadToSend = formatPayload(
                deposit,
                email,
                encPayloads,
                hShares,
                encryptionKeys,
                signature,
                encPayloads.length,
                encPayloads.length,
                addr,
                account,
                vaultName,
                thirdshare_firstname,
                thirdshare_lastname,
                thirdshare_wallet_address,
                thirdshare_pub_enc_key
            )
            const res = await APIClient(token).post(
                `${config.url.API_URL}/vault/new`,
                payloadToSend
            )
            if (res.status !== 200) {
                throw new Error(res.data?.message)
            }
            setInfo(
                `Shares succesfully transferred to Arbitri. To prove your submission, you will send a transaction to the blockchain. Please make sure you have enough MATIC in your wallet to pay for transaction fees.`
            )
            //get signature by arbitri and send it to blockchain
            const signer = await library.getSigner()
            const vaultContract = new Contract(
                VAULT_CONTRACT.address,
                VAULT_CONTRACT.abi,
                signer
            )
            const response = res.data?.message
            //send confirmation to blockchain
            const tx = await vaultContract.newShare(
                response?.address,
                response?.message,
                response?.signature
            )
            console.log(`Transaction hash: ${tx.hash}`)
            //send hash to arbitri
            try {
                // Wait for the transaction to be mined
                APIClient(token)
                    .patch(`${config.url.API_URL}/vault/updateHash`, {
                        txHash: tx.hash,
                        address: response?.address,
                    })
                    .catch((err) => {
                        console.log(err)
                        setError("Error while sending txHash to arbitri")
                    })
                const receipt = await tx.wait()
                // The transactions was mined without issue
                setSuccess(
                    `All good ! Transaction confirmed in block ${receipt.blockNumber} !`
                )
                setMyKeys([response?.address])
            } catch (error: any) {
                if (error.code === Logger.errors.TRANSACTION_REPLACED) {
                    if (error.cancelled) {
                        // The transaction was cancelled. Inform the backend
                        console.log(
                            `Transaction cancelled. New tx hash: ${error.replacement?.hash}`
                        )
                        APIClient(token).patch(
                            `${config.url.API_URL}/vault/updateHash`,
                            {
                                txHash: error.replacement?.hash,
                                address: response?.address,
                            }
                        )
                    } else {
                        // The user used "speed up" or something similar. Inform the backend
                        console.log(
                            `Transaction replaced (speedup). New tx hash: ${error.replacement?.hash}`
                        )
                        APIClient(token).patch(
                            `${config.url.API_URL}/vault/updateHash`,
                            {
                                txHash: error.replacement?.hash,
                                address: response?.address,
                            }
                        )
                    }
                }
            }
        } catch (err: any) {
            console.log(err)
            const { data } = err.response || {}
            const { message } = data || {}
            setError(
                message ||
                    err.message ||
                    "Sorry our server encountered an issue"
            )
        }
    }

    const formatPayload = (
        deposit: string,
        email:string,
        sharesSecret: Array<string>,
        sharesHash: Array<string>,
        sharesOwner: Array<string>,
        signature: string,
        totalShares: number,
        thresholdShares: number,
        privKeyAddress: string,
        ownerAddress: string,
        vaultName: string,
        thirdshare_firstname: string,
        thirdshare_lastname: string,
        thirdshare_wallet_address: string,
        thirdshare_pub_enc_key: string
    ) => {
        let shares = sharesSecret.map((s: string, i: number) => {
            let payload = {
                secret: s,
                owner: sharesOwner[i],
                index: i,
                keccak256: sharesHash[i],
            }
            return payload
        })
        const thirdshare = {
            firstname: thirdshare_firstname,
            lastname: thirdshare_lastname,
            walletAddress: thirdshare_wallet_address,
            publicEncKey: thirdshare_pub_enc_key,
        }

        let payload = {
            deposit: deposit,
            ownerPubAddress: ownerAddress,
            signature: signature,
            totalShares: totalShares,
            thresholdShares: thresholdShares,
            address: privKeyAddress,
            shares: shares,
            vaultName: vaultName,
        }

        if (thirdshare_pub_enc_key.length > 0) {
            //add thirdshare payload
            payload = Object.assign({ thirdparty: thirdshare }, payload)
        } 

        if (deposit === "physical") {
            //add email
            payload = Object.assign({ email: email }, payload)
        }
        
        return payload

    }

    //encrypt do not obfuscate length. Use encryptSafely to obfuscate length
    const encryptSalsaAndHexify = (data: string, pubKey: string) =>
        "0x" +
        Buffer.from(
            JSON.stringify(
                encrypt({
                    publicKey: pubKey,
                    data: data,
                    version: "x25519-xsalsa20-poly1305",
                })
            ),
            "utf-8"
        ).toString("hex")

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={generateShares}
                validationSchema={formSchema}
            >
                {({ isSubmitting, values, errors, touched }) => (
                    <Form>
                        <div>
                            <p>
                                Below, you can select whether you want a virtual
                                or a physical deposit (ledger, paperkey...). If
                                you chose a physical deposit, we will ask you to
                                provide an email address so that we can reach
                                out and organize a secure way to make your
                                deposit. In that case, we will use the smart
                                contract as a procuration for the physical
                                object. Your deposit will be preserved in a
                                Swiss bank.
                            </p>
                            <h2
                                className={classnames(
                                    classes.formH2,
                                    "mt-4 mb-2"
                                )}
                            >
                                Type of deposit
                            </h2>
                            <Select
                                name="deposit"
                                options={["virtual", "physical"]}
                                optionsTexts={["Virtual", "Physical"]}
                                label="Type of deposit"
                                tooltipText="Please select the type of deposit"
                            ></Select>
                            <h2
                                className={classnames(
                                    classes.formH2,
                                    "mt-4 mb-2"
                                )}
                            >
                                Mnemonic
                            </h2>
                            <SimpleInput
                                name="vault_name"
                                type="text"
                                placeholder="Enter a name for your vault"
                                label="Vault name (Optional)"
                                tooltipText="Enter a name that will help you identify this private key"
                            />
                            <FormError
                                errors={errors}
                                touched={touched}
                                name="mnemonic"
                            />
                            {values.deposit === "virtual" && (
                                <SimpleInput
                                    name="mnemonic"
                                    type="text"
                                    placeholder="Enter your mnemonic"
                                    label="Your BIP-39 mnemonic"
                                    tooltipText="Enter the BIP-39 mnemonic corresponding to your private key here"
                                />
                            )}
                            {values.deposit === "physical" && (
                                <div>
                                    <SimpleInput
                                        name="email"
                                        type="email"
                                        placeholder="email@provider.ch"
                                        label="Email"
                                        tooltipText="Please enter an email where we can contact you."
                                    />
                                    <FormError
                                        errors={errors}
                                        touched={touched}
                                        name="email"
                                    />
                                    <div className="mt-2">
                                        <p>
                                            Procuration mnemonic:{" "}
                                            <strong>
                                                {randWallet.mnemonic}
                                            </strong>{" "}
                                        </p>
                                        <p>
                                            Procuration adddress:{" "}
                                            <strong>
                                                {randWallet.address}
                                            </strong>{" "}
                                        </p>
                                        <p className="text-red-500 font-bold">
                                            Please write-down and keep these
                                            informations for further reference.
                                            This is the digital procuration for
                                            your deposit. Arbitri cannot access this information. 
                                        </p>
                                    </div>
                                </div>
                            )}

                            <Toggle
                                name="thirdshare"
                                toggleText="Add a third party share"
                                tooltipText="Click to add a third party share. Be careful ! If added, Arbitri and the Notary won't be able to recover your key if they are not able to recover this share !"
                            />
                            {values.thirdshare && (
                                <>
                                    <FormError
                                        errors={errors}
                                        touched={touched}
                                        name="thirdshare_firstname"
                                    />
                                    <SimpleInput
                                        name="thirdshare_firstname"
                                        type="text"
                                        placeholder="John"
                                        label="First name of the third share holder"
                                        tooltipText="Enter the first name of the person holding the third share"
                                    />
                                    <FormError
                                        errors={errors}
                                        touched={touched}
                                        name="thirdshare_lastname"
                                    />
                                    <SimpleInput
                                        name="thirdshare_lastname"
                                        type="text"
                                        placeholder="Lehnon"
                                        label="Last name of the third share holder"
                                        tooltipText="Enter the last name of the person holding the third share"
                                    />
                                    <FormError
                                        errors={errors}
                                        touched={touched}
                                        name="thirdshare_wallet_address"
                                    />
                                    <SimpleInput
                                        name="thirdshare_wallet_address"
                                        type="text"
                                        placeholder="0x0000000000000000000000000000000000000000"
                                        label="Wallet address of the third share holder"
                                        tooltipText="Enter the wallet address of the person holding the third share"
                                    />
                                    <FormError
                                        errors={errors}
                                        touched={touched}
                                        name="thirdshare_pub_enc_key"
                                    />
                                    <SimpleInput
                                        name="thirdshare_pub_enc_key"
                                        type="text"
                                        placeholder="4ENCkEY8publiCmRgOD6N+uzhZ1cHReyJcehtH0j43V="
                                        label="Public encryption key of the third share holder"
                                        tooltipText="Enter the public encryption key of the person holding the third share. BE CAREFUL ! This key will be used to encrypt the third share. If you make a mistake, your private key will be unrecoverable ! You can find out this key by asking your third-party to connect to Arbitri app with his personnal wallet and go to the 'My encryption Key' section"
                                    />
                                </>
                            )}
                        </div>
                        {error && (
                            <ErrorComponent
                                message={error}
                                resetErrorState={() => setError("")}
                                className="mb-4"
                            />
                        )}
                        {info && (
                            <SuccessMessage
                                message={info}
                                resetSuccessState={() => setInfo("")}
                                className="mb-4"
                                info
                            />
                        )}
                        {success && (
                            <SuccessMessage
                                message={success}
                                resetSuccessState={() => setSuccess("")}
                                className="mb-4"
                            />
                        )}
                        <div className="flex items-center gap-x-2">
                            <button
                                className={classnames(
                                    classes.mainButton,
                                    "w-44"
                                )}
                                disabled={isSubmitting}
                                type="submit"
                            >
                                "Generate the shares"
                            </button>
                            {isSubmitting ? <Spinner /> : ""}
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default CreateSharesForm
