import React, { FunctionComponent, useState } from "react"
import { config } from "../../../config/constants"
import { sendPost } from "../../../helpers/submit"
import * as Yup from "yup"
import "yup-phone";
import { utils } from "ethers"
import { Form, Formik } from "formik"
import { ErrorMessage, SuccessMessage } from "../../../components/State"
import classnames from "classnames"
import FormError from "../../../components/fields/InlineError"
import SimpleInput from "../../../components/fields/SimpleInput"
import { ReCAPTCHA } from "react-google-recaptcha"
import classes from "../../../classes/classes"

const RecoverPrivateKeyForm: FunctionComponent<{ token: string }> = ({
    token,
}) => {
    const [submited, setSubmited] = useState(false)
    const [error, setError] = useState("")
    const formSchema = Yup.object().shape({
        phone: Yup.string().phone('CH',false,"Hmm this does not look like a phone. Please make sure to enter the indicative.").required("Required"),
        publicKey: Yup.string()
            .test(
                "validate-eth-address",
                "This is not a valid Ethereum address",
                (value, _) => (value ? utils.isAddress(value) : false)
            )
            .required("Required"),
        keyOwnerName: Yup.string().required("Required"),
    })
    const initialValues = {
        phone: "",
        keyOwnerName: "",
        publicKey: "",
    }
    const onSuccess = (message: string, payload: any, status: number) => {
        setError("")
        setSubmited(true)
    }

    const onFailure = (message: string, payload: any, status: number) => {
        setError(
            "Sorry, there was an error while forwarding the question to the server. Please try again later."
        )
    }

    const submit = (values: any) => {
        sendPost(
            values,
            `${config.url.API_URL}/vault/recover`,
            token,
            onSuccess,
            onFailure
        )
    }

    return (
        <>
            <div>
                {submited ? (
                    <SuccessMessage message="Your key recovery request was submitted to our councels. We will get back to you asap." />
                ) : (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={submit}
                        validationSchema={formSchema}
                    >
                        {({ errors, touched, isValid, isSubmitting }) => (
                            <Form>
                                <FormError
                                    errors={errors}
                                    touched={touched}
                                    name="publicKey"
                                />
                                <SimpleInput
                                    name="publicKey"
                                    type="text"
                                    placeholder="Ethereum public key to recover"
                                    label="Public key"
                                />
                                <FormError
                                    errors={errors}
                                    touched={touched}
                                    name="keyOwnerName"
                                />
                                <SimpleInput
                                    name="keyOwnerName"
                                    type="text"
                                    placeholder="Legal full name of the key owner"
                                    label="Key owner name"
                                />
                                <FormError
                                    errors={errors}
                                    touched={touched}
                                    name="phone"
                                />
                                <SimpleInput
                                    name="phone"
                                    type="text"
                                    placeholder="Phone number"
                                    label="Phone"
                                />
                                <button
                                    disabled={isSubmitting || !isValid}
                                    className={classnames(
                                        classes.flatButton,
                                        "bg-gradient-to-r from-blue-400 to-blue-700 my-4 disabled:opacity-40"
                                    )
                                }
                                >
                                    Send
                                </button>
                                <ErrorMessage
                                    errorMessage={error}
                                />
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </>
    )
}

export default RecoverPrivateKeyForm
