import axios from "axios"
import { FunctionComponent, useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import InitiateContract from "../../components/Contracts/InitiateContract"
import { config } from "../../config/constants"
import { TokenContext } from "../../contexts/TokenContext"
import { formatError, sendPost } from "../../helpers/submit"
import useSignUpForm from "../../hooks/loginform"

const NewProcedure: FunctionComponent<{}> = ({}) => {
    const history = useHistory()
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const {token} = useContext(TokenContext)

    const onSuccess = async (message: string, payload: any, status: number) => {
        const procedure = payload.procedure
        history.push(`/procedures/${procedure._id}`)
    }

    const onError = (message: string, reason: string, status: number) => {
        setError(formatError(message, reason, status))
    }

    const triggerNewClause = (values: any) => {
        sendPost(
            {
                name: values.name,
                contractors: values.contractors,
            },
            `${config.url.API_URL}/procedure/new`,
            token,
            onSuccess,
            onError
        )
    }

    return (
        <InitiateContract
            submit={triggerNewClause}
            name={"New Arbitration Clause"}
        />
    )
}

export default NewProcedure
