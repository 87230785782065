import { FunctionComponent } from "react"

const Ping: FunctionComponent = () => {
    return (
        <span className="flex items-center ml-2 h-2 w-3">
            <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
            <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-green-400 opacity-75"></span>
        </span>
    )
}

export default Ping
