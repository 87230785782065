const classes = {
    formH2: "font-bold font-xl uppercase tracking-widest",
    inputs: "w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-black transition-colors",
    inlineInputs:
        "px-3 py-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-black transition-colors",
    inputsLocked:
        "text-gray-400 w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-black transition-colors",
    select: "form-select w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-black transition-colors cursor-pointer",
    label: "font-bold text-sm ml-1 mr-2 text-gray-700",
    labelNoInput: "font-bold text-sm text-gray-700 capitalize",
    formSection: "border-b-2 border-gray-100 mb-7 pb-7",
    neutralButton:
        "block text-center text-white bg-gray-800 p-3 duration-300 rounded-sm hover:bg-black rounded-md text-sm tracking-widest uppercase",
    newButton:
        "inline-block text-centered text-gray-500 font-bold px-3 py-1 rounded-lg tracking-widest uppercase my-4 border-2 border-gray-500",
    stepButton:
        "bg-blue-500 text-white font-bold uppercase tracking-wide text-sm px-3 py-1 rounded-lg w-44 shadow-lg",
    mainButton: "text-white font-bold uppercase tracking-wide text-sm px-3 py-1 rounded-lg shadow-lg bg-blue-500 disabled:opacity-50",
    button:
        "font-bold uppercase tracking-wide text-sm px-3 py-1 rounded-lg w-44 shadow-lg",
    flatButton:
        "text-white font-bold uppercase tracking-wide text-sm px-3 py-1 rounded-lg w-44 shadow-lg",
    pageSubtitle: "text-2xl font-bold text-gray-700",
    explanatoryText: "text-gray-700",
    box: "bg-white shadow rounded-lg",
    sidebar: {
        button: "w-full text-center rounded font-bold uppercase text-xs block px-2 py-2 tracking-widest",
        link: "w-full block flex px-3 py-3 items-center",
        disabledLink: "w-full block flex px-3 py-3 items-center opacity-20",
        activeLink: "bg-white text-black",
    },
}

export default classes
