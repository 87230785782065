import axios from "axios"
import classnames from "classnames"
import { FieldArray } from "formik"
import {
    default as React,
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from "react"
import { BsFillPlusCircleFill } from "react-icons/bs"
import { useHistory, useParams } from "react-router-dom"
import classes from "../../classes/classes"
import Contractor from "../../components/Contracts/Contractor"
import Select from "../../components/fields/Select"
import SimpleInput from "../../components/fields/SimpleInput"
import TextArea from "../../components/fields/TextArea"
import {
    FormikSimpleStepper,
    FormikStep,
    FormikStepper,
} from "../../components/FormikStepper"
import AppLayout from "../../components/Layout/AppLayout"
import SnippetsLib from "../../components/Layout/CodeSnippet"
import { config } from "../../config/constants"
import { TokenContext } from "../../contexts/TokenContext"
import { formatError, sendGet, sendPost } from "../../helpers/submit"
import useGetProcedures, {
    useGetFreeContracts,
} from "../../hooks/usGetProcedures"

const SmartContractGenerator: FunctionComponent = () => {
    const { token } = useContext(TokenContext)
    const [errorMessage, setErrorMessage] = useState("")

    const [error, setError] = useState("")
    const params = useParams()
    const procedureId = (params as any).procedureId

    const onSuccess = async (message: string, payload: any, status: number) => {
        history.push("/")
    }

    const onError = (message: string, reason: string, status: number) => {
        setError(formatError(message, reason, status))
    }

    const validate = async (values: any) => {
        sendPost(
            { contractDetails: values },
            `${config.url.API_URL}/smart-contract/new`,
            token,
            onSuccess,
            onError
        )
    }

    const onSubmit = (values: any) => {
        return
    }

    const procedures = useGetProcedures()
    const freeContracts = useGetFreeContracts()
    let contracts = procedures.concat(freeContracts)
    let proceduresIds = procedures
        ? contracts.map((clause: any) => clause.uuid)
        : null
    let proceduresName = procedures
        ? contracts.map((clause: any) => clause.name)
        : null

    const previewPDF = () => {}
    const history = useHistory()
    const contractorDefaultValues = {
        firstname: "",
        lastname: "",
        companyName: "",
        companyAddress: "",
        contractorFistName: "",
        contractorLastName: "",
        eth: "",
        intermediary: false,
    }
    const initialValues = {
        contractAddress: "",
        network: "1",
        description: "",
        contract: proceduresIds![0],
    }

    return (
        <AppLayout title="Smart Contract Generator">
            <div className="pt-10 md:px-20 w-full">
                <h1 className={classes.pageSubtitle}>
                    Smart Contract Generator
                </h1>
                <>
                    <p className={classnames(classes.explanatoryText, "mb-4")}>
                        You are currently building your smart contract{" "}
                    </p>
                    <FormikSimpleStepper
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        serverValidate={validate}
                        enableReinitialize={true}
                    >
                        <FormikStep label="Build your Smart Contract">
                            <SnippetsLib />
                        </FormikStep>
                        <FormikStep label="Smart Contract details">
                            <SimpleInput
                                name={"name"}
                                type={"text"}
                                placeholder={"Name"}
                                label={"Name of your smart contract"}
                            />
                            <Select
                                name={"network"}
                                options={["1", "137"]}
                                optionsTexts={["Ethereum Mainnet", "Polygon"]}
                                label={"Network"}
                            />
                            <SimpleInput
                                name={"contractAddress"}
                                type={"text"}
                                placeholder={"0x..."}
                                label={"Contract address"}
                            />
                            <TextArea
                                name={"description"}
                                placeholder={"description of the contract"}
                                label={"description"}
                            />
                            <Select
                                name={`contract`}
                                options={proceduresIds!}
                                optionsTexts={proceduresName!}
                                label="Bind to an existing contract"
                            />
                        </FormikStep>
                    </FormikSimpleStepper>{" "}
                </>
            </div>
        </AppLayout>
    )
}

export default SmartContractGenerator
