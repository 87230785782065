import classnames from "classnames"
import { Form, Formik, FormikConfig, FormikValues } from "formik"
import React, { useState } from "react"
import classes from "../classes/classes"
import Spinner from "./Spinner"

export interface FormikStepProps
    extends Pick<FormikConfig<FormikValues>, "children" | "validationSchema"> {
    label: string
}

export interface FormikStepper extends FormikConfig<FormikValues> {
    saveState: any
    serverUpdate: (values: any) => Promise<any>
    serverValidate: () => void
    serverPreview: () => void
}

export interface FormikSimpleStepper extends FormikConfig<FormikValues> {
    serverValidate: (values: any) => Promise<any>
}

export function FormikStep({ children }: FormikStepProps) {
    return <>{children}</>
}

export function FormikStepper({
    children,
    saveState,
    serverUpdate,
    serverValidate,
    serverPreview,
    ...props
}: FormikStepper) {
    const childrenArray = React.Children.toArray(
        children
    ) as React.ReactElement<FormikStepProps>[]
    const [step, setStep] = useState(0)
    const [canPursue, setCanPursue] = useState(true)
    const currentChild = childrenArray[step]
    const [completed, setCompleted] = useState(false)

    function isLastStep() {
        return step === childrenArray.length - 1
    }

    return (
        <Formik
            {...props}
            validationSchema={currentChild.props.validationSchema}
            onSubmit={async (values, helpers) => {
                // setCanPursue(false)
                saveState(values)
                await serverUpdate(values)
                if (isLastStep()) {
                    await props.onSubmit(values, helpers)
                    serverValidate()
                    setCompleted(true)
                } else {
                    setStep((s) => s + 1)
                    helpers.setTouched({})
                }
                // setCanPursue(true)
            }}
        >
            {({ isSubmitting }) => (
                <>
                    <Form autoComplete="off">
                        <div className="flex space-x-4 items-start">
                            <div className="w-1/4">
                                <div className="bg-white shadow  rounded-lg">
                                    {childrenArray.map(
                                        (child: any, index: number) => {
                                            return (
                                                <div
                                                    key={`step-${index}`}
                                                    className={classnames(
                                                        "font-bold px-3 py-1 border-b border-gray-200",
                                                        index === step
                                                            ? "bg-blue-500 text-white"
                                                            : "text-gray-700",
                                                        index ==
                                                            childrenArray.length -
                                                                1 &&
                                                            "rounded-b-lg",
                                                        index == 0 &&
                                                            "rounded-t-lg"
                                                    )}
                                                >
                                                    {child.props.label}
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                                <div className="space-y-2">
                                    <div className="mt-1 flex space-x-1">
                                        {step > 0 ? (
                                            <button
                                                disabled={isSubmitting}
                                                className={classnames(
                                                    classes.stepButton,
                                                    "w-full"
                                                )}
                                                type="button"
                                                onClick={(e) => {
                                                    setStep(step - 1)
                                                }}
                                            >
                                                {isSubmitting
                                                    ? "Submitting"
                                                    : "back"}
                                            </button>
                                        ) : null}

                                        <button
                                            disabled={isSubmitting}
                                            className={classnames(
                                                classes.stepButton,
                                                "w-full"
                                            )}
                                            type="submit"
                                        >
                                            {isSubmitting
                                                ? "Submitting"
                                                : isLastStep()
                                                ? "Create"
                                                : "Next"}
                                        </button>
                                    </div>
                                    {isSubmitting && (
                                        <div className="flex space-x-1 items-center justify-center">
                                            <span className="text-sm font-bold">
                                                Uploading to the server, please
                                                wait
                                            </span>
                                            <Spinner />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="bg-white w-3/4 shadow rounded-lg">
                                <h2
                                    className={
                                        "font-bold text-gray-700 px-3 py-1 border-b border-gray-200 "
                                    }
                                >
                                    {currentChild.props.label}
                                </h2>
                                <div className="p-8">{currentChild}</div>
                            </div>
                        </div>
                    </Form>
                </>
            )}
        </Formik>
    )
}
export function FormikSimpleStepper({
    children,
    serverValidate,
    ...props
}: FormikSimpleStepper) {
    const childrenArray = React.Children.toArray(
        children
    ) as React.ReactElement<FormikStepProps>[]
    const [step, setStep] = useState(0)
    const [canPursue, setCanPursue] = useState(true)
    const currentChild = childrenArray[step]
    const [completed, setCompleted] = useState(false)

    function isLastStep() {
        return step === childrenArray.length - 1
    }

    return (
        <Formik
            {...props}
            validationSchema={currentChild.props.validationSchema}
            onSubmit={async (values, helpers) => {
                if (isLastStep()) {
                    serverValidate(values)
                    setCompleted(true)
                } else {
                    setStep((s) => s + 1)
                    helpers.setTouched({})
                }
                // setCanPursue(true)
            }}
        >
            {({ isSubmitting }) => (
                <>
                    <Form autoComplete="off">
                        <div className="flex space-x-4 items-start">
                            <div className="w-1/4">
                                <div className="bg-white shadow  rounded-lg">
                                    {childrenArray.map(
                                        (child: any, index: number) => {
                                            return (
                                                <div
                                                    key={`step-${index}`}
                                                    className={classnames(
                                                        "font-bold px-3 py-1 border-b border-gray-200",
                                                        index === step
                                                            ? "bg-blue-500 text-white"
                                                            : "text-gray-700",
                                                        index ==
                                                            childrenArray.length -
                                                                1 &&
                                                            "rounded-b-lg",
                                                        index == 0 &&
                                                            "rounded-t-lg"
                                                    )}
                                                >
                                                    {child.props.label}
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                                <div className="space-y-2">
                                    <div className="mt-1 flex space-x-1">
                                        {step > 0 ? (
                                            <button
                                                disabled={isSubmitting}
                                                className={classnames(
                                                    classes.stepButton,
                                                    "w-full"
                                                )}
                                                type="button"
                                                onClick={(e) => {
                                                    setStep(step - 1)
                                                }}
                                            >
                                                {isSubmitting
                                                    ? "Submitting"
                                                    : "back"}
                                            </button>
                                        ) : null}

                                        <button
                                            disabled={isSubmitting}
                                            className={classnames(
                                                classes.stepButton,
                                                "w-full"
                                            )}
                                            type="submit"
                                        >
                                            {isSubmitting
                                                ? "Submitting"
                                                : isLastStep()
                                                ? "Create"
                                                : "Next"}
                                        </button>
                                    </div>
                                    {isSubmitting && (
                                        <div className="flex space-x-1 items-center justify-center">
                                            <span className="text-sm font-bold">
                                                Uploading to the server, please
                                                wait
                                            </span>
                                            <Spinner />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="bg-white w-3/4 shadow rounded-lg">
                                <h2
                                    className={
                                        "font-bold text-gray-700 px-3 py-1 border-b border-gray-200 "
                                    }
                                >
                                    {currentChild.props.label}
                                </h2>
                                <div className="p-8">{currentChild}</div>
                            </div>
                        </div>
                    </Form>
                </>
            )}
        </Formik>
    )
}