import { FunctionComponent } from "react"
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5"
import { RiErrorWarningFill } from "react-icons/ri"

const SuccessMessage: FunctionComponent<{ message: String }> = ({
    message,
}) => {
    return (
        <>
            {message && (
                <div className="flex bg-white rounded-lg py-4 px-8 font-bold text-xl text-gray-600 items-center">
                    <IoCheckmarkDoneCircleSharp
                        className="text-green-400"
                        size="2rem"
                    />
                    <span className="block ml-3">{message}</span>
                </div>
            )}
        </>
    )
}

const ErrorMessage: FunctionComponent<{
    errorMessage: String
}> = ({ errorMessage }) => {
    return (
        <>
            {errorMessage ? (
                <div className="bg-red-300 bg-opacity-20 rounded-lg py-4 px-8 font-bold text-xl text-red-400 items-center my-4">
                    <div className="flex">
                        <RiErrorWarningFill
                            className="text-red-400"
                            size="2rem"
                        />
                        <span className="block ml-3">{errorMessage}</span>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

export { SuccessMessage, ErrorMessage }
