import axios from "axios"
import classnames from "classnames"
import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from "react"
import { Link, useHistory } from "react-router-dom"
import classes from "../../classes/classes"
import AppLayout from "../../components/Layout/AppLayout"
import Selector, { Subpage } from "../../components/Selector"
import { config } from "../../config/constants"
import { TokenContext } from "../../contexts/TokenContext"
import { UserContext } from "../../contexts/UserContext"
import {
    RenderArchivedTable,
    RenderBuildTable,
    RenderFavTable,
    RenderValidatedTable,
} from "./RenderTable"

const newContractButton:FunctionComponent = (props: any) => (
    <Link
        to="/contract-generator/new"
        className={classnames(
            classes.stepButton,
            "mt-2 block w-full text-center"
        )}
    >
        New Contract
    </Link>
)

const Procedures: FunctionComponent<{}> = () => {
    const history = useHistory()
    const [arbClauses, setArbClauses] = useState([])
    const [freeContracts, setFreeContracts] = useState([])
    const [smartContracts, setSmartContracts] = useState([])
    const { token } = useContext(TokenContext)
    const { loggedUser } = useContext(UserContext)
    const user = loggedUser

    const onRowClickHandler = (e: any) => {
        const id = e.currentTarget.getAttribute("data-item")
        history.push(`/contract/${id}`)
    }
    useEffect(() => {
        axios
            .get(`${config.url.API_URL}/procedure`, {
                params: { token: token },
            })
            .then((res) => {
                const procedures = res.data.procedures
                if (arbClauses !== procedures) {
                    setArbClauses(procedures)
                }
            })
        axios
            .get(`${config.url.API_URL}/custom-contract`, {
                params: { token: token },
            })
            .then((res) => {
                const procedures = res.data.procedures
                if (freeContracts !== procedures) {
                    setFreeContracts(procedures)
                }
            })
        axios
            .get(`${config.url.API_URL}/smart-contract`, {
                params: { token: token },
            })
            .then((res) => {
                const contracts = res.data.data
                if (smartContracts !== contracts) {
                    setSmartContracts(contracts)
                }
            })
    }, [])

    return (

                <Selector SelectorButton={newContractButton} title="Procedures">
                    {/* TODO  faire un texte mieux */}
                    <Subpage label="Dashboard">
                        <p className={classnames("text-gray-700 mb-4")}>
                            In this section you will find all the contracts that
                            were finalized
                        </p>
                        <div className="space-y-5">
                            {RenderValidatedTable(
                                arbClauses,
                                user,
                                onRowClickHandler,
                                "Arbitration Clauses",
                                "bg-red-500"
                            )}
                            {RenderValidatedTable(
                                freeContracts,
                                user,
                                onRowClickHandler,
                                "Legal Contracts",
                                "bg-green-500"
                            )}
                            {RenderValidatedTable(
                                smartContracts,
                                user,
                                onRowClickHandler,
                                "Smart Contracts",
                                "bg-gray-500"
                            )}
                        </div>
                    </Subpage>
                    <Subpage label="Clauses in creation">
                        <p className={classnames("text-gray-700 mb-4")}>
                            In this section you will find all the clauses that
                            you are building
                        </p>
                        <div className="space-y-5">
                            {RenderBuildTable(
                                arbClauses,
                                user,
                                onRowClickHandler,
                                "Arbitration Clauses",
                                "bg-red-500"
                            )}
                            {RenderBuildTable(
                                freeContracts,
                                user,
                                onRowClickHandler,
                                "Legal Contracts",
                                "bg-green-500"
                            )}
                            {RenderBuildTable(
                                smartContracts,
                                user,
                                onRowClickHandler,
                                "Smart Contracts",
                                "bg-gray-500"
                            )}
                        </div>
                    </Subpage>
                    <Subpage label="Favorites">
                        <p className={classnames("text-gray-700 mb-4")}>
                            In this section you will find all the clauses that
                            you set as favourite
                        </p>
                        <div className="space-y-5">
                            {RenderFavTable(
                                arbClauses,
                                user,
                                onRowClickHandler,
                                "Arbitration Clauses",
                                "bg-red-500"
                            )}
                            {RenderFavTable(
                                freeContracts,
                                user,
                                onRowClickHandler,
                                "Legal Contracts",
                                "bg-green-500"
                            )}
                            {RenderFavTable(
                                smartContracts,
                                user,
                                onRowClickHandler,
                                "Smart Contracts",
                                "bg-gray-500"
                            )}
                        </div>
                    </Subpage>
                    <Subpage label="Archived">
                        <p className={classnames("text-gray-700 mb-4")}>
                            In this section you will find all your archived
                            clauses
                        </p>
                        <div className="space-y-5">
                            {RenderArchivedTable(
                                arbClauses,
                                user,
                                onRowClickHandler,
                                "Arbitration Clauses",
                                "bg-red-500"
                            )}
                            {RenderArchivedTable(
                                freeContracts,
                                user,
                                onRowClickHandler,
                                "Legal Contracts",
                                "bg-green-500"
                            )}
                            {RenderArchivedTable(
                                smartContracts,
                                user,
                                onRowClickHandler,
                                "Smart Contracts",
                                "bg-gray-500"
                            )}
                        </div>
                    </Subpage>
                </Selector>

    )
}

export default Procedures
