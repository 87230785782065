import { useWeb3React } from "@web3-react/core"
import classnames from "classnames"
import { FunctionComponent, useContext, useState } from "react"
import {
    IoIosCheckmarkCircle,
    IoMdArrowDropdown,
    IoMdArrowDropleft,
} from "react-icons/io"
import { IoCloseCircle } from "react-icons/io5"
import { useHistory } from "react-router-dom"
import classes from "../classes/classes"
import { config } from "../config/constants"
import { TokenContext } from "../contexts/TokenContext"
import { UserContext } from "../contexts/UserContext"
import { dateHelper } from "../helpers/formatDate"
import { didUserSigned, signMessage } from "../helpers/signatures"
import { formatError, sendPost } from "../helpers/submit"
import { injected } from "../web3/connectors"
import { DetailsElement } from "./DetailsElement"

export const SignerDetails: FunctionComponent<{
    signer: any
    userDetails: any
}> = ({ signer, userDetails }) => {
    const [open, setOpen] = useState(false)
    return (
        <div className="">
            <button onClick={() => setOpen(!open)}>
                <div className="space-x-2 flex items-center">
                    {!open ? <IoMdArrowDropleft /> : <IoMdArrowDropdown />}
                    <span>{signer.userEmail}</span>
                    <span>
                        {signer.signed ? (
                            <div className="flex space-x-1">
                                <span className="text-xs">
                                    (signed on{" "}
                                    <span className="font-bold">
                                        {dateHelper(
                                            new Date(signer.signatureTimestamp)
                                        )}
                                    </span>
                                    )
                                </span>
                                <IoIosCheckmarkCircle className="text-green-400" />
                            </div>
                        ) : (
                            <IoCloseCircle className="text-red-400" />
                        )}
                    </span>
                </div>
            </button>
            {open &&
                (userDetails ? (
                    <div className="flex flex-col space-y-2 text-xs ml-8">
                        <div className="space-x-2">
                            <span className="font-bold">First Name:</span>
                            <span className="">{userDetails.firstname}</span>
                        </div>
                        <div className="space-x-2">
                            <span className="font-bold">Last Name:</span>
                            <span className="">{userDetails.lastname}</span>
                        </div>
                        <div>
                            <span className="font-bold">
                                Ethereum addresses:
                            </span>
                            <div className="flex flex-col">
                                {userDetails.ethAddresses.map((eth: string) => (
                                    <span key={eth}>{eth}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="ml-8 text-xs text-red-500">
                        User is not signed up to Arbitri
                    </div>
                ))}
        </div>
    )
}

export const SignatureDetails: FunctionComponent<{
    users: any
    signature: any
}> = ({ users, signature}) => {
    const history = useHistory()
    const { loggedUser } = useContext(UserContext)
    const getUserFromEmail = (email: string) => {
        const user = users.find((user: any) => user.email === email)
        return user ? user : null
    }
    const [error, setError] = useState("")
    const { token } = useContext(TokenContext)
    const { activate, active, account, library } = useWeb3React()
    const onSuccess = async (message: string, payload: any, status: number) => {
        window.location.reload()
    }
    const onError = (message: string, reason: string, status: number) => {
        setError(formatError(message, reason, status))
    }
    const sign = (e: any) => {
        e.preventDefault()
        signMessage(library, account, signature.toSign).then((digest) => {
            sendPost(
                { signature: digest },
                `${config.url.API_URL}/signature/${signature._id}/sign`,
                token,
                onSuccess,
                onError
            )
        })
    }
    return (
        <div className="mt-2">
            <DetailsElement
                label="Signature Status"
                value={
                    signature.validated
                        ? "Valid, all contractors signed the binding"
                        : "Pending signatures"
                }
                className={
                    signature.validated ? "text-green-500" : "text-red-500"
                }
            />
            <DetailsElement label="Pending Signatures" />
            <div className="flex flex-col space-y-1">
                {signature.signers.map((signer: any, i: number) => {
                    const { userEmail, signed } = signer
                    const details = getUserFromEmail(userEmail)
                    // const { fisrtname, lastname, ethAddresses } = details
                    return (
                        <SignerDetails
                            key={`contractor-${i}`}
                            signer={signer}
                            userDetails={details}
                        />
                    )
                })}
                {!didUserSigned(loggedUser.email, signature) && (
                    <div className="">
                        {active ? (
                            <button
                                onClick={sign}
                                className={classnames("block")}
                            >
                                <div
                                    className={classnames(
                                        classes.stepButton,
                                        "mt-2"
                                    )}
                                >
                                    Sign
                                </div>
                            </button>
                        ) : (
                            <button
                                className="text-xs font-bold text-blue-400"
                                onClick={() => activate(injected)}
                            >
                                Connect with Metamask
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
