import { useState } from "react"

function useResetState<T>(initial: T, timer: number): [T, (newValue: T) => void] {
    const [value, setValue] = useState(initial)

    const setTemporaryValue = (newValue: T) => {
        setValue(newValue)
        setTimeout(() => setValue(initial), timer)
    }
    
    return [value, setTemporaryValue]
}

export default useResetState
