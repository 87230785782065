import classnames from "classnames"
import { FunctionComponent } from "react"
import classes from "../../classes/classes"
import { ErrorMessage, SuccessMessage } from "../State"

const FormLayout: FunctionComponent<{
    children: React.ReactNode
    title: string,
    success: string
    error: string
    setError: React.Dispatch<React.SetStateAction<string>>
}> = ({ children, title, success, error, setError }) => {
    return (
        <div className="pt-8 md:px-20 w-full">
            <h2 className={classnames(classes.pageSubtitle)}>{title}</h2>
            <div className="flex space-x-4 items-start">
                <div className="p-8 pb-4 bg-white w-full mt-4 mx-auto shadow rounded-lg">
                    <SuccessMessage message={success}/>
                    {children}
                    <ErrorMessage errorMessage={error} />
                </div>
            </div>
        </div>
    )
}

export default FormLayout
