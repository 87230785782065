import classnames from "classnames"
import { Field } from "formik"
import { FunctionComponent } from "react"
import classes from "../../classes/classes"
import Tooltip from "../Tooltip"

const Select: FunctionComponent<{
    name: string
    options: string[]
    optionsTexts?: string[]
    label: string
    tooltipText?: string
}> = ({ name, label, options, optionsTexts, tooltipText }) => {
    return (
        <div className="my-5 text-sm">
            <label htmlFor={name} className={classnames(classes.label)}>
                {label}
            </label>
            {tooltipText && <Tooltip text={tooltipText} />}
            <Field
                as="select"
                name={name}
                id={name}
                className={classnames(classes.inputs)}
            >
                {options.map((option, index) => {
                    return (
                        <option value={option} key={`${option}-${index}-${name}`}>
                            {optionsTexts ? optionsTexts[index] : option}
                        </option>
                    )
                })}
            </Field>
        </div>
    )
}

export default Select
