import classnames from "classnames"
import { Field, useFormikContext } from "formik"
import React, { FunctionComponent } from "react"
import classes from "../../../classes/classes"
import Radio from "../../../components/fields/Radio"
import Select from "../../../components/fields/Select"
import SimpleInput from "../../../components/fields/SimpleInput"
import TextArea from "../../../components/fields/TextArea"
import Toggle from "../../../components/fields/Toggle"

const Direct: FunctionComponent = () => {
    const { values, submitForm } = useFormikContext()
    const descriptions = [
        'They shall be freely appointed in accordance with the following procedure ("other services" section) by the Nomination and Proposals Committee within 14 working days upon the express request of the Parties. The Arbitrators shall be appointed freely. This appointment is binding between the Parties. The Arbitrators must in particular have the following qualities, a quality which Arbitri is entirely free to judge.',
        'They shall be freely proposed according to the procedure below ("other services" section) by the Nomination and Proposals Committee of Arbitration within 14 working days from the express request of the Parties. The Arbitrators shall be appointed freely. They must in particular have the following qualities, a quality which Arbitri is entirely free to judge.',
        "Appointing the Arbitri expert arbitrators from the drop-down list",
    ]
    return (
        <>
            <Select
                name="legalData.expertiseData.directData.language"
                options={["English", "French"]}
                label="Language of the procedure"
            />
            <Select
                name="legalData.expertiseData.directData.expertsNumber"
                options={["1", "3"]}
                label="Number of Experts"
            />
            <label
                htmlFor={"legalData.expertiseData.directData.designation"}
                className={classnames(classes.label)}
            >
                {"Expert Designation"}
            </label>
            <div className="space-y-2 mb-5">
                <label className="block flex items-center">
                    <Field
                        type="radio"
                        name="legalData.expertiseData.directData.designation"
                        value="freelyAppointed"
                    />
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-sm ml-4"
                        )}
                    >
                        {" "}
                        {descriptions[0]}
                    </p>
                </label>
                {(values as any).legalData.expertiseData.directData
                    .designation == "freelyAppointed" && (
                    <div className="ml-8">
                        <SimpleInput
                            name="legalData.expertiseData.directData.expertQualification"
                            type="text"
                            placeholder="Qualifications"
                            label="Expert qualifications (technical knowledge, etc)"
                        />
                        <TextArea
                            name="legalData.expertiseData.directData.questions"
                            label="What questions do you plan to ask the arbitrator?"
                            placeholder="Add a question here"
                            tooltipText="Examples Determining the price, delivery, performance, quality of goods, Is there a defect in the goods? Of performance, non-performance, imperfect performance of a service, A bug in the smart contract]."
                        />
                    </div>
                )}
                <label className="block flex items-center">
                    <Field
                        type="radio"
                        name="legalData.expertiseData.directData.designation"
                        value="freelyProposed"
                    />
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-sm ml-4"
                        )}
                    >
                        {" "}
                        {descriptions[1]}
                    </p>
                </label>
                {(values as any).legalData.expertiseData.directData
                    .designation == "freelyProposed" && (
                    <div className="ml-8">
                        <SimpleInput
                            name="legalData.expertiseData.directData.expertQualification"
                            type="text"
                            placeholder="Qualifications"
                            label="Expert qualifications (technical knowledge, etc)"
                        />
                        <TextArea
                            name="legalData.expertiseData.directData.questions"
                            label="What questions do you plan to ask the arbitrator?"
                            placeholder="Add a question here"
                            tooltipText="Examples Determining the price, delivery, performance, quality of goods, Is there a defect in the goods? Of performance, non-performance, imperfect performance of a service, A bug in the smart contract]."
                        />
                    </div>
                )}
                <label className="block flex items-center">
                    <Field
                        type="radio"
                        name="legalData.expertiseData.directData.designation"
                        value="reffered"
                    />
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-sm ml-4"
                        )}
                    >
                        {" "}
                        They are referred{" "}
                        <Field
                            className={classnames(classes.inlineInputs)}
                            placeholder="designation method"
                            type="text"
                            name="legalData.expertiseData.directData.refferedData.method"
                        />{" "}
                        by{" "}
                        <Field
                            className={classnames(classes.inlineInputs)}
                            placeholder="insert name"
                            type="text"
                            name="legalData.expertiseData.directData.refferedData.referrer"
                        />
                        .
                    </p>
                </label>

                {(values as any).legalData.expertiseData.directData
                    .designation == "reffered" && (
                    <div className="ml-8">
                        <Toggle
                            className="text-xs h-3 w-3"
                            name="legalData.expertiseData.directData.refferedData.designationDelay"
                            toggleText="They shall be designated within 14 working days upon the express request of the Parties."
                        />
                        <Toggle
                            className="text-xs h-3 w-3"
                            name="legalData.expertiseData.directData.refferedData.freely"
                            toggleText="The Arbitrators shall be appointed freely. Such appointment shall be binding on the Parties. The Expert Arbitrators shall, in particular, have the following qualities, which the person appointed shall be entirely free to judge."
                        />
                        <SimpleInput
                            name="legalData.expertiseData.directData.expertQualification"
                            type="text"
                            placeholder="Qualifications"
                            label="Expert qualifications (technical knowledge, etc)"
                        />
                        <TextArea
                            name="legalData.expertiseData.directData.questions"
                            label="What questions do you plan to ask the arbitrator?"
                            placeholder="Add a question here"
                            tooltipText="Examples Determining the price, delivery, performance, quality of goods, Is there a defect in the goods? Of performance, non-performance, imperfect performance of a service, A bug in the smart contract]."
                        />
                    </div>
                )}
                <label className="block flex items-center">
                    <Field
                        type="radio"
                        name="legalData.expertiseData.directData.designation"
                        value="offered"
                    />
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-sm ml-4"
                        )}
                    >
                        {" "}
                        They are offered{" "}
                        <Field
                            className={classnames(classes.inlineInputs)}
                            placeholder="designation method"
                            type="text"
                            name="legalData.expertiseData.directData.offeredData.method"
                        />{" "}
                        by{" "}
                        <Field
                            className={classnames(classes.inlineInputs)}
                            placeholder="insert name"
                            type="text"
                            name="legalData.expertiseData.directData.offeredData.referrer"
                        />
                    </p>
                </label>
                {(values as any).legalData.expertiseData.directData
                    .designation == "offered" && (
                    <div className="ml-8">
                        <Toggle
                            className="text-xs h-3 w-3"
                            name="legalData.expertiseData.directData.offeredData.designationDelay"
                            toggleText="They shall be designated within 14 working days upon the express request of the Parties."
                        />
                        <Toggle
                            className="text-xs h-3 w-3"
                            name="legalData.expertiseData.directData.offeredData.freely"
                            toggleText="The Arbitrators shall be appointed freely. Such appointment shall be binding on the Parties. The Expert Arbitrators shall, in particular, have the following qualities, which the person appointed shall be entirely free to judge."
                        />
                        <SimpleInput
                            name="legalData.expertiseData.directData.expertQualification"
                            type="text"
                            placeholder="Qualifications"
                            label="Expert qualifications (technical knowledge, etc)"
                        />
                        <TextArea
                            name="legalData.expertiseData.directData.questions"
                            label="What questions do you plan to ask the arbitrator?"
                            placeholder="Add a question here"
                            tooltipText="Examples Determining the price, delivery, performance, quality of goods, Is there a defect in the goods? Of performance, non-performance, imperfect performance of a service, A bug in the smart contract]."
                        />
                    </div>
                )}
                <label className="block flex items-center">
                    <Field
                        type="radio"
                        name="legalData.expertiseData.directData.designation"
                        value="arbitri"
                    />
                    <p
                        className={classnames(
                            classes.explanatoryText,
                            "text-sm ml-4"
                        )}
                    >
                        {" "}
                        {descriptions[2]}
                    </p>
                </label>
                {(values as any).legalData.expertiseData.directData
                    .designation == "arbitri" && (
                    <div className="ml-8">
                        <Select
                            name="legalData.expertiseData.directData.arbitriData.arbitrator"
                            options={["0", "1"]}
                            optionsTexts={[
                                "Gabriel Jaccard",
                                "Gaspard Peduzzi",
                            ]}
                            label="Select the desired arbitrator"
                        />
                        <TextArea
                            name="legalData.expertiseData.directData.questions"
                            label="What questions do you plan to ask the arbitrator?"
                            placeholder="Add a question here"
                            tooltipText="Examples Determining the price, delivery, performance, quality of goods, Is there a defect in the goods? Of performance, non-performance, imperfect performance of a service, A bug in the smart contract]."
                        />
                    </div>
                )}
            </div>

            <SimpleInput
                type="text"
                name="legalData.expertiseData.directData.timeLimit"
                label="Time limit for resolving the dispute"
                placeholder="Time limit"
            />
            <SimpleInput
                type="text"
                name="legalData.expertiseData.directData.remuneration"
                label="Remuneration of the Arbitrator"
                tooltipText="e.g. 400.- CHF/Hour, limited to 20 hours or 15'000 CHF"
                placeholder="Amount"
            />
            <SimpleInput
                type="text"
                name="legalData.expertiseData.directData.currencyRemuneration"
                label="Currency for remuneration"
                placeholder="Currency of the remuneration"
            />
            <SimpleInput
                type="text"
                name="legalData.expertiseData.directData.thirdPartiesRemuneration"
                label="Remuneration of the third parties"
                tooltipText="e.g. up to 1'000.- CHF. It concerns Assistants, Secretariat, etc.."
                placeholder="Amount"
            />
            <SimpleInput
                type="text"
                name="legalData.expertiseData.directData.currencyThirdPartiesRemuneration"
                label="Currency for remuneration"
                placeholder="Currency"
            />
            <Radio
                name="legalData.expertiseData.directData.allocation"
                label="Cost Allocation"
                options={["equal", "result", "equity"]}
                optionsTexts={[
                    "Equal division",
                    "Allocation according to the result of the arbitration expertise",
                    "Equity distribution",
                ]}
            />
            <h3 className={classnames(classes.label, "block mb-2")}>
                Conciliation
            </h3>
            <Toggle
                name="legalData.expertiseData.directData.conciliation"
                toggleText="The parties wish that the expert arbitrator, before deciding the case, make every effort to obtain conciliation between the Parties. If necessary, and where appropriate, he shall record the result of this conciliation. It is only in the absence of the success of this conciliation that the expert arbitrator will have to carry out his mandate and rule on the dispute."
            />
        </>
    )
}

export default Direct
