import classnames from "classnames"
import { FieldArray, useFormikContext } from "formik"
import React, { FunctionComponent } from "react"
import { BsFillPlusCircleFill } from "react-icons/bs"
import { RiDeleteBin4Fill } from "react-icons/ri"
import { useParams } from "react-router-dom"
import classes from "../../classes/classes"
import Radio from "../fields/Radio"
import Select from "../fields/Select"
import SimpleInput from "../fields/SimpleInput"
import TextArea from "../fields/TextArea"
import Toggle from "../fields/Toggle"
import UploadComponent, {
    ListFiles,
} from "../fields/UploadFiles"
import UploadedFiles from "../UploadedFiles"
import { config } from "../../config/constants"
import useGetProcedures from "../../hooks/usGetProcedures"

const Token: FunctionComponent<{
    index: number
    token: any
    remove: (index: number) => void
}> = ({ index, remove, token }) => {
    const { values, submitForm } = useFormikContext()
    return (
        <>
            <div className="flex items-center space-x-2 mb-4">
                <h2 className={classnames(classes.formH2)}>{`Token (${
                    index + 1
                })`}</h2>
                <button
                    className="block flex items-center cursor-pointer text-red-400"
                    onClick={(e) => {
                        e.preventDefault()
                        remove(index)
                    }}
                >
                    <RiDeleteBin4Fill />
                </button>
            </div>
            <SimpleInput
                name={`underlyingContractData.applicableLawData.tokenData[${index}].token`}
                type="text"
                placeholder="Token name"
                label="Name of the token"
            />
            <Radio
                name={`underlyingContractData.applicableLawData.tokenData[${index}].lawChoice`}
                label="Choose the Law applicable to the token"
                options={["0", "1", "2"]}
                optionsTexts={[
                    "Swiss law",
                    "Liechtenstein law",
                    "Freely written",
                ]}
            />
            {(values as any).underlyingContractData.applicableLawData.tokenData[
                index
            ].lawChoice === "2" && (
                <TextArea
                    name={`underlyingContractData.applicableLawData.tokenData[${index}].freeLaw`}
                    placeholder="Law applicable to the token"
                    label="What is the law applicable to the token"
                />
            )}
        </>
    )
}
const tokenDefaultValues = {
    token: "",
    lawChoice: "0",
    freeLaw: "",
}

const UnderlyingContract:FunctionComponent<{url:string}> = ({url}) => {
    const { values, submitForm, setFieldValue } = useFormikContext()
    const params = useParams()
    const procedures = useGetProcedures()
    let proceduresIds = procedures
        ? procedures.map((clause: any) => clause.uuid)
        : null
    let proceduresName = procedures
        ? procedures.map((clause: any) => `${clause.name}`)
        : null

    const procedureId = (params as any).procedureId
    return (
        <div className="">
            <>
                <h2 className={classnames(classes.pageSubtitle, "mt-2")}>
                    Legal relationship
                </h2>
                <Radio
                    name="underlyingContractData.bind"
                    label="Define the legal relationship of the contract"
                    options={["0", "1"]}
                    optionsTexts={[
                        "Link to an existing contract",
                        "Define freely the legal relationship",
                    ]}
                />
                {(values as any).underlyingContractData.bind === "0" ? (
                    <>
                        <Select
                            name={`underlyingContractData.bindData.bindContractName`}
                            options={proceduresIds!}
                            optionsTexts={proceduresName!}
                            label="Select the Contract"
                        />
                    </>
                ) : (
                    <>
                        <TextArea
                            name="underlyingContractData.relationship"
                            placeholder="Name of the exchange contract, denomination, date, version, etc."
                            label="What is the legal relationship that will be subject to an Arbitration?"
                        />
                        <UploadComponent
                            name="uploadFiles"
                            label="Upload your contract in PDF"
                        />
                        <ListFiles name={"uploadFiles"}/>
                        <UploadedFiles
                            url={url}
                            title="Uploaded Files"
                        />
                        <h2
                            className={classnames(classes.pageSubtitle, "mt-2")}
                        >
                            Law applicable to underlying relationship
                        </h2>
                        <Radio
                            name="underlyingContractData.applicableLawChoice"
                            label="Choose the Law applicable to the underlying contract and/or token(s)"
                            options={["0", "1", "2"]}
                            optionsTexts={[
                                "We do not wish to specify the law applicable to the underlying contract/token",
                                "Freely written",
                                "Law applicable to the underlying contract & to the titles",
                            ]}
                        />
                        {(values as any).underlyingContractData
                            .applicableLawChoice === "1" && (
                            <TextArea
                                name="underlyingContractData.freeApplicableLaw"
                                placeholder="Law application to the unerlying contract/token"
                                label="What is the law applicable to the underlying contract/token?"
                            />
                        )}
                        {(values as any).underlyingContractData
                            .applicableLawChoice === "2" && (
                            <div className="ml-8">
                                <Toggle
                                    name="underlyingContractData.applicableLawData.underlyingChecked"
                                    toggleText="Choice of law of the underlying relationship"
                                />
                                {(values as any).underlyingContractData
                                    .applicableLawData.underlyingChecked && (
                                    <div className="ml-8">
                                        <Radio
                                            name="underlyingContractData.applicableLawData.underlyingData.underlyingDataChoice"
                                            label="Choose the Law applicable to the underlying contract"
                                            options={["0", "1"]}
                                            optionsTexts={[
                                                "Freely written",
                                                "Swiss law",
                                            ]}
                                        />
                                        {(values as any).underlyingContractData
                                            .applicableLawData.underlyingData
                                            .underlyingDataChoice === "0" && (
                                            <TextArea
                                                name="underlyingContractData.applicableLawData.underlyingData.freeApplicableLaw"
                                                placeholder="Law applicable to the underlying contact"
                                                label="What is the law applicable to the underlying contact?"
                                            />
                                        )}
                                    </div>
                                )}
                                <Toggle
                                    name="underlyingContractData.applicableLawData.tokenChecked"
                                    toggleText="Law applicable to the Tokens"
                                />
                                {(values as any).underlyingContractData
                                    .applicableLawData.tokenChecked && (
                                    <div className="ml-8">
                                        <FieldArray name="underlyingContractData.applicableLawData.tokenData">
                                            {(fieldArrayProps: any) => {
                                                const { push, remove, form } =
                                                    fieldArrayProps
                                                const { values } = form
                                                const { tokenData } =
                                                    values
                                                        .underlyingContractData
                                                        .applicableLawData
                                                return (
                                                    <div>
                                                        {tokenData.map(
                                                            (
                                                                token: any,
                                                                index: number
                                                            ) => {
                                                                return (
                                                                    <Token
                                                                        index={
                                                                            index
                                                                        }
                                                                        remove={
                                                                            remove
                                                                        }
                                                                        token={
                                                                            token
                                                                        }
                                                                        key={
                                                                            `token-${index}`
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                        )}
                                                        <button
                                                            className="flex items-center cursor-pointer"
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                push(
                                                                    tokenDefaultValues
                                                                )
                                                            }}
                                                        >
                                                            <BsFillPlusCircleFill />
                                                            <span className="ml-3 block">
                                                                Add Token
                                                            </span>
                                                        </button>
                                                    </div>
                                                )
                                            }}
                                        </FieldArray>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
            </>
        </div>
    )
}

export default UnderlyingContract
