import classNames from "classnames"
import { FunctionComponent } from "react"
import { BsThreeDots } from "react-icons/bs"
import { HiSelector } from "react-icons/hi"
import { IoFilterSharp, IoTrashSharp } from "react-icons/io5"
import classes from "../../classes/classes"
import { PageWithTitle } from "../../components/Layout/AppLayout"

type ContactDetails = {
    contractorFistName: string
    contractorLastName: string
    contractorEmail: string
    contractorTitle: string
    intermediary: boolean
    eth: string
    title?: string
    firstname?: string
    lastname?: string
    companyName?: string
    companyAddress?: string
}

const Actions = (
    <div className="flex space-x-2">
        <IoTrashSharp />
        <BsThreeDots />
    </div>
)

const Column: FunctionComponent<{ element: any; small?: boolean }> = ({
    element,
    small,
}) => {
    return (
        <>
            {element && (
                <span
                    className={classNames(
                        "text-gray-500 flex-1",
                        small ? " text-xs" : "text-md"
                    )}
                >
                    {element}
                </span>
            )}
        </>
    )
}

const HeadElement: FunctionComponent<{ element: any }> = ({ element }) => {
    return <span className="text-gray-500 font-bold flex-1">{element}</span>
}

const ContactListHeader = (
    <div className="flex p-4 ml-4">
        <HeadElement element="First Name" />
        <HeadElement element="Last Name" />
        <HeadElement element="Email" />
        <HeadElement element="Ethereum Address" />
    </div>
)
const ContactListElement: FunctionComponent<{
    details: ContactDetails
    isOpen: boolean
}> = ({ details, isOpen }) => {
    const {
        contractorFistName,
        contractorLastName,
        contractorEmail,
        contractorTitle,
        intermediary,
        eth,
        title,
        firstname,
        lastname,
        companyName,
        companyAddress,
    } = details

    return (
        <>
            <div className="flex space-x-2 items-center justify-center">
                <HiSelector />
                <div className="bg-white flex p-4 w-full rounded">
                    <Column element={contractorFistName} />
                    <Column element={contractorLastName} />
                    <Column element={contractorEmail} />
                    <Column element={eth} small />
                </div>
                {Actions}
            </div>
        </>
    )
}

const ContactsList = () => {
    const contacts: ContactDetails[] = [
        {
            contractorFistName: "Valentin",
            contractorLastName: "Quelquejay",
            contractorEmail: "valentin.quelqujay@email.com",
            contractorTitle: "contractor",
            intermediary: false,
            eth: "0x0a67d70f036e93C664C375913B75b74D9f771Fd8",
            title: "Mr.",
        },
        {
            contractorFistName: "Gaspard",
            contractorLastName: "Peduzzi",
            contractorEmail: "gaspard.peduzzi@email.com",
            contractorTitle: "contractor",
            intermediary: true,
            eth: "0xb6CE8E2C13A6e0116f9DC71E395b1265328106e2",
            title: "Mr.",
            firstname: "Gabriel",
            lastname: "Jaccard",
            companyName: "Arbitri",
            companyAddress: "Geneva, Switzerland",
        },
    ]
    return (
        <div className="flex flex-col space-y-2">
            {ContactListHeader}
            <div className="flex flex-col space-y-2">
                {contacts.map((contact, i) => {
                    return (
                        <ContactListElement
                            details={contact}
                            key={i}
                            isOpen={i === contacts.length - 1}
                        />
                    )
                })}
            </div>
        </div>
    )
}

const contacts: FunctionComponent = () => {
    return (
        <PageWithTitle title="Contacts" disabled disabledText="Coming soon ...">
            <div className="flex mb-4 w-full items-right">
                <div className="flex space-x-3 ml-auto mr-10">
                    <div className="">
                        <button
                            className={classNames(
                                classes.sidebar.button,
                                "bg-gray-100 text-gray-500 h-full bg-opacity-70 flex items-center space-x-1"
                            )}
                            onClick={(clearToken) => {}}
                        >
                            <span>Filter</span> <IoFilterSharp />
                        </button>
                    </div>
                    <div className="w-44">
                        <button
                            className={classNames(
                                classes.sidebar.button,
                                "bg-blue-500 text-white h-full"
                            )}
                            onClick={(clearToken) => {}}
                        >
                            Add Contact
                        </button>
                    </div>
                    <div className="">
                        <button
                            className={classNames(
                                classes.sidebar.button,
                                "bg-blue-500 text-white h-full bg-opacity-70"
                            )}
                            onClick={(clearToken) => {}}
                        >
                            <BsThreeDots />
                        </button>
                    </div>
                </div>
            </div>
            <ContactsList />
        </PageWithTitle>
    )
}

export default contacts
