import { FormikErrors, FormikTouched } from "formik"
import { FunctionComponent } from "react"

const InlineError: FunctionComponent<{ message: String }> = ({ message }) => {
    return <div className="text-red-400">{message}</div>
}

const FormError: FunctionComponent<{
    errors: FormikErrors<any>
    touched: FormikTouched<any>
    name: string
}> = ({ errors, touched, name }) => {
    return (
        <>
            {errors[name] && touched[name] ? (
                <InlineError message={errors[name] as string} />
            ) : null}
        </>
    )
}

export default FormError
