import { IoCloseCircle } from "react-icons/io5"
import classnames from "classnames"
import { FunctionComponent } from "react"
import { Field } from "formik"
import Tooltip from "../Tooltip"
import classes from "../../classes/classes"

const SimpleInput: FunctionComponent<{
    name: string
    type: string
    placeholder?: string
    label: string
    tooltipText?: string
}> = ({ name, type, placeholder, label, tooltipText }) => {
    return (
        <div className="mb-5 text-sm">
            <label htmlFor={name} className={classnames(classes.label)}>
                {label}
            </label>
            {tooltipText && <Tooltip text={tooltipText} />}
            <Field
                type={type}
                name={name}
                id={name}
                className={classnames(classes.inputs)}
                placeholder={placeholder}
            />
        </div>
    )
}

export default SimpleInput
