import axios from "axios"
import classnames from "classnames"
import { default as React, FunctionComponent, useContext, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import classes from "../../classes/classes"
import AppLayout from "../../components/Layout/AppLayout"
import { config } from "../../config/constants"
import { TokenContext } from "../../contexts/TokenContext"

const ArbitrageItem: FunctionComponent = () => {
    const params = useParams()
    const [arbState, setArbState] = useState({})
    const [inputs, setInputs] = useState({})
    const arbitrageId = (params as any).arbitrageId
    const procedureId = (params as any).procedureId
    const {token} = useContext(TokenContext)
    
    useEffect(() => {
        const loadArbitrage = async () => {
            axios
                .get(
                    `${config.url.API_URL}/procedure/${procedureId}/arbitrage/${arbitrageId}`,
                    {
                        params: { token: token },
                    }
                )
                .then((res) => {
                    const arbitrage = res.data.arbitrage.arbitrageData
                    setArbState(arbitrage)
                })

            axios
                .get(`${config.url.API_URL}/procedure/${procedureId}`, {
                    params: { token: token },
                })
                .then((res) => {
                    const procedure = res.data.procedures
                    setInputs(procedure)
                })
        }
        loadArbitrage()
    }, [])

    const technicalCheckbox = () => {
        if (typeof (inputs as any).procedureData !== "undefined") {
            return (
                (inputs as any).procedureData.technical === true && (
                    <div className="technical">
                        <label className="flex items-center mt-3">
                            <input
                                type="checkbox"
                                name="technical"
                                value="technical"
                                checked={(arbState as any).technical}
                                readOnly
                                className="form-checkbox h-5 w-5 text-red-600"
                            />
                            <span className="ml-2 text-gray-700 capitalize">
                                Technical arbitration{" "}
                            </span>
                        </label>
                        {(inputs as any).procedureData.technical && (
                            <div className="technicalChoice">
                                <label className="flex items-center ml-6 mt-3">
                                    <input
                                        type="radio"
                                        name="technicalChoice"
                                        value="arbitri"
                                        checked={
                                            (arbState as any).technicalChoice ==
                                            "arbitri"
                                        }
                                        readOnly
                                        className="form-checkbox h-4 w-4 text-red-600"
                                    />
                                    <span className="ml-2 text-gray-700">
                                        Triggering the technical procedure and
                                        sending the assets to the Arbitri
                                        address
                                    </span>
                                </label>
                                <label className="flex items-center ml-6 mt-6">
                                    <input
                                        type="radio"
                                        name="technicalChoice"
                                        value="transfer"
                                        checked={
                                            (arbState as any).technicalChoice ==
                                            "transfer"
                                        }
                                        className="form-checkbox h-4 w-4 text-red-600"
                                    />
                                    <span className="ml-2 text-gray-700">
                                        The transfer of assets that are
                                        currently on the Arbitri address
                                    </span>
                                </label>
                                <label className="flex items-center ml-6 mt-6">
                                    <input
                                        type="radio"
                                        name="technicalChoice"
                                        value="nosend"
                                        checked={
                                            (arbState as any).technicalChoice ==
                                            "nosend"
                                        }
                                        className="form-checkbox h-4 w-4 text-red-600"
                                    />
                                    <span className="ml-2 text-gray-700">
                                        Requesting a simple code modification /
                                        repair of the smart contracts without
                                        sending any assets
                                    </span>
                                </label>
                            </div>
                        )}
                    </div>
                )
            )
        }
    }

    const legalCheckbox = () => {
        if (typeof (inputs as any).procedureData !== "undefined") {
            return (
                (inputs as any).procedureData.legal && (
                    <div className="legal">
                        <label className="flex items-center mt-3">
                            <input
                                type="checkbox"
                                name="legal"
                                value="legal"
                                checked={(arbState as any).legal}
                                className="form-checkbox h-5 w-5 text-red-600"
                            />
                            <span className="ml-2 text-gray-700 capitalize">
                                Legal arbitration{" "}
                            </span>
                        </label>
                        {(inputs as any).procedureData.legal &&
                            (inputs as any).procedureData.direct && (
                                <label className="flex items-center ml-6 mt-3">
                                    <input
                                        type="radio"
                                        name="legalChoice"
                                        value="direct"
                                        checked={
                                            (arbState as any).legalChoice ==
                                            "direct"
                                        }
                                        className="form-checkbox h-4 w-4 text-red-600"
                                    />
                                    <span className="ml-2 text-gray-700">
                                        Direct Arbitration by Arbitri{" "}
                                    </span>
                                </label>
                            )}

                        {(inputs as any).procedureData.legal &&
                            (inputs as any).procedureData.external && (
                                <label className="flex items-center ml-6 mt-3">
                                    <input
                                        type="radio"
                                        name="legalChoice"
                                        value="external"
                                        checked={
                                            (arbState as any).external ==
                                            "external"
                                        }
                                        className="form-checkbox h-4 w-4 text-red-600"
                                    />
                                    <span className="ml-2 text-gray-700 capitalize">
                                        External{" "}
                                    </span>
                                </label>
                            )}
                    </div>
                )
            )
        }
    }

    if (arbState !== "undefined" && inputs !== "undefined") {
        return (
            <AppLayout title={`View Arbitrage`}>
                {!(arbState as any).closed && arbState && (
                    <div className="py-8 px-8 h-full">
                        <nav className="bg-grey-light rounded font-sans w-full">
                            <ol className="list-reset flex text-grey-dark mb-4">
                                <li>
                                    <Link
                                        to={`/procedures/`}
                                        className="font-bold"
                                    >
                                        Procedures
                                    </Link>
                                </li>
                                <li>
                                    <span className="mx-2">/</span>
                                </li>
                                <li>
                                    <Link
                                        to={`/procedures/${procedureId}`}
                                        className="font-bold"
                                    >
                                        {procedureId}
                                    </Link>
                                </li>
                                <li>
                                    <span className="mx-2">/</span>
                                </li>
                                <li>{(arbState as any)._id}</li>
                            </ol>
                        </nav>
                        <h2 className={classnames(classes.formH2, "mb-6")}>
                            Arbitrage Information
                        </h2>
                        <div className="flex space-x-4">
                            <form>
                                <fieldset disabled={true}>
                                    <h2
                                        className={classnames(
                                            classes.formH2,
                                            "mt-2"
                                        )}
                                    >
                                        Procedure details
                                    </h2>
                                    <div
                                        className={classnames(
                                            classes.formSection
                                        )}
                                    >
                                        <div className="text-sm mt-4">
                                            <label
                                                className={classnames(
                                                    classes.label
                                                )}
                                            >
                                                Procedure Name
                                            </label>
                                            <input
                                                value={(inputs as any).name}
                                                type="text"
                                                autoFocus
                                                name="name"
                                                id="name"
                                                className={classnames(
                                                    classes.inputsLocked
                                                )}
                                                placeholder="Procedure Name"
                                            />
                                        </div>
                                        <div className="text-sm mt-4">
                                            <label
                                                className={classnames(
                                                    classes.label
                                                )}
                                            >
                                                Procedure Id
                                            </label>
                                            <input
                                                value={(inputs as any)._id}
                                                type="text"
                                                autoFocus
                                                name="name"
                                                id="name"
                                                className={classnames(
                                                    classes.inputsLocked
                                                )}
                                                placeholder="Procedure Name"
                                            />
                                        </div>
                                    </div>

                                    <h2
                                        className={classnames(
                                            classes.formH2,
                                            "my-2"
                                        )}
                                    >
                                        Arbitrage details
                                    </h2>
                                    <div className="text-sm mt-4">
                                        <label
                                            className={classnames(
                                                classes.label
                                            )}
                                        >
                                            Is your request urgent ?
                                        </label>
                                        <label className="flex items-center ml-6 mt-3">
                                            <input
                                                type="radio"
                                                name="urgent"
                                                value="yes"
                                                checked={
                                                    (arbState as any).urgent ==
                                                    "yes"
                                                }
                                                readOnly
                                                className="form-checkbox h-4 w-4 text-red-600"
                                            />
                                            <span className="ml-2 text-gray-700 capitalize">
                                                yes
                                            </span>
                                        </label>
                                        <label className="flex items-center ml-6 mt-3">
                                            <input
                                                type="radio"
                                                name="urgent"
                                                value="no"
                                                checked={
                                                    (arbState as any).urgent ==
                                                    "no"
                                                }
                                                className="form-checkbox h-4 w-4 text-red-600"
                                            />
                                            <span className="ml-2 text-gray-700 capitalize">
                                                No
                                            </span>
                                        </label>
                                    </div>
                                    <div className="text-sm mt-4">
                                        <label
                                            className={classnames(
                                                classes.label
                                            )}
                                        >
                                            How much do you estimate your
                                            dispute to be ?
                                        </label>
                                        <input
                                            type="text"
                                            autoFocus
                                            name="amount"
                                            id="amount"
                                            className={classnames(
                                                classes.inputsLocked
                                            )}
                                            value={(arbState as any).amount}
                                            placeholder="Amount"
                                        />
                                    </div>
                                    <div className="my-5 text-sm">
                                        <label
                                            htmlFor="username"
                                            className={classnames(
                                                classes.label
                                            )}
                                        >
                                            Arbitrations requested
                                        </label>
                                        {technicalCheckbox()}
                                        {legalCheckbox()}
                                    </div>
                                    <div className="text-sm mt-4">
                                        <label
                                            className={classnames(
                                                classes.label
                                            )}
                                        >
                                            Why are you asking for this
                                            Arbitration? What is the factual
                                            situation
                                        </label>
                                        <textarea
                                            autoFocus
                                            id="situation"
                                            name="situation"
                                            className={classnames(
                                                classes.inputsLocked
                                            )}
                                            value={(arbState as any).situation}
                                            placeholder="Explain the situation"
                                        />
                                    </div>
                                    <div className="text-sm mt-4">
                                        <label
                                            className={classnames(
                                                classes.label
                                            )}
                                        >
                                            What evidence do you have to proved
                                            the alleged facts?
                                        </label>
                                        <textarea
                                            autoFocus
                                            id="evidence"
                                            name="evidence"
                                            className={classnames(
                                                classes.inputsLocked
                                            )}
                                            value={(arbState as any).evidence}
                                            placeholder="Evidence details"
                                        />
                                    </div>
                                    <div className="text-sm mt-4">
                                        <label
                                            className={classnames(
                                                classes.label
                                            )}
                                        >
                                            What is the basic legal relationship
                                            involved in the dispute ?
                                        </label>
                                        <textarea
                                            autoFocus
                                            id="relationship"
                                            name="relationship"
                                            className={classnames(
                                                classes.inputsLocked
                                            )}
                                            value={
                                                (arbState as any).relationship
                                            }
                                            placeholder="Explain the relationship"
                                        />
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                )}
            </AppLayout>
        )
    } else {
        return <div></div>
    }
}

export default ArbitrageItem
