import classnames from "classnames"
import { Field } from "formik"
import { FunctionComponent } from "react"
import classes from "../../classes/classes"
import Tooltip from "../Tooltip"

const Radio: FunctionComponent<{
    name: string
    options: string[]
    optionsTexts?: string[]
    label: string
    descriptions?: string[]
    tooltipText?: string
}> = ({ name, label, options, descriptions, optionsTexts, tooltipText }) => {
    return (
        <div className="my-5 text-sm">
            <label
                htmlFor={name}
                className={classnames(classes.label, "block mb-2")}
            >
                {label}
            </label>
            {tooltipText && <Tooltip text={tooltipText} />}
            <div className="space-y-2">
                {options.map((option: any, index: number) => {
                    return (
                        <label className="block" key={`radio-${name}-${index}`}>
                            <Field
                                id={name}
                                type="radio"
                                name={name}
                                value={option}
                            />
                            <span className={classes.explanatoryText}>
                                {" "}
                                {optionsTexts ? optionsTexts[index] : option}
                            </span>
                            {descriptions && (
                                <p className={classes.explanatoryText}>
                                    {" "}
                                    {descriptions[index]}
                                </p>
                            )}
                        </label>
                    )
                })}
            </div>
        </div>
    )
}

export default Radio
