import { useState } from "react"

const useSignUpForm = (callback: any) => {
    const [inputs, setInputs] = useState({})
    const handleSubmit = (event: any) => {
        if (event) {
            event.preventDefault()
        }
        callback()
    }

    const handleInputChange = (event: any) => {
        event.persist()
        setInputs((inputs) => ({
            ...inputs,
            [event.target.name]: event.target.value,
        }))
    }

    const handleCheckboxInputChange = (event: any) => {
        event.persist()
        setInputs((inputs) => ({
            ...inputs,
            [event.target.name]: event.target.checked,
        }))
    }

    const nestedFormUpdate = (name: string, value: any) => {
        setInputs((inputs) => ({ ...inputs, [name]: value }))
    }
    const nestedFormUpdateTab = (
        name: keyof typeof inputs,
        value: any,
        index: number
    ) => {
        var tab: any = inputs[name]
        if (typeof tab == "undefined") {
            setInputs((inputs) => ({ ...inputs, [name]: [] }))
        }
        tab = inputs[name]
        tab[index] = value
        setInputs((inputs) => ({ ...inputs, [name]: tab }))
    }

    const formUpdate = (inputs: any) => {
        var temp = inputs
        for (var key in inputs) {
            temp = { ...temp, key: inputs[key] }
        }
        setInputs(temp)
    }

    return {
        handleSubmit,
        handleInputChange,
        handleCheckboxInputChange,
        nestedFormUpdate,
        nestedFormUpdateTab,
        formUpdate,
        inputs,
    }
}

export default useSignUpForm
