import { useWeb3React } from "@web3-react/core"
import axios from "axios"
import classnames from "classnames"
import React, { FunctionComponent, useEffect, useState } from "react"
import { FaFilePdf } from "react-icons/fa"
import { Link, useHistory } from "react-router-dom"
import { Signature } from "typescript"
import classes from "../../classes/classes"
import { CreateDeletion, Delete } from "../../components/DeleteDetails"
import { SignatureDetails } from "../../components/SignatureDetails"
import UploadedFiles from "../../components/UploadedFiles"
import { config } from "../../config/constants"
import { dateHelper } from "../../helpers/formatDate"
import { formatError, sendGet } from "../../helpers/submit"
import { Bindings } from "../ArbitrationClause/BindingPreview"
import { SimpleDetailElement } from "../CustomContract/Details"

const SmartContractItem: FunctionComponent<{
    dataState: any
    token: any
}> = ({ dataState, token }) => {
    return (
        <div className="py-8 px-8 h-full pb-20 overflow-x-scroll">
            <nav className="bg-grey-light rounded font-sans w-full">
                <ol className="list-reset flex text-grey-dark mb-4">
                    <li>
                        <Link to={`/procedures/`} className="font-bold">
                            Smart Contract
                        </Link>
                    </li>
                    <li>
                        <span className="mx-2">/</span>
                    </li>
                    <li>{dataState._id}</li>
                </ol>
            </nav>
            <h2 className={classnames(classes.formH2, "my-6")}>
                Smart Contract Information
            </h2>
            <div className="flex space-x-4">
                <div className="w-1/2 mx-2">
                    <SimpleDetailElement name="Name">
                        <span>{dataState.name}</span>
                    </SimpleDetailElement>

                    <SimpleDetailElement name="Contract address">
                        {dataState.network ? (
                            dataState.network === "1" ? (
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://etherscan.io/address/${dataState.contractAddress}`}
                                >
                                    <span className="text-blue-500 underline">
                                        {dataState.contractAddress}
                                    </span>
                                </a>
                            ) : (
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://polygonscan.com/address/${dataState.contractAddress}`}
                                >
                                    <span className="text-blue-500 underline">
                                        {dataState.contractAddress}
                                    </span>
                                </a>
                            )
                        ) : (
                            <span>{dataState.contractAddress}</span>
                        )}
                    </SimpleDetailElement>
                    <SimpleDetailElement name="Description">
                        <span>{dataState.description}</span>
                    </SimpleDetailElement>
                    <SimpleDetailElement name="Date of creation">
                        <span>
                            {dateHelper(new Date(dataState.creationDate))}
                        </span>
                    </SimpleDetailElement>
                </div>
                <div className="w-1/2"></div>
            </div>
            <Bindings dataState={dataState} />
        </div>
    )
}

export default SmartContractItem
