import classNames from "classnames"
import { FunctionComponent, useContext, useEffect, useState } from "react"
import classes from "../classes/classes"
import { config } from "../config/constants"
import { TokenContext } from "../contexts/TokenContext"
import { Signature } from "../helpers/signatures"
import { formatError, sendGet, sendPost } from "../helpers/submit"
import { DetailsElement } from "./DetailsElement"
import { SignatureDetails } from "./SignatureDetails"

export const CreateDeletion: FunctionComponent<{ uuid: string }> = ({
    uuid,
}) => {
    const [deletion, setDeletion] = useState({})
    const [error, setError] = useState("")
    const { token } = useContext(TokenContext)

    const onSuccess = async (message: string, payload: any, status: number) => {
        window.location.reload()
    }

    const onError = (message: string, reason: string, status: number) => {
        setError(formatError(message, reason, status))
    }

    return (
        <button
            onClick={(e) => {
                e.preventDefault()
                sendPost(
                    {},
                    `${config.url.API_URL}/deletion/${uuid}`,
                    token,
                    onSuccess,
                    onError
                )
            }}
        >
            <span
                className={classNames(
                    classes.button,
                    "bg-red-400 hover:bg-red-500 text-white inline-block"
                )}
            >
                Delete contract
            </span>
        </button>
    )
}

export const DeletePreview: FunctionComponent<{ deleteDetails: any }> = ({
    deleteDetails,
}) => {
    const [error, setError] = useState("")
    const [signature, setSignature] = useState<Signature>()
    const [users, setUsers] = useState<any>()
    const { token } = useContext(TokenContext)

    const onSuccessSignature = async (
        message: string,
        payload: any,
        status: number
    ) => {
        const { signature, users } = payload
        setSignature(signature)
        setUsers(users)
    }

    const onError = (message: string, reason: string, status: number) => {
        setError(formatError(message, reason, status))
    }

    useEffect(() => {
        sendGet(
            `${config.url.API_URL}/signature/${deleteDetails.signature}`,
            token,
            onSuccessSignature,
            onError
        )
    }, [])

    return (
        <>
            {signature && users && deleteDetails  && (<>
                <div className="mt-5 rounded bg-red-400 bg-opacity-30 p-3">
                    <span className="text-red-500 font-bold">Deletion in progress</span>
                    <DetailsElement
                        label="Creation date"
                        value={signature.creation.toString()}
                    />

                    <SignatureDetails users={users} signature={signature}/>
                </div>
            </>
            )}
        </>
    )
}

export const Delete: FunctionComponent<{ uuid: string }> = ({ uuid }) => {
    const [deletion, setDeletion] = useState()
    const [error, setError] = useState("")
    const { token } = useContext(TokenContext)

    const onSuccess = async (message: string, payload: any, status: number) => {
        setDeletion(payload)
    }

    const onError = (message: string, reason: string, status: number) => {
        setError(formatError(message, reason, status))
    }

    useEffect(() => {
        sendGet(
            `${config.url.API_URL}/deletion/get/${uuid}`,
            token,
            onSuccess,
            onError
        )
    }, [])

    return deletion !== undefined && deletion !== null ? <DeletePreview deleteDetails={deletion}/> : <CreateDeletion uuid={uuid}/>
}
