import classnames from "classnames"
import { FieldArray, Form, Formik } from "formik"
import { FunctionComponent } from "react"
import { BsFillPlusCircleFill } from "react-icons/bs"
import classes from "../../classes/classes"
import SimpleInput from "../fields/SimpleInput"
import { PageWithTitle } from "../Layout/AppLayout"
import Contractor from "./Contractor"

const InitiateContract: FunctionComponent<{
    submit: (values: any) => void
    name: string
    description?: string
}> = ({ submit, name, description }) => {
    const contractorDefaultValues = {
        firstname: "",
        lastname: "",
        companyName: "",
        companyAddress: "",
        contractorFistName: "",
        contractorLastName: "",
        eth: "",
        intermediary: false,
    }

    return (
        <PageWithTitle title={name} description={description}>
            <div className="flex space-x-4 items-start">
                <div className="p-8 pb-4 bg-white w-full mt-4 mx-auto shadow rounded-lg">
                    <Formik
                        initialValues={{
                            name: "",
                            contractors: [contractorDefaultValues],
                        }}
                        onSubmit={submit}
                    >
                        <Form>
                            <h2 className={classnames(classes.formH2, "mt-2")}>
                                Name of reference
                            </h2>
                            <div className={classnames(classes.formSection)}>
                                <SimpleInput
                                    name="name"
                                    type="text"
                                    placeholder="E.g. Sale contract 16.10. with Lucas"
                                    label="Choose a name"
                                    tooltipText="This is just for ease of search in all references"
                                />
                            </div>
                            <FieldArray name="contractors">
                                {(fieldArrayProps: any) => {
                                    const { push, remove, form } =
                                        fieldArrayProps
                                    const { values } = form
                                    const { contractors } = values
                                    return (
                                        <div>
                                            {contractors.map(
                                                (
                                                    contractor: any,
                                                    index: number
                                                ) => {
                                                    return (
                                                        <Contractor
                                                            key={`contractor-${index}`}
                                                            index={index}
                                                            remove={remove}
                                                            contractor={
                                                                contractor
                                                            }
                                                        />
                                                    )
                                                }
                                            )}
                                            <button
                                                className="flex items-center cursor-pointer"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    push(
                                                        contractorDefaultValues
                                                    )
                                                }}
                                            >
                                                <BsFillPlusCircleFill />
                                                <span className="ml-3 block">
                                                    Add Contractor
                                                </span>
                                            </button>
                                        </div>
                                    )
                                }}
                            </FieldArray>
                            <button
                                type="submit"
                                className={classnames(
                                    classes.stepButton,
                                    "mt-2"
                                )}
                            >
                                Create
                            </button>
                        </Form>
                    </Formik>
                </div>
            </div>
        </PageWithTitle>
    )
}

export default InitiateContract
