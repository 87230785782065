import classnames from "classnames"
import { FunctionComponent } from "react"
import { RiDeleteBin4Fill } from "react-icons/ri"
import classes from "../../classes/classes"
import Select from "../fields/Select"
import SimpleInput from "../fields/SimpleInput"
import TextArea from "../fields/TextArea"
import Toggle from "../fields/Toggle"

const Contractor: FunctionComponent<{
    index: number
    contractor: any
    remove: (index: number) => void
}> = ({ index, remove, contractor }) => {
    return (
        <>
            <div className={classnames(classes.formSection)}>
                <div className="flex items-center space-x-2">
                    <h2 className={classnames(classes.formH2)}>{`${
                        index + 1
                    } - Contractor information`}</h2>
                    <button
                        className="block flex items-center cursor-pointer text-red-400"
                        onClick={() => {
                            remove(index)
                        }}
                    >
                        <RiDeleteBin4Fill />
                    </button>
                </div>
                <SimpleInput
                    name={`contractors[${index}].contractorFistName`}
                    type="text"
                    placeholder="First Name"
                    label="First Name"
                />
                <SimpleInput
                    name={`contractors[${index}].contractorLastName`}
                    type="text"
                    placeholder="Last Name"
                    label="Last Name"
                />

                <SimpleInput
                    name={`contractors[${index}].contractorEmail`}
                    type="email"
                    placeholder="Email"
                    label="Email"
                />
                <Select
                    name={`contractors[${index}].contractorTitle`}
                    options={["Mr.", "Mrs.", "-"]}
                    label="Title"
                />
                <Toggle
                    name={`contractors[${index}].intermediary`}
                    toggleText="Acting on behalf of someone else"
                />
            </div>

            {contractor.intermediary && (
                <div className={classnames(classes.formSection)}>
                    <label
                        htmlFor="username"
                        className={classnames(classes.label)}
                    >
                        Acting on behalf of
                    </label>
                    <Select
                        name={`contractors[${index}].title`}
                        options={["Mr.", "Mrs.", "-"]}
                        label="Title"
                    />
                    <SimpleInput
                        name={`contractors[${index}].firstname`}
                        type="text"
                        placeholder="First Name"
                        label="First Name"
                    />
                    <SimpleInput
                        name={`contractors[${index}].lastname`}
                        type="text"
                        placeholder="Last Name"
                        label="Last Name"
                    />
                    <SimpleInput
                        name={`contractors[${index}].companyName`}
                        type="text"
                        placeholder="Company Name"
                        label="Company"
                    />
                    <TextArea
                        name={`contractors[${index}].companyAddress`}
                        placeholder="Company Address"
                        label="Company Address"
                    ></TextArea>
                </div>
            )}
        </>
    )
}

export default Contractor
