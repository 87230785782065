import axios from "axios"
import { config } from "../../config/constants"

export const archiveProcedure = (uuid: any, token: any) => {
    axios
        .post(
            `${config.url.API_URL}/contract/${uuid}/archive`,
            {},
            {
                params: { token: token },
            }
        )
        .then((res) => {
            window.location.reload()
        })
        .catch((error) => {
            console.log(error)
        })
}

export const unArchiveProcedure = (uuid: any, token: any) => {
    axios
        .post(
            `${config.url.API_URL}/contract/${uuid}/unarchive`,
            {},
            {
                params: { token: token },
            }
        )
        .then((res) => {
            window.location.reload()
        })
        .catch((error) => {
            console.log(error)
        })
}

export const likeProcedure = (uuid: any, token: any) => {
    axios
        .post(
            `${config.url.API_URL}/contract/${uuid}/favorite`,
            {},
            {
                params: { token: token },
            }
        )
        .then((res) => {
            window.location.reload()
        })
        .catch((error) => {
            console.log(error)
        })
}

export const unLikeProcedure = (uuid: any, token: any) => {
    axios
        .post(
            `${config.url.API_URL}/contract/${uuid}/unfavorite`,
            {},
            {
                params: { token: token },
            }
        )
        .then((res) => {
            window.location.reload()
        })
        .catch((error) => {
            console.log(error)
        })
}

export const getFavorites = (data: any, user: any) => {
    return data.filter((procedure: any) =>
        procedure.favorites.includes((user as any)._id)
    )
}
export const getArchived = (data: any, user: any) => {
    return data.filter((procedure: any) =>
        procedure.archived.includes((user as any)._id)
    )
}
export const getBuild = (data: any) => {
    return data.filter((procedure: any) => !procedure.validated)
}
export const getValidated = (data: any, user: any) => {
    return data.filter(
        (procedure: any) =>
            procedure.validated &&
            !procedure.archived.includes((user as any)._id)
    )
}
