import axios from "axios"
import classnames from "classnames"
import { default as React, FunctionComponent, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import classes from "../../classes/classes"
import Toggle from "../../components/fields/Toggle"
import { FormikStep, FormikStepper } from "../../components/FormikStepper"
import Spinner from "../../components/Spinner"
import { config } from "../../config/constants"
import { clauseData } from "./clauseData"
import ClassicAAA from "./components/ClassicAAA"
import ClassicCCI from "./components/ClassicCCI"
import ClassicLDIP from "./components/ClassicLDIP"
import ClassicSCAI from "./components/ClassicSCAI"
import ClassicWIPO from "./components/ClassicWIPO"
import Direct from "./components/Direct"
import External from "./components/External"
import FreeClassic from "./components/FreeClassic"
import FreeExternal from "./components/FreeExternal"
import LegalChoice from "./components/LegalChoice"
import UnderlyingContract from "../../components/Contracts/UnderlyingContract"

const ResumeProcedure: FunctionComponent<{ token: any; procedureId: any }> = ({
    token,
    procedureId,
}) => {
    const [errorMessage, setErrorMessage] = useState("")
    const [initialized, setInitialized] = useState(false)
    const [clauseData, setClauseData] = useState<clauseData>()
    const [values, setValues] = useState<clauseData>()

    const resetErrorState = () => {
        setErrorMessage("")
    }

    const previewPDF = () => {
        axios
            .get(`${config.url.API_URL}/procedure/${procedureId}/preview`, {
                params: { token: token },
                responseType: "blob",
            })
            .then((response) => {
                //Create a Blob from the PDF Stream
                const file = new Blob([response.data], {
                    type: "application/pdf",
                })
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file)
                //Open the URL on new Window
                window.open(fileURL)
            })
            .catch((err) => {
                setErrorMessage(err.response.data.error)
            })
    }

    const getStored = () => {
        axios
            .get(`${config.url.API_URL}/procedure/${procedureId}/resume`, {
                params: { token: token },
            })
            .then((res) => {
                if ("procedureData" in res.data.procedure) {
                    setClauseData(res.data.procedure.procedureData)
                    setValues(res.data.procedure.procedureData)
                    setInitialized(true)
                    resetErrorState()
                }
            })
            .catch((err) => {
                setErrorMessage(err.response.data.error)
            })
    }

    const updateData = async (values: clauseData) => {
        const valuesCopy = values
        const contracts = valuesCopy.uploadFiles
        delete valuesCopy.uploadFiles
        const dataform = new FormData()
        dataform.append("procedureUpdateData", JSON.stringify(valuesCopy))
        if (contracts !== null && contracts !== undefined) {
            for (const contract of contracts as any) {
                dataform.append("files", contract)
            }
        }
        return axios
            .put(`${config.url.API_URL}/procedure/${procedureId}`, dataform, {
                params: { token: token },
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {})
            .catch((err) => {
                setErrorMessage(err)
            })
    }

    const onSubmit = (values: any) => {
        return
    }

    const validate = () => {
        axios
            .post(
                `${config.url.API_URL}/procedure/${procedureId}/validate`,
                {},
                {
                    params: { token: token },
                }
            )
            .then((res) => {
                history.push(`/procedures/${procedureId}/connect`)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        getStored()
    }, [])
    const history = useHistory()

    return (
        <div className="pt-10 md:px-20 w-full">
            <h1 className={classes.pageSubtitle}>Clause builder</h1>
            {initialized ? (
                <>
                    <p className={classnames(classes.explanatoryText, "mb-4")}>
                        You are currently building your clause{" "}
                        <span className="font-bold">
                            {(clauseData as any).name}
                        </span>{" "}
                    </p>
                    <FormikStepper
                        initialValues={clauseData as clauseData}
                        onSubmit={onSubmit}
                        saveState={setValues}
                        serverUpdate={updateData}
                        serverValidate={validate}
                        serverPreview={previewPDF}
                    >
                        <FormikStep label="Type of Arbitrations">
                            <p className={classes.explanatoryText}>
                                Choose Arbitration(s)
                            </p>
                            <h2
                                className={classnames(
                                    classes.pageSubtitle,
                                    "mt-2"
                                )}
                            >
                                Technical Arbitration
                            </h2>
                            <p className={classes.explanatoryText}>
                                Use Arbitri as an Authority with specific powers
                                set up in your smart contracts. (e.g. escrow
                                agent in case of dispute). <br />{" "}
                                <b>
                                    Arbitri Public Key:
                                    0x60a5744e066bBbcCF6863d61Ac2d4D888Ab21596
                                </b>
                            </p>
                            <Toggle
                                name="technicalChecked"
                                toggleText="Technical arbitration"
                            />
                            <h2
                                className={classnames(
                                    classes.pageSubtitle,
                                    "mt-2"
                                )}
                            >
                                Legal Arbitrations
                            </h2>
                            <p className={classes.explanatoryText}>
                                Create an Expert Arbitration clause and/or a
                                classic Arbitration clause
                            </p>
                            <Toggle
                                name="legalChecked"
                                toggleText="Legal arbitrations"
                            />
                        </FormikStep>
                        <FormikStep label="Relationship subject to Arbitration">
                            <UnderlyingContract url={`${config.url.API_URL}/procedure/${procedureId}/underlying-contracts`} />
                        </FormikStep>
                        {(values as any).legalChecked && (
                            <FormikStep label="Legal Arbitrations">
                                <LegalChoice />
                            </FormikStep>
                        )}
                        {(values as any).legalData.expertiseChecked &&
                            (values as any).legalData.expertiseData
                                .expertiseOption == "0" && (
                                <FormikStep label="Direct arbitration by Arbitri">
                                    <Direct />
                                </FormikStep>
                            )}
                        {(values as any).legalData.expertiseChecked &&
                            (values as any).legalData.expertiseData
                                .expertiseOption == "1" && (
                                <FormikStep label="Arbitration expertise by a third party">
                                    <External />
                                </FormikStep>
                            )}
                        {(values as any).legalData.expertiseChecked &&
                            (values as any).legalData.expertiseData
                                .expertiseOption == "2" && (
                                <FormikStep label="Freely written external expert arbitration">
                                    <FreeExternal />
                                </FormikStep>
                            )}
                        {(values as any).legalData.classicChecked &&
                            (values as any).legalData.classicData
                                .classicOption == "0" && (
                                <FormikStep label="PILA classic arbitration ">
                                    <ClassicLDIP />
                                </FormikStep>
                            )}
                        {(values as any).legalData.classicChecked &&
                            (values as any).legalData.classicData
                                .classicOption == "1" && (
                                <FormikStep label="SCAI classic arbitration">
                                    <ClassicSCAI />
                                </FormikStep>
                            )}
                        {(values as any).legalData.classicChecked &&
                            (values as any).legalData.classicData
                                .classicOption == "2" && (
                                <FormikStep label="CCI classic arbitration">
                                    <ClassicCCI />
                                </FormikStep>
                            )}
                        {(values as any).legalData.classicChecked &&
                            (values as any).legalData.classicData
                                .classicOption == "3" && (
                                <FormikStep label="AAA external classic arbitration">
                                    <ClassicAAA />
                                </FormikStep>
                            )}
                        {(values as any).legalData.classicChecked &&
                            (values as any).legalData.classicData
                                .classicOption == "4" && (
                                <FormikStep label="WIPO external classic arbitration">
                                    <ClassicWIPO />
                                </FormikStep>
                            )}
                        {(values as any).legalData.classicChecked &&
                            (values as any).legalData.classicData
                                .classicOption == "5" && (
                                <FormikStep label="Freely written external classic arbitration">
                                    <FreeClassic />
                                </FormikStep>
                            )}

                        <FormikStep label="Review & Confirm">
                            <p className={classes.explanatoryText}>
                                You are about to finalize you clause. After this
                                step you won't be able to modify it. People will
                                immediately be invited to sign the clause.
                            </p>
                            <Toggle
                                name="checkedEverythingCorrect"
                                toggleText="I assert that everything is correct and I want to pursue with my clause creation"
                            />
                        </FormikStep>
                    </FormikStepper>{" "}
                </>
            ) : (
                <Spinner />
            )}
        </div>
    )
}

export default ResumeProcedure


