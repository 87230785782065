import classnames from "classnames"
import { Form, Formik, FormikHelpers } from "formik"
import React, { FunctionComponent, useContext, useState } from "react"
import classes from "../../../classes/classes"
import SimpleInput from "../../../components/fields/SimpleInput"
import Spinner from "../../../components/Spinner"
import { ErrorMessage, SuccessMessage } from "../../../components/State"
import UploadComponent, {
    ListFiles,
} from "../../../components/fields/UploadFiles"
import { config } from "../../../config/constants"
import { TokenContext } from "../../../contexts/TokenContext"
import { UserContext } from "../../../contexts/UserContext"
import { sendGet } from "../../../helpers/submit"
import * as Yup from "yup"
import FormError from "../../../components/fields/InlineError"

const KYCHandler: FunctionComponent = () => {
    const [success, setSuccess] = useState("")
    const [error, setError] = useState("")
    const { token } = useContext(TokenContext)
    const { loggedUser, setUser } = useContext(UserContext)

    interface Values {
        firstname: ""
        lastname: ""
        birthdate: ""
        street: ""
        city: ""
        country: ""
        citizenship: ""
        id: ""
    }

    const initialValues: Values = {
        firstname: "",
        lastname: "",
        birthdate: "",
        street: "",
        city:"",
        country: "",
        citizenship: "",
        id: "",
    }

    const formSchema = Yup.object().shape({
        firstname: Yup.string().required(),
        lastname: Yup.string().required(),
        birthdate: Yup.date().required(),
        street: Yup.string().required(),
        city: Yup.string().required(),
        country: Yup.string().required(),
        citizenship: Yup.string().required(),
        id: Yup.array().required()
    })

    const verifyAccount = async (
        values: any,
        { setSubmitting }: FormikHelpers<Values>
    ) => {
        console.log(values)
        setSubmitting(true)
        const onSuccess = (message: string, payload: any, status: number) => {
            setError("")
            setSuccess("Success ! You will be redirected in 3 seconds.")
            setTimeout(() => {
                setUser(payload)
            }, 3000)
        }

        const onFailure = (message: string, reason: string, status: number) => {
            setError(message)
        }

        sendGet(`${config.url.API_URL}/kyc/verify`, token, onSuccess, onFailure)
    }

    return !loggedUser?.kyc ? (
        <div className="m-10">
            <h2 className={classnames(classes.formH2, "mt-2 mb-2")}>
                KYC Handler
            </h2>
            <p className={classes.explanatoryText}>
                {" "}
                Before being able to use this module,{" "}
                <strong> we need to verify your account</strong>. Please fill in
                all the required information. It can take us a few days to
                process the information. We will get back to you as soon as
                possible when we validated your information.
            </p>
            <div className="mb-2"></div>
            <Formik
                initialValues={initialValues}
                onSubmit={verifyAccount}
                validationSchema={formSchema}
            >
                {({ isSubmitting, errors, touched }) => (
                    <Form>
                        <div>
                            <FormError
                                errors={errors}
                                touched={touched}
                                name="firstname"
                            />
                            <SimpleInput
                                name="firstname"
                                type="text"
                                placeholder="Jean"
                                label="First name"
                                tooltipText="Enter your first name here"
                            />
                            <FormError
                                errors={errors}
                                touched={touched}
                                name="lastname"
                            />
                            <SimpleInput
                                name="lastname"
                                type="text"
                                placeholder="Dupont"
                                label="Last name"
                                tooltipText="Enter your last name here"
                            />
                            <FormError
                                errors={errors}
                                touched={touched}
                                name="birthdate"
                            />
                            <SimpleInput
                                name="birthdate"
                                type="date"
                                label="Birthdate"
                                tooltipText="Enter your birthdate here"
                            />
                            <FormError
                                errors={errors}
                                touched={touched}
                                name="street"
                            />
                            <SimpleInput
                                name="street"
                                type="text"
                                placeholder="Place Centrale 1"
                                label="Street and Street Number"
                                tooltipText="Enter your street and street number here"
                            />
                             <FormError
                                errors={errors}
                                touched={touched}
                                name="city"
                            />
                            <SimpleInput
                                name="city"
                                type="text"
                                placeholder="Lausanne"
                                label="City"
                                tooltipText="Enter your city here"
                            />
                            <FormError
                                errors={errors}
                                touched={touched}
                                name="country"
                            />
                            <SimpleInput
                                name="country"
                                type="text"
                                placeholder="Suisse"
                                label="Country"
                                tooltipText="Enter your country here"
                            />
                            <FormError
                                errors={errors}
                                touched={touched}
                                name="citizenship"
                            />
                            <SimpleInput
                                name="citizenship"
                                type="text"
                                placeholder="Suisse"
                                label="Citizenship"
                                tooltipText="Enter your citizenship here"
                            />
                            <FormError
                                errors={errors}
                                touched={touched}
                                name="id"
                            />
                            <UploadComponent
                                name="id"
                                label="Official identification document (PDF, JPEG, PNG)"
                                tooltipText="Please upload an official ID"
                                filetypeAccept="application/pdf, image/png, image/jpeg"
                            ></UploadComponent>
                        </div>
                        <div className="flex items-center gap-x-2">
                            <button
                                className={classnames(
                                    classes.mainButton,
                                    "w-44 mt-2"
                                )}
                                disabled={isSubmitting}
                                type="submit"
                            >
                                Verify my account !
                            </button>
                            {isSubmitting ? <Spinner /> : ""}
                        </div>
                    </Form>
                )}
            </Formik>
            {success && <SuccessMessage message={success}></SuccessMessage>}
            {error && <ErrorMessage errorMessage={error}></ErrorMessage>}
        </div>
    ) : (
        <p>You are already KYCed !</p>
    )
}

export default KYCHandler
