import classnames from "classnames"
import { ErrorMessage, SuccessMessage } from "../../components/State"
import { Form, Formik } from "formik"
import { FunctionComponent, useContext, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import classes from "../../classes/classes"
import Select from "../../components/fields/Select"
import AppLayout from "../../components/Layout/AppLayout"
import FormLayout from "../../components/Layout/FormLayout"
import { config } from "../../config/constants"
import { formatError, sendPost } from "../../helpers/submit"
import useGetProcedures, { useGetFreeContracts, useGetSmartContracts } from "../../hooks/usGetProcedures"
import { getContractByUUID } from "../../helpers/contract"
import { TokenContext } from "../../contexts/TokenContext"

const NewBinding: FunctionComponent = () => {
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const history = useHistory()
    const params = useParams()
    const { contract0Id } = params as any
    const procedures = useGetProcedures()
    const freeContracts = useGetFreeContracts()
    const smartContracts = useGetSmartContracts()
    const {token} = useContext(TokenContext)
    let contracts = procedures.concat(freeContracts).concat(smartContracts)
    contracts = contracts.filter((contract:any )=> contract.validated)
    let proceduresIds = procedures
        ? contracts.map((clause: any) => clause.uuid)
        : null
    let proceduresName = procedures
        ? contracts.map((clause: any) => clause.name)
        : null

    let initialContract0 =
        proceduresIds !== null
            ? proceduresIds.find((uuid) => {
                  return uuid === contract0Id
              })
            : null

    const initialValue = {
        contract0: initialContract0,
        contract1: proceduresIds![0],
    }

    const onSuccess = async (message: string, payload: any, status: number) => {
        setSuccess(`${message}; binding id:${payload._id}`)
        setTimeout(() => history.push(`/contract/${contract0Id}`), 1000)
    }

    const onError = (message: string, reason: string, status: number) => {
        setError(formatError(message, reason, status))
    }

    const submit = async (values: any) => {
        await sendPost(
            values,
            `${config.url.API_URL}/binding/new`,
            token,
            onSuccess,
            onError
        )
    }

    return (
        <AppLayout title="New Binding">
            <FormLayout title="New Binding" success={success} error={error} setError={setError}>
                {procedures &&
                    proceduresIds !== null &&
                    proceduresName !== null &&
                    initialContract0 !== null && (
                        <Formik
                            initialValues={initialValue}
                            enableReinitialize={true}
                            onSubmit={(values) => submit(values)}
                        >
                            {({ values, setFieldValue }) => {
                                return (
                                    <Form>
                                        <Select
                                            name={`contract0`}
                                            options={proceduresIds!}
                                            optionsTexts={proceduresName!}
                                            label="First Contract"
                                        />
                                        <Select
                                            name={`contract1`}
                                            options={proceduresIds!}
                                            optionsTexts={proceduresName!}
                                            label="Second Contract"
                                        />
                                        <button
                                            type="submit"
                                            className={classnames(
                                                classes.stepButton,
                                                "mt-2"
                                            )}
                                        >
                                            Create
                                        </button>
                                    </Form>
                                )
                            }}
                        </Formik>
                    )}
            </FormLayout>
        </AppLayout>
    )
}

export default NewBinding
